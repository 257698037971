import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { AdminService } from '../../admin.service';
import { Menu } from '../../../models/menu.model';
import { Client } from '../../../models/client.model';
import { ClientService } from '../../../services/client.service';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ImageLibraryService } from '../../../shared/imagelibrary.service';
import { MyDesktopService } from '../../../services/mydesktop.service';

import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Auth } from '../../../models/auth.model';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user.model';
import { VaultService } from '../../../services/vault.service';
import { DomainService } from '../../../services/domain.service';
import { GlobalService } from '../../../services/global.service';
import { of } from 'rxjs';

declare var swal: any;
declare var jQuery: any;
declare const $: any;

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss']
})
export class ClientDashboardComponent implements OnInit, OnDestroy {
  loading = false;
  menus: Menu[];
  clients: Client[];
  subscription: Subscription;
  subscriptionLoginAs: Subscription;
  subscriptionClose: Subscription;

  subscriptionUsers: Subscription;
  deleteClientSubscription: Subscription;

  dataTableHtml: any;

  selectedRow = 0;
  selectedRowDataId = [];

  authUser: Auth;

  page = 1;
  filter;

  hide_close = false;

  _tmp_client_id: number;
  _tmp_client_name = '';

  constructor(public adminService: AdminService,
              private clientService: ClientService,
              private imageService: ImageLibraryService,
              private mydesktopService: MyDesktopService,
              private userService: UserService,
              private router: Router,
              private ngZone: NgZone,
              private vaultService: VaultService,
              private domainService: DomainService,
              private globalService: GlobalService
            ) {
                const $this = this;
                window['onEdit'] = function (id) {
                  $this.onEdit(id);
                };

                window['openGallery'] = function( id, group_id ) {
                  $this.openGallery(id, group_id);
                };

                window['loginAs'] = function(id, client) {
                  $this.loginAs(id, client);
                };

                window['onDelete'] = function(id) {
                  $this.onDelete(id);
                };

                window['onDomainResync'] = function(id) {
                  $this.onDomainResync(id);
                }

                window['onManageUsers'] = function(id) {
                  $this.onManageUsers(id);
                }
              }

  ngOnInit() {
    this.authUser = this.adminService.authService.auth;
    this.loading = true;

    this.menus = [];
    this.adminService.loadMenu(this.menus);

    this.subscription = this.clientService.clientsChanged.subscribe(
      (clients: Client[]) => {
        if (typeof clients !== 'undefined' && clients.length > 0) {
          this.loading = false;
          this.clients = clients;

          if ($.fn.DataTable.isDataTable(this.dataTableHtml)) {
            this.dataTableHtml.destroy();
          }
          this.initDT();
        }
      }
    );

    this.subscriptionLoginAs = this.mydesktopService.myDesktopLoginAs.subscribe(
      (authLoginAs: any) => {
        if (typeof authLoginAs !== 'undefined' && authLoginAs
          && authLoginAs.status === 'success'
          && typeof authLoginAs.data.token !== 'undefined' ) {
            this.adminService.authService.setAuthStorage(authLoginAs.data);

          location.href = '/templates';
        }
      }
    );

    // this.clientService.httpGetClients({}, 'dashboard');

    this.subscriptionClose = this.imageService.adminCloseChanged.subscribe(
      (close: any) => {
        this.hide_close = true;
      }
    );

    this.subscriptionUsers = this.userService.usersChanged.subscribe(
      (users: any) => {
        if ( typeof users !== 'undefined' && users.status === 'success' ) {
          this.loading = false;
          const $this = this;

          if ( users.count > 0 ) {
            const userOptions = [];
            for (const user of users.data) {
              let adminText = '';
              if (user.is_admin == 1){
                adminText = ' (Admin)';
              }
              if (user.is_group_admin) {
                adminText = ' (Group admin)';
              }
              if ( user.firstname !== '' ) {
                userOptions[ user.agent_id ] = this.globalService.cleanAgentName(user.firstname + ' ' + user.lastname + adminText);
              }
            }

            swal({
              title: 'Are you sure you want to login as \n' + $this._tmp_client_name,
              html: `
                <p><input id="isFirstTrigger" type="checkbox"> Trigger <strong for="isFirstTrigger">Is First Login</strong></p>
              `,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirm',
              input: 'select',
              inputOptions: userOptions,
            }).then(function () {
                const agent_id = jQuery('.swal2-container.swal2-shown').find('select.swal2-select').val();
                const isFirstTrigger = jQuery('#isFirstTrigger').is(':checked');

                swal({
                  title: 'Login as ' + $this._tmp_client_name,
                  onOpen: function () {
                    swal.showLoading ();
                  }
                });
                const loginAsForm: FormGroup = new FormGroup({
                  'client_id': new FormControl($this._tmp_client_id, Validators.required),
                  'agent_id': new FormControl(agent_id, Validators.required)
                });

                const token = $this.authUser.token;
                const loginAsValues = loginAsForm.value;
                loginAsValues['isFirstTrigger'] = isFirstTrigger;

                if ( $this.adminService.authService.getApp() === 'MyDesign3' ) {
                  $this.mydesktopService.httpPostLoginAs(loginAsValues, token);
                } else if ($this.adminService.authService.auth.provider === 'domain') {
                  $this.domainService.httpLoginAs(loginAsValues);
                } else {
                  $this.vaultService.httpLoginAs(loginAsValues);
                }
            });
          } else {
            swal(
              'Error',
              'No Users found',
              'error'
            );
          }
        }
      }
    );

    this.deleteClientSubscription = this.clientService.clientDelete.subscribe(
      (client: any) => {
        if ( typeof client !== 'undefined' && client.status === 'success' ) {
          swal(
            'Deleted!',
            'Client has been deleted.',
            'success'
          );
          this.loading = false;

          if ($.fn.DataTable.isDataTable(this.dataTableHtml)) {
            this.dataTableHtml.destroy();
          }
          this.initDT();

        }
      }
    );

    this.vaultService.onLoginAs
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.adminService.authService.setAuthStorage(response.data, false, true);
          location.href = '/templates';
        }
      }
    );

    this.domainService.onLoginAs
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.adminService.authService.setAuthStorage(response.data, false, true);
          location.href = '/templates';
        }
      }
    );

    this.domainService.onRefresh
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          window.location.reload();
          this.loading = false;
        }
        if ( typeof response !== 'undefined' && (response.status === 'unauthorized' || response.status === 'error') ) {
          swal(
            'Error',
            'Client Data not found',
            'error'
          );

          this.loading = false;
        }
      }
    );

    this.initDT();
  }

  openGallery(id, gid) {
    const client = {
      ID: id,
      group_id: gid
    };
    this.imageService.onAdmin(client);

    jQuery('#mdLibrarySearch').modal('show');
  }

  closeGallery() {
    jQuery('#mdLibrarySearch').modal('hide');
    this.imageService.onAdminClose();
  }

  loginAs (id: number, client) {
    this._tmp_client_id = id;
    this._tmp_client_name = client;

    this.userService.httpGetUsers({
      'client_id': id
    });
  }

  initDT() {
    let queryParams = '?';

    queryParams += `provider=${this.authUser.provider},designly`;

    if (this.adminService.authService.getApp() === 'Designly') {
      queryParams += '&designly=yes';
    }

    const $this = this;
    setTimeout(() => {
      this.dataTableHtml = $('#datatable-clients').DataTable({
        responsive: true,
        pagingType: 'full_numbers',
        processing: true,
        serverSide: true,
        ajax: {
          // tslint:disable-next-line:max-line-length
          url: $this.adminService.appConfig.API_ENDPOINT + '/clients/datatable' + queryParams,
          type: 'GET',
          beforeSend: function (request) {
            request.setRequestHeader('Authorization', 'Bearer ' + $this.adminService.authService.auth.__token);
            request.setRequestHeader('MD-Authorization', $this.adminService.authService.auth.token);
          },
        },
        pageLength: 50,
        preDrawCallback: () => {
          $this.loading = true;
        },
        drawCallback: () => {
          $this.loading = false;
        },
        columns: [
          { data: 'ID' },
          {
            data: function (row) {
              return row['provider'].toUpperCase();
            },
          },
          {
            data: function (row) {
              return row['name'];
            },
          },
          {
            data: function (row) {
              return row['group'] ? row['group'] : '';
            },
          },
          {
            data: function (row) {
              return row['office_id'];
            },
          },
          {
            name: 'is_pro_trial',
            data: function (row) {
              return row['is_pro_trial'] === true ? 'Yes' : 'No';
            },
          },
          {
            name: 'pro_enabled_users_count',
            data: function (row) {
              return row['pro_enabled_users'] && row['pro_enabled_users'].length > 0 ? 'Yes' : 'No';
            },
            orderable: false
          },
          {
            data: function (row) {
              return row['is_franchise_master'] === 1 ? 'Yes' : 'No';
            },
          },
          {
            name: 'is_account_master',
            data: function (row) {
              return row['is_account_master'] === 1 ? 'Yes' : 'No';
            },
          },
          {
            data: function (row) {
              if (row['is_account_active'] === 1) {
                return '<span class="label label-success">Active</span>';
              } else {
                return '<span class="label label-danger">Inactive</span>';
              }
            },
          },
          {
            data: function (row) {
              let html = '';

              let officeName = row['name'];
              officeName = officeName.replace(/'/g, '_');

              html +=
                '<button type="button" onclick="onEdit(' +
                row['office_id'] +
                ')" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>';
              html +=
                '<button type="button" onclick="openGallery(' +
                row['ID'] +
                ', ' +
                row['group_id'] +
                ')" class="btn-table" title="Image Gallery"><i class="icon feather icon-image"></i></button>';
              html +=
                '<button type="button" onclick="loginAs(' +
                row['ID'] +
                ", '" +
                officeName +
                '\')" class="btn-table" title="Login As"><i class="icon feather icon-log-in"></i></button>';
              html +=
                '<button type="button" onclick="onManageUsers(' +
                row['ID'] +
                ')" class="btn-table" title="Manage Users"><i class="icon feather icon-users"></i></button>';

              if (row['provider'] === 'domain' && row['is_ghost'] === 'no') {
                // tslint:disable-next-line: max-line-length
                html +=
                  '<button type="button" onclick="onDomainResync(' +
                  row['ID'] +
                  ')" class="btn-table" title="Domain Resync"><i class="icon feather icon-refresh-cw"></i></button>';
              }

              // tslint:disable-next-line: max-line-length
              html +=
                '<button onclick="onDelete(' +
                row['ID'] +
                ')" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>';

              return html;
            },
          },
        ],
        columnDefs: [
          {
            targets: [0],
            visible: false,
            searchable: false,
          },
          {
            targets: [1],
            name: 'provider',
            searchable: true,
          },
          {
            targets: [2],
            name: 'name',
            searchable: true,
          },
          {
            targets: [3],
            name: 'group',
            searchable: true,
          },
          {
            targets: [4],
            name: 'office_id',
            searchable: true,
          },
          {
            targets: [5],
            name: 'is_pro_trial',
            searchable: true,
          },
          {
            targets: [6],
            name: 'pro_enabled_users_count',
            searchable: false,
          },
          {
            targets: [7],
            name: 'is_account_master',
            searchable: false,
          },
          {
            targets: [8],
            searchable: false,
          },
        ],
        order: [],
        language: {
          paginate: {
            first: '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
            previous: '<i aria-hidden="true" class="fa fa-angle-left"></i>',
            next: '<i aria-hidden="true" class="fa fa-angle-right"></i>',
            last: '<i aria-hidden="true" class="fa fa-angle-double-right"></i>',
          },
        },
        select: {
          style: 'multiple',
        },
      });

      $('#datatable-clients').on('select.dt', function (e, dt, type, indexes) {
        $this.selectedRow = $this.selectedRow + 1;
        const data = dt.rows('.selected').data();

        for (let i = 0; i < $this.selectedRow; i++) {
          if ($this.selectedRowDataId.includes(data[i]['ID'])) {
            continue;
          } else {
            $this.selectedRowDataId.push(data[i]['ID']);
          }
        }
      });

      $('#datatable-clients').on('deselect.dt', function (e, dt, type, indexes) {
        $this.selectedRow = $this.selectedRow - 1;
        const data = dt.rows(indexes).data();
        const officeID = data[0]['ID'];

        $this.selectedRowDataId.forEach((value, index) => {
          if (value === officeID) {
            $this.selectedRowDataId.splice(index, 1);
          }
        });
      });

      this.loading = false;
    }, 500);
  }

  ngOnDestroy() {
    this.adminService.authService.cancelRequest();
    this.subscription.unsubscribe();
    this.subscriptionLoginAs.unsubscribe();
    this.subscriptionUsers.unsubscribe();
    this.deleteClientSubscription.unsubscribe();

    if ($.fn.DataTable.isDataTable(this.dataTableHtml)) {
      this.dataTableHtml.destroy();
    }
  }

  onDelete(id: number) {
    const $this = this;

    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;
        $this.clientService.httpDeleteClient(id);
      }
    }).catch(swal.noop);
  }

  onDeleteSelected() {
    const $this = this;

    swal({
      title: 'Are you sure you want to delete this selected office/s?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;

        $this.selectedRowDataId.forEach(element => {
          $this.clientService.httpDeleteClient(element);
        });
      }
    }).catch(swal.noop);
  }

  onEdit(id: number) {
    this.ngZone.run(() => {
      const url = this.router.serializeUrl(this.router.createUrlTree([`/admin/clients/${id}/edit`]));
      window.open(url, '_blank');
    });
    return;
  }

  onDomainResync(ID: number) {
    this.domainService.httpRefreshClient(ID);

    this.loading = true;
  }

  onManageUsers(id: number) {
    this.ngZone.run(() => {
      window.open(`/admin/clients/${id}/users`, '_blank');
    });
    return;
  }
}
