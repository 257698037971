import { Injectable, Inject } from '@angular/core';
import { HttpClient,   HttpRequest } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';

import * as moment from 'moment';
import { Observable } from 'rxjs/Rx';
import { AuthService } from './auth.service';

import { keys } from '../../environments/keys';
import { Auth } from '../models/auth.model';
import { Template } from '../models/template.model';
import { ProposalClient } from '../models/proposal-client.model';
import { FeatureFlagService } from './feature-flags.service';
declare var $: any;

@Injectable()
export class GlobalService {
    resetSearch = new Subject<boolean>();
    insertListings = new Subject();
    onSelectListing = new Subject();

    public element_listing_limit = 0;
    public template: any;
    public defaultColor5 = '#EEEEEE';

    constructor(
		@Inject(APP_CONFIG) public appConfig,
		private httpClient: HttpClient,
		public authService: AuthService,
    public featureFlagsService: FeatureFlagService
    ) {}

  httpGetAuthRefresh(provider = 'mydesktop'): Observable<any> {
    const groupid = this.authService.auth.groupid;
    const agentid = this.authService.auth.agentid;

    let url = 'auth';

    if (provider === 'vaultre') {
      url = 'vault';
    }

    if ( provider === 'domain' ) {
      url = 'domain';
    }

    if (provider === 'designly') {
      url = 'accounts';
    }

    if (provider === 'idashboard') {
      url = 'idashboard';
    }

    let isAdminLink = '/user';
    const isAdminData = localStorage.getItem('isAdmin');

    if ( isAdminData === 'yes' ) {
      isAdminLink = '/admin';
    }

    if ( provider === 'mydesktop') {
      isAdminLink = '';
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/' + url + '/refresh/' + groupid + '/' + agentid + isAdminLink
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          return response.body;
        }
      }
    );
  }

  cleanAgentName(value: string) {
    return value.replace(/â€™/g, '&#39;');
  }

  getEnvironmentKeys(key: string) {
    try {
      return keys[key];
    } catch (e) {
      console.error(e);
    }

    console.log('wahoo');
    return false;
  }

  fillProposalMergeClass(elementBlock: any, data: any) {
    if (typeof data.proposalClient !== 'undefined' && data.proposalClient) {
      const client = data.proposalClient;
      const allClientsName = data.clients.map((client: ProposalClient) => {
        return client.name;
      });
      const allClientsFirstName = data.clients.map((client: ProposalClient) => {
        return client.name.split(' ')[0];
      });

      if (allClientsName) {
        elementBlock.find('.proposal-client-name').html(allClientsName.join(' & '));
        elementBlock.find('.proposal-client-fname').html(allClientsFirstName.join(' & '));
      } else {
        elementBlock.find('.proposal-client-name').html('-');
        elementBlock.find('.proposal-client-fname').html('-');
      }

      if (typeof client.email !== 'undefined' && client.email) {
        elementBlock.find('.proposal-client-email').html(client.email);
      } else {
        elementBlock.find('.proposal-client-email').html('-');
      }

      if (typeof client.mobile !== 'undefined' && client.mobile) {
        elementBlock.find('.proposal-client-mobile').html(client.mobile);
      } else {
        elementBlock.find('.proposal-client-mobile').html('-');
      }
    }

    if (typeof data.property !== 'undefined' && data.property) {
      const property = data.property;
      let proposal_property_address = '';
      let forward_slash = '';

      if (typeof property.unit_number !== 'undefined' && property.unit_number) {
        elementBlock.find('.proposal-property-unit-number').html(property.unit_number);
        proposal_property_address += property.unit_number;
        forward_slash = '/';
      } else {
        elementBlock.find('.proposal-property-unit-number').html('');
      }

      if (typeof property.street_number !== 'undefined' && property.street_number) {
        elementBlock.find('.proposal-property-street-number').html(property.street_number);
        proposal_property_address += forward_slash + property.street_number;
      } else {
        elementBlock.find('.proposal-property-street-number').html('');
      }

      if (typeof property.street !== 'undefined' && property.street) {
        elementBlock.find('.proposal-property-street').html(property.street);
        proposal_property_address += ' ' + property.street;
      } else {
        elementBlock.find('.proposal-property-street').html('');
      }

      if (typeof property.suburb !== 'undefined' && property.suburb) {
        elementBlock.find('.proposal-property-suburb').html(property.suburb);
        proposal_property_address += ' ' + property.suburb;
      } else {
        elementBlock.find('.proposal-property-suburb').html('');
      }
      // default the proposal_property_address to vault display_address
      if (data.property.display_address) {
        proposal_property_address = data.property.display_address;
      }

      if (typeof proposal_property_address !== 'undefined' && proposal_property_address) {
        elementBlock.find('.proposal-property-address').html(proposal_property_address);
      }

      if (typeof property.postcode !== 'undefined' && property.postcode) {
        elementBlock.find('.proposal-property-postcode').html(property.postcode);
      } else {
        elementBlock.find('.proposal-property-postcode').html('');
      }

      if (typeof property.state !== 'undefined' && property.state) {
        elementBlock.find('.proposal-property-state').html(property.state);
      } else {
        elementBlock.find('.proposal-property-state').html('');
      }

      if (typeof property.beds !== 'undefined' && property.beds) {
        elementBlock.find('.proposal-property-beds').html(property.beds);
      } else {
        elementBlock.find('.proposal-property-beds').html('-');
      }

      if (typeof property.baths !== 'undefined' && property.baths) {
        elementBlock.find('.proposal-property-baths').html(property.baths);
      } else {
        elementBlock.find('.proposal-property-baths').html('-');
      }

      if (typeof property.cars !== 'undefined' && property.cars) {
        elementBlock.find('.proposal-property-cars').html(property.cars);
      } else {
        elementBlock.find('.proposal-property-cars').html('-');
      }

      if (typeof property.floor_area !== 'undefined' && property.floor_area && property.floor_area !== 0) {
        elementBlock.find('.proposal-property-floor-area').html(`${property.floor_area} sqm`);
      } else {
        elementBlock.find('.proposal-property-floor-area').html('-');
      }

      if (typeof property.land_area !== 'undefined' && property.land_area) {
        elementBlock.find('.proposal-property-land-area').html(`${property.land_area} sqm`);
      } else {
        elementBlock.find('.proposal-property-land-area').html('-');
      }

      if (typeof property.method_of_sale !== 'undefined' && property.method_of_sale) {
        elementBlock.find('.proposal-property-methodofsale').html(property.method_of_sale);
      } else {
        elementBlock.find('.proposal-property-methodofsale').html('-');
      }

      if (typeof property.price !== 'undefined' && property.price) {
        elementBlock.find('.proposal-property-displayprice').html(property.price);
      } else {
        elementBlock.find('.proposal-property-displayprice').html('-');
      }

      if (typeof property.price_lower !== 'undefined') {
        elementBlock.find('.proposal-property-appraisalpricelower').html(property.price_lower)
      } else {
        elementBlock.find('.proposal-property-appraisalpricelower').html('-');
      }

      if (typeof property.price_upper !== 'undefined') {
        elementBlock.find('.proposal-property-appraisalpriceupper').html(property.price_upper) 
      } else {
        elementBlock.find('.proposal-property-appraisalpriceupper').html('-');
      }
      
      // if ((typeof property.price_lower !== 'undefined' && property.price_lower) || 
      //     (typeof property.price_upper !== 'undefined' && property.price_upper)) {
      //   property.price_lower ? elementBlock.find('.proposal-property-appraisalpricelower').html(property.price_lower) 
      //     : elementBlock.find('.proposal-property-appraisalpricelower').html('-');

      //   property.price_upper ? elementBlock.find('.proposal-property-appraisalpriceupper').html(property.price_upper) 
      //     : elementBlock.find('.proposal-property-appraisalpriceupper').html('-');
      // } else {
      //   elementBlock.find('.proposal-property-appraisalpricelower').html('-');
      //   elementBlock.find('.proposal-property-appraisalpriceupper').html('-');
      // }

      if (typeof property.sold_price !== 'undefined' && property.sold_price) {
        elementBlock.find('.proposal-property-soldprice').html(`$${parseFloat(property.sold_price).toLocaleString()}`);
        elementBlock.find('.proposal-property-saleprice').html(`$${parseFloat(property.sold_price).toLocaleString()}`);
      } else {
        elementBlock.find('.proposal-property-soldprice').html('-');
        elementBlock.find('.proposal-property-saleprice').html('-');
      }

      if (typeof property.photo !== 'undefined' && property.photo) {
        elementBlock.find('.proposal-property-image').attr('src', property.photo);
      }

      if (typeof property.selling_price !== 'undefined' && property.selling_price) {
        elementBlock.find('.proposal-property-sellingprice').html(property.selling_price);
      } else {
        elementBlock.find('.proposal-property-sellingprice').html('-');
      }

      if (typeof property.status !== 'undefined' && property.status) {
        elementBlock.find('.proposal-property-status').html(property.status);
      } else {
        elementBlock.find('.proposal-property-status').html('-');
      }

      if (typeof property.property_type !== 'undefined' && property.property_type) {
        elementBlock.find('.proposal-property-proptype').html(property.property_type);
      } else {
        elementBlock.find('.proposal-property-proptype').html('-');
      }
    }

    if (typeof data.agent !== 'undefined' && data.agent) {
      const agent = data.agent;

      if (typeof agent.firstname !== 'undefined' && agent.firstname) {
        elementBlock.find('.proposal-agent-first-name').html(agent.firstname);
      } else {
        elementBlock.find('.proposal-agent-first-name').html('-');
      }

      if (typeof agent.lastname !== 'undefined' && agent.lastname) {
        elementBlock.find('.proposal-agent-last-name').html(agent.lastname);
      } else {
        elementBlock.find('.proposal-agent-last-name').html('-');
      }

      if (typeof agent.email !== 'undefined' && agent.email) {
        elementBlock.find('.proposal-agent-email').html(agent.email);
      } else {
        elementBlock.find('.proposal-agent-email').html('-');
      }

      if (typeof agent.mobile !== 'undefined' && agent.mobile) {
        elementBlock.find('.proposal-agent-mobile').html(agent.mobile);
      } else {
        elementBlock.find('.proposal-agent-mobile').html('-');
      }

      if (typeof agent.photo !== 'undefined' && agent.photo) {
        elementBlock.find('.proposal-agent-photo').attr('src', agent.photo);
      }

      if (typeof agent.position !== 'undefined' && agent.position) {
        elementBlock.find('.proposal-agent-position').html(agent.position);
      } else {
        elementBlock.find('.proposal-agent-position').html('-');
      }
    }

    if (typeof data.investments !== 'undefined' && data.investments && data.investments.length) {
      const strategyGrid = elementBlock.find('.dsgnly-marketing-schedule-grid');

      if (strategyGrid.length) {
        let schedulePackage = strategyGrid.find('.dsgnly-schedule-package').first();
        
        if (schedulePackage.length === 0) {
            const scheduleItem = strategyGrid.find('.dsgnly-schedule-item:first').html();
            
            schedulePackage = $(`
                <div class="row category-holder dsgnly-schedule-package justify-content-between">
                    <div class="col col-12 col-xl-4 dsgnly-schedule-package-title">
                        <h2 class="text-sub-heading dsgnly-color-subheading dsgnly-schedule-title"></h2>
                    </div>
                    <div class="col col-12 col-xl-8">
                      <div class="row dsgnly-schedule-package-item justify-content-between list-info-holder">
                        <div class="col-12 col-md-6 dsgnly-schedule-item">
                          ${scheduleItem}
                        </div>
                      </div>
                    </div>
                </div>
            `);
            strategyGrid.append(schedulePackage);
            strategyGrid.parent().removeClass('col-xl-8');
        }

          const schedulePackageItem = schedulePackage.find('.dsgnly-schedule-item').first();
            strategyGrid.empty();
            let totalAmount = 0;
            const schedulePackageClone = schedulePackage.clone();

            const scheduleTitle = elementBlock.find('.dsgnly-schedule-title:first');
            if (scheduleTitle.html() !== '') {
              scheduleTitle.parent().hide();
            }

            data.investments.forEach((investment: any) => {

              if (schedulePackage.length > 0) {
                schedulePackageClone.find('.dsgnly-schedule-package-item').empty();
              }

              schedulePackageClone.find('.dsgnly-schedule-title').first().html(investment.title);

              investment.investment_items.forEach((item: any) => {
                totalAmount += parseFloat(item.amount);
                schedulePackageItem.find('.dsgnly-schedule-item-title').html(item.name);
                schedulePackageItem.find('.dsgnly-schedule-item-price').html(`$${item.amount.toLocaleString()}`);
                schedulePackageClone.find('.dsgnly-schedule-package-item').append(schedulePackageItem.prop('outerHTML'));
              });

              strategyGrid.append(schedulePackageClone.prop('outerHTML'));
            });
            totalAmount = parseFloat(totalAmount.toFixed(2));
            
            elementBlock.find('.dsgnly-schedule-total-price').html(`$${totalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`);
            
            if(typeof data.property.hide_price !== 'undefined' && data.property.hide_price === 1) {
              elementBlock.find('.dsgnly-schedule-item-price').hide();
              elementBlock.find('.dsgnly-schedule-total-price').parent().hide();
            } else {
              elementBlock.find('.dsgnly-schedule-item-price').show();
              elementBlock.find('.dsgnly-schedule-total-price').parent().show();
            }
        
      }
    }

    if (typeof data.advertisementTitle !== 'undefined' && data.advertisementTitle) {
      elementBlock.find('.dsgnly-schedule-title').html(data.advertisementTitle);
    }

    return elementBlock.html();
  }

  /*
    auth: Authenticated user details
    elementBlock: The query selector used to search for merge fields class
    listing: For Merge Fields related
    data: For the user
  */
  fillMergeClass(auth: Auth, elementBlock: any, listing: any, data: any) {
    try {
      const clientSetting = auth.client;
      const groupSetting = clientSetting.group.master;
      const template = typeof data.template !== 'undefined' && data.template ? data.template as Template : null;

      elementBlock.find('.md-prop-field-date').html(moment().format('Do, MMM YYYY'));
      /* md-prop-field-new-date - New format of today's date for Bayleys  */
      elementBlock.find('.md-prop-field-new-date').html(moment().format('DD.MM.YYYY'));
      
      let currency = '$';
        if (this.authService.isUk()) {
          currency = '£';
        }

      if (template) {
        if (template.category_id === 1) {
          elementBlock.find('table').first().attr('data-propid', listing.id);
        }

        if (listing.images !== null && listing.images.length > 0) {
          let imageUrl = listing.images[0].url;
          if (typeof listing.images[0].fullImage !== 'undefined' && listing.images[0].hasOwnProperty('fullImage')
            && listing.images[0].fullImage !== '') {
            imageUrl = listing.images[0].fullImage;
          }

          const imgEl = elementBlock.find('.md-prop-field-image').attr('src', imageUrl);
          const imgElHighRes = elementBlock.find('.md-prop-field-hiresimage').attr('src', listing.images[0].hiresurl);

          /* 
          Loop over the images to get their 
          captions and set it to merge fields 
          */
          listing.images.forEach((image, index) => {

            let tempMergeField = '.md-prop-field-image-caption';

            if (index > 0) {
              tempMergeField += `-${index + 1}`;
            }
            if (image.caption !== null) {
              elementBlock.find(tempMergeField).html(image.caption);
            }
          });

          if (template.category_id !== 7 &&
            template.category_id !== 1 &&
            template.category_id !== 2 && template.category_id !== 3) {
            if (typeof imgEl !== 'undefined') {
              imgEl.css({ top: 0, left: 0 });
            }

            if (typeof imgElHighRes !== 'undefined') {
              imgElHighRes.css({ top: 0, left: 0 });
            }
          }

          // tslint:disable-next-line: max-line-length
          const defaultImageUrl = 'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/1/images/propphoto_spacer.png';
          const blockImages = elementBlock.find('[class*="md-prop-field-image"]');

          if (blockImages.length) {
            blockImages.each(function() {
              const classNames = $(this).attr('class');

              if (classNames) {
                const blockImageClass = classNames.match(/md-prop-field-image-\d+/g);

                if (blockImageClass && blockImageClass.length && blockImageClass.length === 1) {
                  // tslint:disable-next-line: radix
                  const imageIndex = parseInt(String(blockImageClass[0]).substring(20));

                  let listingImageUrl = defaultImageUrl;

                  if (typeof listing.images[imageIndex - 1] !== 'undefined') {
                    const img = listing.images[imageIndex - 1];
                    listingImageUrl = img.hasOwnProperty('pricefinderImage') && img.pricefinderImage !== '' ? 
                    img.pricefinderImage : img.url;
                  }

                  const imgElSub = $(this).attr('src', listingImageUrl);

                  if (template.category_id !== 7 &&
                    template.category_id !== 1 &&
                    template.category_id !== 2 && template.category_id !== 3) {
                    if (typeof imgElSub !== 'undefined') {
                      imgElSub.css({ top: 0, left: 0 });
                    }
                  }
                }
              }
            });
          }

          const blockHiresImages = elementBlock.find('[class*="md-prop-field-hiresimage"]');

          blockHiresImages.each(function() {
            const classNames = $(this).attr('class');

            if (classNames) {
              const blockHiresImageClass = classNames.match(/md-prop-field-hiresimage-\d+/g);

              if (blockHiresImageClass && blockHiresImageClass.length && blockHiresImageClass.length === 1) {
                // tslint:disable-next-line: radix
                const imageIndex = parseInt(String(blockHiresImageClass[0]).substring(25));

                let listingImageUrl = defaultImageUrl;

                if (typeof listing.images[imageIndex - 1] !== 'undefined') {
                  listingImageUrl = listing.images[imageIndex - 1].hiresurl;
                }

                const imgElSub = $(this).attr('src', listingImageUrl);

                if (template.category_id !== 7 &&
                  template.category_id !== 1 &&
                  template.category_id !== 2 && template.category_id !== 3) {
                  if (typeof imgElSub !== 'undefined') {
                    imgElSub.css({ top: 0, left: 0 });
                  }
                }
              }
            }
          });
        }
      }

      if (auth.provider === 'vaultre' && typeof data.fillType === 'undefined') {
        if (listing.floorplans !== null && listing.floorplans.length > 0) {
          const largeFloorPlans = elementBlock.find('.md-prop-field-imagefloorplan-lrg');
          const smallFloorPlans = elementBlock.find('.md-prop-field-imagefloorplan-sml');

          largeFloorPlans.attr('src',  listing.floorplans[0].url).removeAttr('style');
          smallFloorPlans.attr('src',  listing.floorplans[0].url).removeAttr('style');

          for (let i = 0; i < listing.floorplans.length; i++) {
            const idx = i + 1;

            elementBlock.find('.md-prop-field-imagefloorplan-sml-' + idx).attr('src',  listing.floorplans[i].url).removeAttr('style');
            elementBlock.find('.md-prop-field-imagefloorplan-lrg-' + idx).attr('src',  listing.floorplans[i].hiresurl).removeAttr('style');
          }
        } else {
          elementBlock.find('.md-prop-field-imagefloorplan-sml').attr('src',  'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/1/images/floorplan_placeholder.svg').removeAttr('style');
          elementBlock.find('.md-prop-field-imagefloorplan-lrg').attr('src',  'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/1/images/floorplan_placeholder.svg').removeAttr('style');
        }
      }

      if (this.authService.getApp() === 'MyDesign3' || auth.provider === 'domain') {
        if (elementBlock.find('[class*="md-prop-field-bulletpoint-"]').length > 0) {
          for (let i = 0; i < elementBlock.find('[class*="md-prop-field-bulletpoint-"]').length; i++) {
            const bullet_number = i + 1;

            if (typeof listing.bulletpoints[i] !== 'undefined') {
              elementBlock.find('.md-prop-field-bulletpoint-' + bullet_number).html(listing.bulletpoints[i].item);
            } else {
              elementBlock.find('.md-prop-field-bulletpoint-' + bullet_number).html('');
            }
          }
        }
      }

      if (typeof listing.auctiondate !== 'undefined' && listing.auctiondate != null) {
        elementBlock.find('.md-prop-field-listing-type').html('For Auction');
        /* New Title Revision of md-prop-field-listing-type */
        elementBlock.find('.md-prop-field-listing-type-title').html('<b>Type</b><span>For Auction</span>');
        
        elementBlock.find('.md-prop-field-rh-status').html('Guide');
        elementBlock.find('.md-prop-field-gh-status').html('For Auction');

        if (listing.listing_status !== 'settled' && listing.listing_status !== 'unconditional'
          && listing.listing_status !== 'settled,unconditional') {

          const dateToday = Date.parse(new Date().toString());

          let dateAuction = 0;
  
          if (typeof listing.auctiondateformat !== 'undefined' && listing.auctiondateformat !== null && listing.auctiondateformat !== '') {
            dateAuction = Date.parse(listing.auctiondateformat.toString());
          }
          
          if (dateAuction >= dateToday) {
            elementBlock.find('.md-prop-field-status').html('For Auction');
          } else {
            if (listing.portalstatus !== null && listing.portalstatus === 'conditional') {
              elementBlock.find('.md-prop-field-status').html('Under Offer');
            } else {
              if (listing.listing_status !== null && listing.listing_status === 'listing') {
                elementBlock.find('.md-prop-field-status').html('For Sale');
              }
            }
          }
        } else {
          elementBlock.find('.md-prop-field-rh-status').html('Sold');
        }

        let auctionDateFormat = '';

        if (typeof listing.auctiondateformat !== 'undefined' && listing.auctiondateformat !== null && listing.auctiondateformat) {
          let tempAuctionDate = moment(listing.auctiondateformat);
          auctionDateFormat = tempAuctionDate.format('dddd Do MMMM, h:mmA');
          
          // Check if custom email auction template
          if (elementBlock.find('.auction').length > 0) {
            const dateToday = moment();
            
            // Check if auction date is not passed
            if (tempAuctionDate >= dateToday) {

              let auctionVenue = listing.auctionvenue;
              let auctionDate = moment(listing.auctiondateformat).format('DD');
              let auctionMonth = moment(listing.auctiondateformat).format('MMM');
              let auctionTime = moment(listing.auctiondateformat).format('hh.mma');
              let auctionMap = `https://maps.google.com/?q=${auctionVenue}`;

              elementBlock.find('.custom-auction-venue').html(auctionVenue);
              elementBlock.find('.custom-auction-date').html(auctionDate);
              elementBlock.find('.custom-auction-month').html(auctionMonth);
              elementBlock.find('.custom-auction-time').html(auctionTime);
              elementBlock.find('.custom-auction-map').attr('href', auctionMap);
            } else {
              // Remove auction section
              elementBlock.find('.auction').remove();
            }
          }

          elementBlock.find('.md-prop-field-auctiondate').html(listing.auctiondate);
          elementBlock.find('.md-prop-field-auctiondate-title').html('<b>Auction</b> ' + auctionDateFormat);
          elementBlock.find('.md-prop-field-auctiondate-title-br-br').remove();
          elementBlock.find('.md-prop-field-auctiondate-title-br').html('<b>Auction</b> ' + auctionDateFormat + '<br class="md-prop-field-auctiondate-title-br-br">');
          elementBlock.find('.md-prop-field-auctiondate-title-alt').html('<b>Auction</b><br> ' + auctionDateFormat);
        } else {
          if (elementBlock.find('.auction').length > 0) {
            // Remove auction section
            elementBlock.find('.auction').remove();
          }

          elementBlock.find('.md-prop-field-auctiondate').html('');
          elementBlock.find('.md-prop-field-auctiondate-title').html('');
          elementBlock.find('.md-prop-field-auctiondate-title-br').html('');
          elementBlock.find('.md-prop-field-auctiondate-title-alt').html('');
        }
      } else {
        elementBlock.find('.md-prop-field-auctiondate').html('');
        elementBlock.find('.md-prop-field-auctiondate-title').html('');
        elementBlock.find('.md-prop-field-auctiondate-title-alt').html('');
        if (listing.listing_type === 'sale') {
          elementBlock.find('.md-prop-field-listing-type').html('For Sale');
           /* New Title Revision of md-prop-field-listing-type */
        elementBlock.find('.md-prop-field-listing-type-title').html('<b>Type</b><span>For Sale</span>');
          
          elementBlock.find('.md-prop-field-status').html('For Sale');
          elementBlock.find('.md-prop-field-rh-status').html('Guide');
          elementBlock.find('.md-prop-field-gh-status').html('For Sale');
        }
        if (listing.listing_type === 'sale' && listing.listing_category === 'commercial') {
          elementBlock.find('.md-prop-field-rh-status').html('Sale');
          elementBlock.find('.md-prop-field-gh-status').html('Sale');
        }
        if (listing.listing_type === 'rent') {
          if (auth.provider === 'vaultre') {
            elementBlock.find('.md-prop-field-listing-type').html('For Lease');
            /* New Title Revision of md-prop-field-listing-type */
            elementBlock.find('.md-prop-field-listing-type-title').html('<b>Type</b><span>For Lease</span>');
            if (this.authService.isUk()) {
              elementBlock.find('.md-prop-field-status').html('For Rent');
            } else {
              elementBlock.find('.md-prop-field-status').html('For Lease');
            }
            elementBlock.find('.md-prop-field-gh-status').html('For Lease');
          } else {
            elementBlock.find('.md-prop-field-listing-type').html('For Rent');
            /* New Title Revision of md-prop-field-listing-type */
            elementBlock.find('.md-prop-field-listing-type-title').html('<b>Type</b><span>For Rent</span>');
            elementBlock.find('.md-prop-field-status').html('For Rent');
            elementBlock.find('.md-prop-field-gh-status').html('For Rent');
          }
          elementBlock.find('.md-prop-field-rh-status').html('Lease');
        }
        if (listing.listing_type === 'rent' && listing.listing_category === 'commercial') {
          elementBlock.find('.md-prop-field-listing-type').html('For Lease');
           /* New Title Revision of md-prop-field-listing-type */
          elementBlock.find('.md-prop-field-listing-type-title').html('<b>Type</b><span>For Lease</span>');
          if (this.authService.isUk()) {
            elementBlock.find('.md-prop-field-status').html('For Rent');
          } else {
            elementBlock.find('.md-prop-field-status').html('For Lease');
          }
          elementBlock.find('.md-prop-field-rh-status').html('Lease');
          elementBlock.find('.md-prop-field-gh-status').html('For Lease');
        }
        if (listing.listing_type === 'rent' && (listing.listing_status === 'settled' || listing.listing_status === 'leased')) {
          elementBlock.find('.md-prop-field-status').html('Leased');
          elementBlock.find('.md-prop-field-rh-status').html('Leased');
          elementBlock.find('.md-prop-field-gh-status').html('Leased');
        }
        if (listing.listing_status === 'leased' && listing.listing_category === 'commercial') {
          elementBlock.find('.md-prop-field-rh-status').html('Leased');
          elementBlock.find('.md-prop-field-gh-status').html('Leased');
        }
        if (listing.listing_type === 'sale' && (listing.listing_status === 'unconditional' ||
        listing.listing_status === 'settled' || listing.listing_status === 'settled,unconditional')) {
          elementBlock.find('.md-prop-field-status').html('Sold');
          elementBlock.find('.md-prop-field-rh-status').html('Sold');
          elementBlock.find('.md-prop-field-gh-status').html('Sold');
        }
        if (listing.listing_category === 'holidayRental') {
          elementBlock.find('.md-prop-field-status').html('Holiday Rental');
          elementBlock.find('.md-prop-field-gh-status').html('Holiday Rental');
        }

        if (listing.listing_status === 'unconditional') {
          elementBlock.find('.md-prop-field-status').html('Sold');
          elementBlock.find('.md-prop-field-gh-status').html('Sold');
        }

        if (listing.listing_status === 'conditional') {
          elementBlock.find('.md-prop-field-gh-status').html('Sold');
        }

        if (listing.listing_status === 'leased') {
          elementBlock.find('.md-prop-field-status').html('Leased');
          elementBlock.find('.md-prop-field-gh-status').html('Leased');
        }

        if (listing.listing_status === 'settled,unconditional') {
          elementBlock.find('.md-prop-field-status').html('Sold');
          elementBlock.find('.md-prop-field-gh-status').html('Sold');
        }

        if (listing.portalstatus === 'conditional') {
          elementBlock.find('.md-prop-field-status').html('Under Offer');
        }
      }

      if (auth.provider === 'idashboard') {
        let statusTitle = this.capitalizeFirstLetter(listing.listing_status);

        if (listing.listing_status === 'appraisal') {
          statusTitle = 'Appraisal';
        }

        if (listing.listing_status === 'listing' && listing.listing_type !== 'lease') {
          statusTitle = 'For Sale';
        }

        if (listing.listing_type === 'lease' && listing.listing_status !== 'leased') {
          statusTitle = 'For Lease';
        }

        if (listing.listing_status === 'application') {
          statusTitle = 'For Lease';
        }

        elementBlock.find('.md-prop-field-status').html(statusTitle);
      }

      if (typeof listing.saleLifeId !== 'undefined' &&
      listing.saleLifeId !== null &&
      listing.listing_type === 'sale' &&
      listing.listing_status !== 'unconditional' &&
      listing.listing_status !== 'settled' && listing.listing_status !== 'settled,unconditional') {
        elementBlock.find('.md-prop-field-lifeid').html(listing.saleLifeId);
        elementBlock.find('.md-prop-field-lifeid-alt').html('L' + listing.saleLifeId);
      } else if (typeof listing.leaseLifeId !== 'undefined' &&
      listing.leaseLifeId !== null && listing.listing_type === 'rent' &&
      listing.listing_status !== 'unconditional' && listing.listing_status !== 'settled' &&
      listing.listing_status !== 'settled,unconditional') {
        elementBlock.find('.md-prop-field-lifeid').html(listing.leaseLifeId);
        elementBlock.find('.md-prop-field-lifeid-alt').html('R' + listing.leaseLifeId);
      } else if (typeof listing.lifeId !== 'undefined' && listing.lifeId !== null &&
      (listing.listing_status === 'unconditional' || listing.listing_status === 'settled' ||
      listing.listing_status === 'settled,unconditional')) {
        elementBlock.find('.md-prop-field-lifeid').html(listing.lifeId);
        elementBlock.find('.md-prop-field-lifeid-alt').html('L' + listing.lifeId);
      } else if (typeof listing.leasedlifeId !== 'undefined' && listing.leasedlifeId !== null &&
      listing.listing_status === 'leased') {
        elementBlock.find('.md-prop-field-lifeid').html(listing.leasedlifeId);
        elementBlock.find('.md-prop-field-lifeid-alt').html('R' + listing.leasedlifeId);
      } else {
        elementBlock.find('.md-prop-field-lifeid').html('');
        elementBlock.find('.md-prop-field-lifeid-alt').html('');
      }

      if (typeof listing.tenureortitletype !== 'undefined' && listing.tenureortitletype !== null) {
        let unitType;
        elementBlock.find('.md-prop-field-titletype').html(listing.tenureortitletype);
        // Additional checker -- if isUnitTitle === true, then we use Unit Title as a label
        if(typeof(listing.isUnitTitle !== 'undefined') && listing.isUnitTitle) {
          unitType = "Unit Title";
        } else {
          unitType = "Title";
        }
        elementBlock.find('.md-prop-field-titletype-title').html(`<b>${unitType}</b> <span>${listing.tenureortitletype}</span>`);
        elementBlock.find('.md-prop-field-titletype-title-br-br').remove();
        elementBlock.find('.md-prop-field-titletype-title-br').html(`<b>${unitType}</b> <span>${listing.tenureortitletype}</span><br class="md-prop-field-titletype-title-br-br">`);
        
      } else {
        elementBlock.find('.md-prop-field-titletype').html('');
        elementBlock.find('.md-prop-field-titletype-title').html('');
        elementBlock.find('.md-prop-field-titletype-title-br').html('');
      }

      if (typeof listing.tenureortitletype !== 'undefined' && listing.tenureortitletype !== null && listing.tenureortitletype === 'Leasehold') {
        if (listing.leasehold !== 'undefined' && listing.leasehold !== null && listing.leasehold) {
          elementBlock.find('.md-prop-field-groundrent-title').html(`<b>Ground Rent</b> ${listing.leasehold.ground_rent}`);
          elementBlock.find('.md-prop-field-servicecharge-title').html(`<b>Service/Estate Charge</b> ${listing.leasehold.service_charge}`);
          elementBlock.find('.md-prop-field-leaseend').html(listing.leasehold.lease_end);
          elementBlock.find('.md-prop-field-leaseend-title').html(`<b>End Date of Lease</b> ${listing.leasehold.lease_end}`);
        } else {
          elementBlock.find('.md-prop-field-groundrent-title').html('');
          elementBlock.find('.md-prop-field-servicecharge-title').html('');
          elementBlock.find('.md-prop-field-leaseend').html('');
          elementBlock.find('.md-prop-field-leaseend-title').html('');
        }
      } else {
        elementBlock.find('.md-prop-field-groundrent-title').html('');
        elementBlock.find('.md-prop-field-servicecharge-title').html('');
        elementBlock.find('.md-prop-field-leaseend').html('');
        elementBlock.find('.md-prop-field-leaseend-title').html('');
      }

      if (listing.shared_ownership !== 'undefined' && listing.shared_ownership !== null && listing.shared_ownership) {
        elementBlock.find('.md-prop-field-sharedownershippercentage-title').html(`<b>Shared Ownership Share</b> ${listing.shared_ownership.percentage}`);
        elementBlock.find('.md-prop-field-sharedownershiprent-title').html(`<b>Shared Ownership Rent</b> ${listing.shared_ownership.rent}`);
      } else {
        elementBlock.find('.md-prop-field-sharedownershippercentage-title').html('');
        elementBlock.find('.md-prop-field-sharedownershiprent-title').html('');
      }

      if (typeof listing.lotnumber !== 'undefined' && listing.lotnumber !== null && listing.lotnumber !== '') {
        elementBlock.find('.md-prop-field-lotnumber').html(listing.lotnumber);
        elementBlock.find('.md-prop-field-lotnumber-title').html('<b>Lot</b>&nbsp' + listing.lotnumber);
        elementBlock.find('.md-prop-field-lotnumber-title-br-br').remove();
        elementBlock.find('.md-prop-field-lotnumber-title-br').html('<b>Lot</b>&nbsp' + listing.lotnumber + '<br class="md-prop-field-lotnumber-title-br-br">');
      } else {
        elementBlock.find('.md-prop-field-lotnumber').html('');
        elementBlock.find('.md-prop-field-lotnumber-title').html('');
        elementBlock.find('.md-prop-field-lotnumber-title-br').html('');
      }

      if (typeof listing.certificateoftitle !== 'undefined' && listing.certificateoftitle !== null && listing.certificateoftitle !== '') {
        elementBlock.find('.md-prop-field-ct').html(listing.certificateoftitle);
        elementBlock.find('.md-prop-field-ct-title').html('<b>CT</b>&nbsp' + listing.certificateoftitle);
        elementBlock.find('.md-prop-field-ct-title-br-br').remove();
        elementBlock.find('.md-prop-field-ct-title-br').html('<b>CT</b>&nbsp' + listing.certificateoftitle + '<br class="md-prop-field-ct-title-br-br">');
      } else {
        elementBlock.find('.md-prop-field-ct').html('');
        elementBlock.find('.md-prop-field-ct-title').html('');
        elementBlock.find('.md-prop-field-ct-title-br').html('');
      }

      if (typeof listing.setSaleDate !== 'undefined' && listing.setSaleDate !== null && listing.setSaleDate !== '') {
        elementBlock.find('.md-prop-field-setsaledate').html(listing.setSaleDate);
      } else {
        elementBlock.find('.md-prop-field-setsaledate').html('');
      }

      const listingHeading = listing.heading !== null ? listing.heading : '';
      elementBlock.find('.md-prop-field-heading').html(listingHeading);

      /* Get all element with class 'md-prop-field-heading-' */
      const fieldHeading = elementBlock.find("[class*='md-prop-field-heading-']");
      
      if (fieldHeading.length > 0) {
        fieldHeading.each(function() {

          /* Get attribute class value and split */
          const fieldHeadingClass = $(this).attr('class');
          const fieldHeadingClasses = fieldHeadingClass.split(' ');

          if (fieldHeadingClasses.length > 0) {
            fieldHeadingClasses.forEach(element => {

              /* Check if class 'md-prop-field-heading-' */
              if (String(element).includes('md-prop-field-heading-')) {

                /* Get the character limit in class */
                const characterLimit = element.split('md-prop-field-heading-')[1];

                if (listingHeading.length <= characterLimit) {
                  /* If less or equal the limit, add all */
                  elementBlock.find('.md-prop-field-heading-' + characterLimit).html(listingHeading);
                } else {
                  /* If length greater than limit, trim text */
                  let trimmedString = listingHeading.substring(0, +characterLimit);
                  trimmedString = trimmedString + '...';

                  elementBlock.find('.md-prop-field-heading-' + characterLimit).html(trimmedString);
                }
              }
            });
          }
        });
      }

      if (typeof listing.beds !== 'undefined' && listing.beds !== null && listing.beds !== '' && listing.beds !== 0) {
        elementBlock.find('.md-prop-field-beds').html(listing.beds);

        const bedsHarcourts = '<span class="md-prop-field-beds" style="display: inline-block; vertical-align: middle">'+listing.beds+'</span><span>&nbsp;</span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_bed.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" />';
        elementBlock.find('.md-prop-field-beds-harcourts').html(bedsHarcourts);

        const bedsHarcourtsSVG = '<p class="md-prop-field-beds value">'+listing.beds+'</p><img class="bed icon" src="https://resources.designly.com.au/resources/admin/4349/Images/social-medi/bed.svg" />';
        elementBlock.find('.md-prop-field-beds-harcourts-svg').html(bedsHarcourtsSVG);

      } else {
        elementBlock.find('.md-prop-field-beds').html('-');
        elementBlock.find('.md-prop-field-beds-harcourts').html('');
        elementBlock.find('.md-prop-field-beds-harcourts-svg').html('');
      }
      
      if (typeof listing.customfeatures !== 'undefined' && listing.customfeatures) {
        const hzCFeatures = elementBlock.find("[class*='md-prop-field-feature-']");
        hzCFeatures.each(function () {
          const classes = ($(this).attr('class')).split(' ');
          /* Valid format md-prop-field-feature-{featureName}-{clientName} */
          const mergeClass = classes[0].split('-');
          const feature = mergeClass[4]; /* Property feature name */
          const client = mergeClass[5]; /* Client name of the merge field class */
          const isSvg = (typeof mergeClass[6] !== 'undefined') ? mergeClass[6] : ''; /* Svg merge field */
          let count = '';
          let html = '';
          const block = elementBlock.find(`.md-prop-field-feature-${feature}-${client}`+(isSvg ? '-'+isSvg : ''));
          switch (feature) {
            case "livingrooms":
              if (typeof listing.customfeatures[feature] !== 'undefined' && listing.customfeatures[feature] != null) {
                count = listing.customfeatures[feature][0].data;
                const imgSrc = 'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/image/admin/4349/Images/custom_icon_living_room.png';
                const svgImgSrc = 'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/image/admin/4349/Images/property-guide/icon_livingroom.svg';
                html = (parseInt(count) > 0) ? `<span class="md-prop-field-feature-${feature}-${client}" style="display: inline-block; vertical-align: middle">${count}</span><span>&nbsp;</span><img src="${imgSrc}" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" /><span>&nbsp;&nbsp;</span>` : '';
                if (isSvg) {
                  html = (parseInt(count) > 0) ? `<p class="md-prop-field-${feature} value">${count}</p><img class="${feature} icon" src="${svgImgSrc}" />` : '';
                }
              }
            break;
          }
          block.html(html);
        });
      }

      if (typeof listing.baths !== 'undefined' && listing.baths !== null && listing.baths !== '' && listing.baths !== 0) {
        elementBlock.find('.md-prop-field-baths').html(listing.baths);

        const bathsHarcourts = '<span class="md-prop-field-baths" style="display: inline-block; vertical-align: middle">'+listing.baths+'</span><span>&nbsp;</span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_bath.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" /><span>&nbsp;&nbsp;</span>';
        elementBlock.find('.md-prop-field-baths-harcourts').html(bathsHarcourts);

        const bathsHarcourtsSVG = '<p class="md-prop-field-baths value">'+listing.baths+'</p><img class="bath icon" src="https://resources.designly.com.au/resources/admin/4349/Images/social-medi/bath.svg" />';
        elementBlock.find('.md-prop-field-baths-harcourts-svg').html(bathsHarcourtsSVG);
      } else {
        elementBlock.find('.md-prop-field-baths').html('-');
        elementBlock.find('.md-prop-field-baths-harcourts').html('');
        elementBlock.find('.md-prop-field-baths-harcourts-svg').html('');
      }

      if (typeof listing.toilets !== 'undefined' && listing.toilets !== null && listing.toilets !== '' && listing.toilets !== 0) {
        elementBlock.find('.md-prop-field-toilets').html(listing.toilets);

        const toiletsHarcourts = '<span class="md-prop-field-toilets" style="display: inline-block; vertical-align: middle">'+listing.toilets+'</span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_toilet.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" /><span>&nbsp;&nbsp;</span>';

        elementBlock.find('.md-prop-field-toilets-harcourts').html(toiletsHarcourts);
      } else {
        elementBlock.find('.md-prop-field-toilets').html('-');
        elementBlock.find('.md-prop-field-toilets-harcourts').html('');
      }

      if (typeof listing.cars !== 'undefined' && listing.cars !== null && listing.cars !== '' && listing.cars !== 0) {
        elementBlock.find('.md-prop-field-cars').html(listing.cars);
        elementBlock.find('.md-prop-field-cars-title').html(`<b>Parking</b> <span>${listing.cars}</span>`);
        elementBlock.find('.md-prop-field-cars-title-br-br').remove();
        elementBlock.find('.md-prop-field-cars-title-br').html(`<b>Parking</b> <span>${listing.cards}</span> <br class="md-prop-field-cars-title-br-br">`);

        const carsHarcourts = '<span class="md-prop-field-cars" style="display: inline-block; vertical-align: middle">'+listing.cars+'</span><span>&nbsp;</span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_car.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" /><span>&nbsp;&nbsp;</span>';

        elementBlock.find('.md-prop-field-cars-harcourts').html(carsHarcourts);
      } else {
        elementBlock.find('.md-prop-field-cars').html('-');
        elementBlock.find('.md-prop-field-cars-title').html('-');
        elementBlock.find('.md-prop-field-cars-title-br').html('-');
        elementBlock.find('.md-prop-field-cars-harcourts').html('');
      }

      if (typeof listing.carports !== 'undefined' && listing.carports !== null && listing.carports !== '' && listing.carports !== 0) {
        elementBlock.find('.md-prop-field-carports').html(listing.carports);

        const carsportsHarcourts = '<span class="md-prop-field-carports" style="display: inline-block; vertical-align: middle">'+listing.carports+'</span><span>&nbsp;&nbsp;</span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_car_port.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" /><span>&nbsp;&nbsp;</span>';

        elementBlock.find('.md-prop-field-carports-harcourts').html(carsportsHarcourts);
      } else {
        elementBlock.find('.md-prop-field-carports').html('-');
        elementBlock.find('.md-prop-field-carports-harcourts').html('');
      }


      if( typeof listing.officeName !== 'undefined' && listing.officeName !== null && listing.officeName !== '') {
        elementBlock.find('.md-prop-field-lofficename').html(listing.officeName);
      } else {
        elementBlock.find('.md-prop-field-lofficename').html('-');
      }

      if (typeof listing.garages !== 'undefined' && listing.garages !== null && listing.garages !== '' && listing.garages !== 0) {
        elementBlock.find('.md-prop-field-garages').html(listing.garages);

        const garagesHarcourts = '<span class="md-prop-field-garages" style="display: inline-block; vertical-align: middle">'+listing.garages+'</span><span>&nbsp;</span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_car.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" /><span>&nbsp;&nbsp;</span>';
        elementBlock.find('.md-prop-field-garages-harcourts').html(garagesHarcourts);

        const garagesHarcourtsSVG = '<p class="md-prop-field-garages value">'+listing.garages+'</p><img class="car icon" src="https://resources.designly.com.au/resources/admin/4349/Images/social-medi/car.svg" />';
        elementBlock.find('.md-prop-field-garages-harcourts-svg').html(garagesHarcourtsSVG);
      } else {
        elementBlock.find('.md-prop-field-garages').html('-');
        elementBlock.find('.md-prop-field-garages-harcourts').html('');
        elementBlock.find('.md-prop-field-garages-harcourts-svg').html('');
      }

      if (typeof listing.openparkingspaces !== 'undefined' && listing.openparkingspaces !== null &&
      listing.openparkingspaces !== '' && listing.openparkingspaces !== 0) {
        elementBlock.find('.md-prop-field-open-parking').html(listing.openparkingspaces);
      } else {
        elementBlock.find('.md-prop-field-open-parking').html('');
      }

      elementBlock.find('.md-prop-field-price').html(listing.price);
      elementBlock.find('.md-prop-field-price-alt').html(listing.price);

      if (typeof listing.priceMain !== 'undefined' && listing.priceMain) {
        if (listing.listing_status === 'sold' && typeof listing.salepricecurrent !== 'undefined' && listing.salepricecurrent) {
          if (typeof listing.unsuppress_saleprice !== 'undefined' && listing.unsuppress_saleprice !== null && !listing.unsuppress_saleprice) {
            if (listing.showsaleprice === true) {
              elementBlock.find('.md-prop-field-pricemain').html(listing.salepricecurrent);
            } else {
              elementBlock.find('.md-prop-field-pricemain').html('');
            }
          } else {
            elementBlock.find('.md-prop-field-pricemain').html(listing.salepricecurrent);
          }
        } else {
          elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
        }
      } else {
        elementBlock.find('.md-prop-field-pricemain').html('');
      }

      if (typeof listing.displayprice !== 'undefined' && listing.displayprice) {
        elementBlock.find('.md-prop-field-displayprice').html(listing.displayprice);
      } else {
        elementBlock.find('.md-prop-field-displayprice').html('');
      }

      if (typeof listing.searchprice !== 'undefined' && listing.searchprice) {
        elementBlock.find('.md-prop-field-searchprice').html(listing.searchprice);
      } else {
        elementBlock.find('.md-prop-field-searchprice').html('');
      }

      if (typeof listing.salepricecurrent !== 'undefined' && listing.salepricecurrent) {
        if (typeof listing.unsuppress_saleprice !== 'undefined' && listing.unsuppress_saleprice !== null && !listing.unsuppress_saleprice) {
          if (listing.showsaleprice === true) {
            elementBlock.find('.md-prop-field-saleprice').html(listing.salepricecurrent);
            elementBlock.find('.md-prop-field-saleprice-title').html(`<b>Sales Price</b> <span>${listing.salepricecurrent}</span><br>`);
          } else {
            elementBlock.find('.md-prop-field-saleprice').html('');
            elementBlock.find('.md-prop-field-saleprice-title').html('');
          }
        } else {
          elementBlock.find('.md-prop-field-saleprice').html(listing.salepricecurrent);
          elementBlock.find('.md-prop-field-saleprice-title').html(`<b>Sales Price</b> <span>${listing.salepricecurrent}</span><br>`);
        }
      } else {
        elementBlock.find('.md-prop-field-saleprice').html('');
        elementBlock.find('.md-prop-field-saleprice-title').html('');
      }

      if (typeof listing.gstRate !== 'undefined' && listing.gstRate) {
        const gstrateString = `${listing.gstRate}%`;
        elementBlock.find('.md-prop-field-gstrate').html(gstrateString);
      } else {
        elementBlock.find('.md-prop-field-gstrate').html('');
      }

      if (typeof listing.saleprice !== 'undefined') {
        if (listing.saleprice === null) {
          elementBlock.find('.md-prop-field-price').html('Contact Agent');
          elementBlock.find('.md-prop-field-price-alt').html('Contact Agent');
        } else {
          elementBlock.find('.md-prop-field-price').html(listing.saleprice);
          elementBlock.find('.md-prop-field-price-alt').html(listing.saleprice);
        }
      }

      if (listing.listing_category === 'commercial') {
        elementBlock.find('.md-prop-field-cars').html('');
        elementBlock.find('.md-prop-field-cars-title').html('');

        if (listing.cars !== null && listing.cars !== 0) {
          elementBlock.find('.md-prop-field-cars').html(listing.cars);
          elementBlock.find('.md-prop-field-cars-title').html('<b>Parking</b>&nbsp;' + listing.cars);
          elementBlock.find('.md-prop-field-cars-title-br-br').remove();
          elementBlock.find('.md-prop-field-cars-title-br').html('<b>Parking</b>&nbsp;' + listing.cars + '<br class="md-prop-field-cars-title-br-br">');
        }
      }

      if (typeof listing.rpdp !== 'undefined' && listing.rpdp !== null && listing.rpdp !== '') {
        elementBlock.find('.md-prop-field-rpdp').html(listing.rpdp);
        elementBlock.find('.md-prop-field-rpdp-title').html('<b>RPD</b>&nbsp' + listing.rpdp);
        elementBlock.find('.md-prop-field-rpdp-title-br-br').remove();
        elementBlock.find('.md-prop-field-rpdp-title-br').html('<b>RPD</b>&nbsp' + listing.rpdp + '<br class="md-prop-field-rpdp-title-br-br">');
      } else {
        elementBlock.find('.md-prop-field-rpdp').html('');
        elementBlock.find('.md-prop-field-rpdp-title').html('');
        elementBlock.find('.md-prop-field-rpdp-title-br').html('');
      }

      if (this.authService.getApp() === 'Designly') {
        const dateToday = Date.parse(new Date().toString());

        let dateAuction = 0;

        if (typeof listing.auctiondateformat !== 'undefined' && listing.auctiondateformat !== null && listing.auctiondateformat !== '') {
          dateAuction = Date.parse(listing.auctiondateformat.toString());
        }

        if (typeof listing.auctiondate !== 'undefined' && listing.auctiondate !== null &&
        (typeof data.s2StatusValue !== 'undefined' && data.s2StatusValue && data.s2StatusValue) !== 'sold' && dateAuction >= dateToday) {

          if ( typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext ) {
            elementBlock.find('.md-prop-field-price').html(listing.pricetext);
          } else {
            elementBlock.find('.md-prop-field-price').html('');
          }
          elementBlock.find('.md-prop-field-price-alt').html('');
          elementBlock.find('.md-prop-field-pricemain').html('');
        } else {
          if (data.s2StatusValue === 'sold') {
            if (typeof listing.unsuppress_saleprice !== 'undefined' && listing.unsuppress_saleprice !== null && !listing.unsuppress_saleprice) {
              if (listing.showsaleprice === true) {
                if (typeof listing.salepricecurrent !== 'undefined' && listing.salepricecurrent) {
                  elementBlock.find('.md-prop-field-price').html(listing.salepricecurrent);
                  elementBlock.find('.md-prop-field-price-alt').html(listing.salepricecurrent);
                  elementBlock.find('.md-prop-field-pricemain').html(listing.salepricecurrent);
                }
              } else {
                elementBlock.find('.md-prop-field-price').html('');
                elementBlock.find('.md-prop-field-price-alt').html('');
                elementBlock.find('.md-prop-field-pricemain').html('');
              }
            } else {
              if (typeof listing.salepricecurrent !== 'undefined' && listing.salepricecurrent) {
                elementBlock.find('.md-prop-field-price').html(listing.salepricecurrent);
                elementBlock.find('.md-prop-field-price-alt').html(listing.salepricecurrent);
                elementBlock.find('.md-prop-field-pricemain').html(listing.salepricecurrent);
              }
            }
          } else {
            if (typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext) {
              elementBlock.find('.md-prop-field-price').html(listing.pricetext);
              elementBlock.find('.md-prop-field-price-alt').html(listing.pricetext);
              if (typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain) {
                elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
              } else {
                elementBlock.find('.md-prop-field-pricemain').html('');
              }
            } else {
              if (typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain) {
                elementBlock.find('.md-prop-field-price').html(listing.priceMain);
                elementBlock.find('.md-prop-field-price-alt').html(listing.priceMain);
                elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
              } else {
                elementBlock.find('.md-prop-field-price').html('');
                elementBlock.find('.md-prop-field-price-alt').html('');
                elementBlock.find('.md-prop-field-pricemain').html('');
              }
            }
          }
          elementBlock.find('.md-prop-field-auctiondate').html('');
          elementBlock.find('.md-prop-field-auctiondate-title').html('');
          elementBlock.find('.md-prop-field-auctiondate-title-br').html('');
          elementBlock.find('.md-prop-field-auctiondate-title-alt').html('');
        }

        if (listing.methodofsale === 'Set Sale Date' && data.s2StatusValue !== 'sold') {
          if (typeof listing.setSaleDate !== 'undefined' && listing.setSaleDate !== null) {
            let dateSetSaleDate = 0;

            dateSetSaleDate = Date.parse(listing.setSaleDateFormat.toString());
            if (dateSetSaleDate > dateToday) {
              elementBlock.find('.md-prop-field-price').html('');
              elementBlock.find('.md-prop-field-price-alt').html('');
              elementBlock.find('.md-prop-field-pricemain').html('');
            } else {
              if ( typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext ) {
                elementBlock.find('.md-prop-field-price').html(listing.pricetext);
                elementBlock.find('.md-prop-field-price-alt').html(listing.pricetext);
                if ( typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain ) {
                  elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
                } else {
                  elementBlock.find('.md-prop-field-pricemain').html('');
                }
              } else {
                if ( typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain ) {
                  elementBlock.find('.md-prop-field-price').html(listing.priceMain);
                  elementBlock.find('.md-prop-field-price-alt').html(listing.priceMain);
                  elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
                } else {
                  elementBlock.find('.md-prop-field-price').html('');
                  elementBlock.find('.md-prop-field-price-alt').html('');
                  elementBlock.find('.md-prop-field-pricemain').html('');
                }
              }
            }
          }
        }

        if (typeof listing.methodofsale !== 'undefined' && data.s2StatusValue !== 'sold') {
          if (typeof listing.tenderdateformat !== 'undefined' && listing.tenderdateformat !== null && listing.methodofsale === 'Tender' ) {
            elementBlock.find('.md-prop-field-status').html('For Sale by Tender');
            if (listing.portalstatus === 'conditional') {
              elementBlock.find('.md-prop-field-status').html('Under Offer');
            }

            let dateTenderDateFormat = 0;

            dateTenderDateFormat = Date.parse(listing.tenderdateformat.toString());

            if (dateTenderDateFormat <= dateToday) {
              elementBlock.find('.md-prop-field-price').html('');
              elementBlock.find('.md-prop-field-pricemain').html('');
            } else {

              if(listing.priceonapplication) {
                elementBlock.find('.md-prop-field-price').html('');
                elementBlock.find('.md-prop-field-pricemain').html('');
              } else {
                if ( typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext) {
                  elementBlock.find('.md-prop-field-price').html(listing.pricetext);
                  elementBlock.find('.md-prop-field-price-alt').html(listing.pricetext);
                  if ( typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain ) {
                    elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
                  } else {
                    elementBlock.find('.md-prop-field-pricemain').html('');
                  }
                } else {
                  elementBlock.find('.md-prop-field-price-alt').html('Sale by tender');

                  if ( typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain) {
                    elementBlock.find('.md-prop-field-price').html(listing.priceMain);
                    elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
                  } else {
                    elementBlock.find('.md-prop-field-price').html('');
                    elementBlock.find('.md-prop-field-pricemain').html('');
                  }
                }
              }
              
            }
          }
        }

        if ( auth.client.group_id === 12 ) {
          elementBlock.find('[href*="rh.com.au"]').each(function() {
              let propertyUrl = 'https://www.raineandhorne.com.au/';

            if (listing.saleLifeId !== null && listing.listing_type === 'sale') {
              propertyUrl = `${propertyUrl}L${listing.saleLifeId}`;
            }

            if (listing.leaseLifeId !== null && listing.listing_type === 'rent') {
              propertyUrl = `${propertyUrl}R${listing.leaseLifeId}`;
            }

            $(this).attr('href', propertyUrl);
          });
        }

        if (auth.client.group_id === 19) {
          elementBlock.find('[href*="rhcommercial.com.au"]').each(function () {
            let propertyUrl = 'https://rhcommercial.com.au/';

            if (listing.saleLifeId !== null && (listing.listing_type === 'sale' || listing.listing_category === 'commercial')) {
              propertyUrl = `${propertyUrl}L${listing.saleLifeId}`;
            }

            if (listing.leaseLifeId !== null && listing.listing_type === 'rent') {
              propertyUrl = `${propertyUrl}R${listing.leaseLifeId}`;
            }

            $(this).attr('href', propertyUrl);
          });
        }
      }

      if (this.authService.isUk()) {
        if (typeof listing.fulladdress !== 'undefined' && listing.fulladdress !== null && listing.fulladdress !== '') {
          elementBlock.find('.md-prop-field-fulladdress').html(listing.fulladdress);
        } else {
          elementBlock.find('.md-prop-field-fulladdress').html('');
        }

        if (typeof listing.fulladdress_br !== 'undefined' && listing.fulladdress_br !== null && listing.fulladdress_br !== '') {
          elementBlock.find('.md-prop-field-fulladdress-br').html(listing.fulladdress_br);
        } else {
          elementBlock.find('.md-prop-field-fulladdress-br').html('');
        }
      }

      let address_unitnum = '';
      let webbook_street = '';
      let webbook_suburb = '';
      if (listing.address_unitnum !== null) {
        address_unitnum = listing.address_unitnum + '/';
        elementBlock.find('.md-prop-field-unitnum').html(listing.address_unitnum);
        webbook_street = listing.address_unitnum;
      }

      /* New address format for Bayleys */
      let addressNZAlt = '';
      let addressBr = '';
      if (listing.address_unitnum !== null && listing.address_streetnum !== null) {
        addressNZAlt = `<b>${listing.address_suburb}</b> ${address_unitnum}${listing.address_streetnum} ${listing.address_street}`;
        if (listing.address_suburb !== null) {
          addressBr = `${address_unitnum}${listing.address_streetnum} ${listing.address_street}<br> ${listing.address_suburb}`;
        } else {
          addressBr = `${address_unitnum}${listing.address_streetnum} ${listing.address_street}`;
        }
      } else {
        addressNZAlt = `<b>${listing.address_suburb}</b> ${listing.address_street}`;
        addressBr = `${listing.address_street}<br> ${listing.address_suburb}`;
      }

      if (clientSetting.country === 'New Zealand') {
        const addressNz = address_unitnum + ' ' + listing.address_streetnum + ' ' + listing.address_street + ', ' + listing.address_suburb;
        
        if (listing.address_visibility === 'streetAndSuburb') {
          elementBlock.find('.md-prop-field-address').html(`${listing.address_street}, ${listing.address_suburb}`);
          /* New address format for Bayleys for street and suburb */
          elementBlock.find('.md-prop-field-address-alt').html(`<b>${listing.address_suburb}</b> ${listing.address_street}`);
          /* New address format with line break before suburb */
          elementBlock.find('.md-prop-field-address-br').html(addressBr);
        } else if (listing.address_visibility === 'suburb') {
          elementBlock.find('.md-prop-field-address').html(listing.address_suburb);
          /* New address format for Bayleys for suburb */
          elementBlock.find('.md-prop-field-address-alt').html(`<b>${listing.address_suburb}</b>`);
          /* No line break if suburb only */
          elementBlock.find('.md-prop-field-address-br').html(listing.address_suburb);
        } else {
          elementBlock.find('.md-prop-field-address').html(addressNz);
          /* New address format for Bayleys for full address */
          elementBlock.find('.md-prop-field-address-alt').html(addressNZAlt);
          /* New address format with line break before suburb */
          elementBlock.find('.md-prop-field-address-br').html(addressBr);
        }
      } else {
        if (listing.address_visibility === 'streetAndSuburb') {
          elementBlock.find('.md-prop-field-address').html(`${listing.address_street}, ${listing.address_suburb}`);
          /* New address format for Bayleys street and suburb */
          elementBlock.find('.md-prop-field-address-alt').html(`<b>${listing.address_suburb}</b> ${listing.address_street}`);
          /* New address format with line break before suburb */
          elementBlock.find('.md-prop-field-address-br').html(addressBr);
        } else if (listing.address_visibility === 'suburb') {
          elementBlock.find('.md-prop-field-address').html(listing.address_suburb);
          /* New address format for Bayleys for suburb */
          elementBlock.find('.md-prop-field-address-alt').html(`<b>${listing.address_suburb}</b>`);
          /* No line break if suburb only */
          elementBlock.find('.md-prop-field-address-br').html(listing.address_suburb);
        } else {
          if (listing.displayaddress) {
            elementBlock.find('.md-prop-field-address').html(listing.displayaddress);
            elementBlock.find('.md-prop-field-address-br').html(addressBr);
          } else if (listing.fulladdress) {
            elementBlock.find('.md-prop-field-address').html(listing.fulladdress);
            /* New address format for Bayleys for fulladdress */
            elementBlock.find('.md-prop-field-address-alt').html(addressNZAlt);
            /* New address format with line break before suburb */
            elementBlock.find('.md-prop-field-address-br').html(addressBr);
          } else {
            elementBlock.find('.md-prop-field-address').html(`${listing.address_streetnum} ${listing.address_street}, ${listing.address_suburb}`);
            /* New address format for Bayleys if no displayaddress and fulladdress */
            elementBlock.find('.md-prop-field-address-alt').html(addressNZAlt);
            /* New address format with line break before suburb */
            elementBlock.find('.md-prop-field-address-br').html(addressBr);
          }
        }
      }

      if (listing.address_streetnum !== null) {
        elementBlock.find('.md-prop-field-streetnum').html(address_unitnum + listing.address_streetnum);
        webbook_street = `${webbook_street} ${listing.address_streetnum}`;
      }
      if (listing.address_street !== null) {
        elementBlock.find('.md-prop-field-street').html(listing.address_street);
        webbook_street = `${webbook_street} ${listing.address_street}`;
      }
      if (listing.address_suburb !== null) {
        elementBlock.find('.md-prop-field-suburb').html(listing.address_suburb);
        webbook_suburb = listing.address_suburb;
      }
      if (listing.address_postcode !== null) {
        elementBlock.find('.md-prop-field-postcode').html(listing.address_postcode);
      } else {
        elementBlock.find('.md-prop-field-postcode').html('');
      }
      if (listing.address_state !== null) {
        elementBlock.find('.md-prop-field-state').html(listing.address_state);
      }

      if (listing.landarea !== null && listing.landarea !== 0 && listing.landarea !== '0' && listing.landarea !== '') {
        elementBlock.find('.md-prop-field-landarea').html(`<b>Land size</b> ${listing.landarea}`);
        elementBlock.find('.md-prop-field-landareatype').html(`<small>${listing.landareatype}</small>`);
        elementBlock.find('.md-prop-field-landarea-title').html(`<b>Land size</b> ${listing.landarea}<small>${listing.landareatype}</small>`);
        elementBlock.find('.md-prop-field-landarea-title-br-br').remove();
        elementBlock.find('.md-prop-field-landarea-title-br').html(`<b>Land size</b> ${listing.landarea}<small>${listing.landareatype}</small> <br class="md-prop-field-landarea-title-br-br">`);
        elementBlock.find('.md-prop-field-landareafull').html(`${listing.landarea}<small>${listing.landareatype}</small>`);
        elementBlock.find('.md-prop-field-landareafull-approx').html(`${listing.landarea}${listing.landareatype} Approx`);
        elementBlock.find('.md-prop-field-landareafull-approx-title').html(`<b>Approx Land Area</b> ${listing.landarea}${listing.landareatype}`);
        elementBlock.find('.md-prop-field-landareafull-approx-title-br-br').remove();
        elementBlock.find('.md-prop-field-landareafull-approx-title-br').html(`<b>Approx Land Area</b> ${listing.landarea}${listing.landareatype} <br class="md-prop-field-landareafull-approx-title-br-br">`);
        elementBlock.find('.md-prop-field-landareafull-title').html(`<b>Land size</b> ${listing.landarea}<small>${listing.landareatype}</small>`);
        elementBlock.find('.md-prop-field-landareafull-title-br-br').remove();
        elementBlock.find('.md-prop-field-landareafull-title-br').html(`<b>Land size</b> ${listing.landarea}<small>${listing.landareatype}</small> <br class="md-prop-field-landareafull-title-br-br">`);

        const landareaHarcourts = `<span class="md-prop-field-landarea" style="display: inline-block; vertical-align: middle">${listing.landarea}</span><span class="md-prop-field-landareatype" style="display: inline-block; vertical-align: middle"><small>${listing.landareatype}</small></span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_land.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" /><span>&nbsp;&nbsp;</span>`;
        elementBlock.find('.md-prop-field-landarea-harcourts').html(landareaHarcourts);

        const landareaHarcourtsSVG = `<p class="md-prop-field-landareafull-approx value">${listing.landarea}${listing.landareatype} Approx</p><img class="area icon" src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/property-guide/icon_area.svg" />`;
        elementBlock.find('.md-prop-field-landarea-harcourts-svg').html(landareaHarcourtsSVG);
      } else {
        elementBlock.find('.md-prop-field-landarea').html('');
        elementBlock.find('.md-prop-field-landareatype').html('');
        elementBlock.find('.md-prop-field-landarea-title').html('');
        elementBlock.find('.md-prop-field-landarea-title-br').html('');
        elementBlock.find('.md-prop-field-landareafull').html('');
        elementBlock.find('.md-prop-field-landareafull-approx').html('');
        elementBlock.find('.md-prop-field-landareafull-approx-title').html('');
        elementBlock.find('.md-prop-field-landareafull-approx-title-br').html('');
        elementBlock.find('.md-prop-field-landareafull-title').html('');
        elementBlock.find('.md-prop-field-landareafull-title-br').html('');
        elementBlock.find('.md-prop-field-landarea-harcourts').html('');
        elementBlock.find('.md-prop-field-landarea-harcourts-svg').html('');
      }

      if (typeof listing.buildingarea !== 'undefined' && listing.buildingarea !== '0' && listing.buildingarea !== null) {
        // tslint:disable-next-line: max-line-length
        elementBlock.find('.md-prop-field-buildingarea-title').html('<b>Building size</b>&nbsp' + listing.buildingarea + listing.buildingareatype);
        elementBlock.find('.md-prop-field-buildingarea-title-br-br').remove();
        elementBlock.find('.md-prop-field-buildingarea-title-br').html('<b>Building size</b>&nbsp' + listing.buildingarea + listing.buildingareatype + '<br class="md-prop-field-buildingarea-title-br-br">');
        elementBlock.find('.md-prop-field-buildingareafull').html(`${listing.buildingarea}<small>${listing.buildingareatype}</small>`);
        elementBlock.find('.md-prop-field-buildingareafull-title').html(`<b>Building size</b> ${listing.buildingarea}<small>${listing.buildingareatype}</small>`);
        elementBlock.find('.md-prop-field-buildingareafull-title-br-br').remove();
        elementBlock.find('.md-prop-field-buildingareafull-title-br').html(`<b>Building size</b> ${listing.buildingarea}<small>${listing.buildingareatype}</small> <br class="md-prop-field-buildingareafull-title-br-br">`);
        elementBlock.find('.md-prop-field-buildingareafull-approx').html(`${listing.buildingarea}${listing.buildingareatype} Approx`);
        elementBlock.find('.md-prop-field-buildingarea-approx-title').html(`<b>Approx Floor Area</b> ${listing.buildingarea}sqm`);
        elementBlock.find('.md-prop-field-buildingarea-approx-title-br-br').remove();
        elementBlock.find('.md-prop-field-buildingarea-approx-title-br').html(`<b>Approx Floor Area</b> ${listing.buildingarea}sqm <br class="md-prop-field-buildingarea-approx-title-br-br">`);

        let harcourtsBuildingArea = `<p class="md-prop-field-buildingareafull-approx value">${listing.buildingarea}${listing.buildingareatype} Approx</p><img class="buildingarea icon" src="https://resources.designly.com.au/resources/admin/4349/Images/social-medi/area.svg" />`;
        elementBlock.find('.md-prop-field-buildingareafull-harcourts-svg').html(harcourtsBuildingArea);
      } else {
        elementBlock.find('.md-prop-field-buildingarea-title').html('');
        elementBlock.find('.md-prop-field-buildingarea-title-br').html('');
        elementBlock.find('.md-prop-field-buildingareafull').html('');
        elementBlock.find('.md-prop-field-buildingareafull-approx').html('');
        elementBlock.find('.md-prop-field-buildingareafull-title').html('');
        elementBlock.find('.md-prop-field-buildingareafull-title-br').html('');
        elementBlock.find('.md-prop-field-buildingareafull-harcourts-svg').html('');
        elementBlock.find('.md-prop-field-buildingarea-approx-title').html('');
        elementBlock.find('.md-prop-field-buildingarea-approx-title-br').html('');
      }

      const listingDescription = String(listing.description).replace(/â€¢/g, '•');

      elementBlock.find('.md-prop-field-description').html(listingDescription);

      /* Get all element with class 'md-prop-field-description-' */
      const fieldDescription = elementBlock.find("[class*='md-prop-field-description-']");
      
      if (fieldDescription.length > 0) {
        fieldDescription.each(function() {

          /* Get attribute class value and split */
          const fieldDescriptionClass = $(this).attr('class');
          const fieldDescriptionClasses = fieldDescriptionClass.split(' ');

          if (fieldDescriptionClasses.length > 0) {
            fieldDescriptionClasses.forEach(element => {

              /* Check if class 'md-prop-field-description-' */
              if (String(element).includes('md-prop-field-description-')) {

                /* Get the character limit in class */
                const characterLimit = element.split('md-prop-field-description-')[1];

                if (listingDescription.length <= characterLimit) {
                  /* If less or equal the limit, add all */
                  elementBlock.find('.md-prop-field-description-'+characterLimit).html(listingDescription);
                } else {
                  /* If length greater than limit, trim text */
                  let trimmedString = listingDescription.substring(0, +characterLimit);
                  trimmedString = trimmedString + '...';

                  elementBlock.find('.md-prop-field-description-' + characterLimit).html(trimmedString);
                }
              }
            });
          }
        });
      }

      if (typeof listing.mainDescription !== 'undefined' && listing.mainDescription !== '' && listing.mainDescription !== null) {
        elementBlock.find('.md-prop-main-description').html(listing.mainDescription);
      } else {
        elementBlock.find('.md-prop-main-description').html('');
      }

      if (typeof listing.brochureDescription !== 'undefined' && listing.brochureDescription !== '' && listing.brochureDescription !== null) {
        elementBlock.find('.md-prop-brochure-description').html(listing.brochureDescription);
      } else {
        elementBlock.find('.md-prop-brochure-description').html('');
      }

      const listing_id = listing.id ? listing.id : ' ';
      elementBlock.find('.md-prop-field-id').html(listing_id);

      const listing_refid = listing.refid ? listing.refid : ' ';
      elementBlock.find('.md-prop-field-refid').html(listing_refid);

      const listing_propertytype = listing.propertytype ? listing.propertytype : ' ';
      elementBlock.find('.md-prop-field-proptype').html(listing_propertytype);

      if (listing.listing_category !== 'land') {
        if (typeof listing.buildingarea !== 'undefined' && listing.buildingarea !== '0' && listing.buildingarea !== null) {
          elementBlock.find('.md-prop-field-buildingarea').html(listing.buildingarea);

          let harcourtsBuildingArea = '<p class="md-prop-field-buildingarea-approx value">'+listing.buildingarea+' Approx</p><img class="area icon" src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/property-guide/icon_area.svg" />';
          elementBlock.find('.md-prop-field-buildingarea-harcourts-svg').html(harcourtsBuildingArea);
        } else {
          elementBlock.find('.md-prop-field-buildingarea').html('');
          elementBlock.find('.md-prop-field-buildingarea-harcourts-svg').html('');
        }
      } else {
        elementBlock.find('.md-prop-field-buildingarea').html('');
        elementBlock.find('.md-prop-field-buildingarea-harcourts-svg').html('');
      }

      const listing_landarea = (listing.landarea != null && listing.landarea !== 0 && listing.landarea !== '0') ? listing.landarea : '';
      elementBlock.find('.md-prop-field-landarea').html(listing_landarea);

      const landAreaElement = elementBlock.find('.md-prop-field-landarea').parent();
      if(listing_landarea == '' && typeof landAreaElement !== 'undefined' && landAreaElement.length !== 0) {
        landAreaElement.html(landAreaElement.html().replace("Land size", ""));
      }
      
      const listing_tenderdate = listing.tenderdate ? listing.tenderdate : ' ';
      elementBlock.find('.md-prop-field-tenderenddate').html(listing_tenderdate);

      let listing_agent1_name = ' ';
      let listing_agent1_imageurl = '';
      let listing_agent1_email = ' ';
      let listing_agent1_position = ' ';

      let listing_agent2_name = ' ';
      let listing_agent2_imageurl = '';
      let listing_agent2_email = ' ';
      let listing_agent2_position = ' ';

      if (listing.agent2 !== null) {
        listing_agent2_position = typeof listing.agent2.position !== 'undefined' && listing.agent2.position ? listing.agent2.position : ' ';
        elementBlock.find('.md-prop-field-lposition2').html(listing_agent2_position);
      } else {
        elementBlock.find('.md-prop-field-lposition2').html('');
        if (elementBlock.find('.element-listing-prop-two-agents')) {
          elementBlock.find('.md-prop-field-lprofile2').html('');
          elementBlock.find('.md-prop-field-profile2').html('');
          elementBlock.find('.md-prop-field-limage2').html('');
          elementBlock.find('.md-prop-field-limage2').css('display', 'none');
          elementBlock.find('.md-prop-field-lname2').html('');
          elementBlock.find('.md-prop-field-lmobile2').html('');
          elementBlock.find('.md-prop-field-lphone2').html('');
          elementBlock.find('.agent-listing-2').css('display', 'none');
        }
        if (elementBlock.find('.element-agent-single-prop-delete')) {
          elementBlock.find('.element-agent-single-prop-delete').remove();
        }
        if (elementBlock.find('.dsgnly-agent-grid-delete')) {
          elementBlock.find('.dsgnly-agent-grid-delete').remove();
        }
      }
      if (this.authService.getApp() === 'MyDesign3') {
        if (typeof listing.rates.water.amount !== 'undefined' && listing.rates.water.amount !== null) {
          elementBlock.find('.md-prop-field-waterrates').html(listing.rates.water.amount);
          elementBlock.find('.md-prop-field-waterrates-title').html('<b>Water Rates</b> ' + listing.rates.water.amount);
          elementBlock.find('.md-prop-field-waterrates-title-br-br').remove();
          elementBlock.find('.md-prop-field-waterrates-title-br').html('<b>Water Rates</b> ' + listing.rates.water.amount+ '<br class="md-prop-field-waterrates-title-br-br">');
        } else {
          elementBlock.find('.md-prop-field-waterrates').html('');
          elementBlock.find('.md-prop-field-waterrates-title').html('');
          elementBlock.find('.md-prop-field-waterrates-title-br').html('');
        }

        if (typeof listing.rates.strata.amount !== 'undefined' && listing.rates.strata.amount !== null) {
          elementBlock.find('.md-prop-field-stratarates').html(listing.rates.strata.amount);
          elementBlock.find('.md-prop-field-stratarates-title').html('<b>Strata Rates</b> ' + listing.rates.strata.amount);
          elementBlock.find('.md-prop-field-stratarates-title-br-br').remove();
          elementBlock.find('.md-prop-field-stratarates-title-br').html('<b>Strata Rates</b> ' + listing.rates.strata.amount + '<br class="md-prop-field-stratarates-title-br-br">');
        } else {
          elementBlock.find('.md-prop-field-stratarates').html('');
          elementBlock.find('.md-prop-field-stratarates-title').html('');
          elementBlock.find('.md-prop-field-stratarates-title-br').html('');
        }

        if (typeof listing.rates.council.amount !== 'undefined' && listing.rates.council.amount !== null) {
          elementBlock.find('.md-prop-field-councilrate').html(listing.rates.council.amount);
          if (this.authService.isUk()) {
            elementBlock.find('.md-prop-field-councilrate-title').html(`<b>Council Tax</b> ${listing.rates.council.amount}`);
            elementBlock.find('.md-prop-field-councilrate-title-br-br').remove();
            elementBlock.find('.md-prop-field-councilrate-title-br').html(`<b>Council Tax</b> ${listing.rates.council.amount} <br class="md-prop-field-councilrate-title-br-br">`);
          } else {
            elementBlock.find('.md-prop-field-councilrate-title').html(`<b>Council Rates</b> ${listing.rates.council.amount}`);
            elementBlock.find('.md-prop-field-councilrate-title-br-br').remove();
            elementBlock.find('.md-prop-field-councilrate-title-br').html(`<b>Council Rates</b> ${listing.rates.council.amount} <br class="md-prop-field-councilrate-title-br-br">`);
          }
        } else {
          elementBlock.find('.md-prop-field-councilrate').html('');
          elementBlock.find('.md-prop-field-councilrate-title').html('');
          elementBlock.find('.md-prop-field-councilrate-title-br').html('');
        }

        if (typeof listing.agent1 !== 'undefined' && listing.agent1) {
          // tslint:disable-next-line: max-line-length
          listing_agent1_position = typeof listing.agent1.position !== 'undefined' && listing.agent1.position ? listing.agent1.position : ' ';
          elementBlock.find('.md-prop-field-lposition').html(listing_agent1_position);

          if (listing.agent1.firstname && listing.agent1.lastname) {
            listing_agent1_name = listing.agent1.firstname + ' ' + listing.agent1.lastname;
          }
          elementBlock.find('.md-prop-field-lname').html(listing_agent1_name);

          let listing_agent1_mobiledisplay = listing.agent1.mobiledisplay ? listing.agent1.mobiledisplay : ' ';
          listing_agent1_mobiledisplay = listing_agent1_mobiledisplay.replace('+61', '').replace('+64', '').trim();

          if (listing_agent1_mobiledisplay) {
            listing_agent1_mobiledisplay = String(listing_agent1_mobiledisplay);
            if (!listing_agent1_mobiledisplay.startsWith('1800') && listing_agent1_mobiledisplay.charAt(0) !== '0') {
              listing_agent1_mobiledisplay = '0' + listing_agent1_mobiledisplay;
            }
          }
          elementBlock.find('.md-prop-field-lmobile').html(listing_agent1_mobiledisplay);

          listing_agent1_email = listing.agent1.email ? listing.agent1.email : ' ';
          elementBlock.find('.md-prop-field-lemail').html(listing_agent1_email);

          listing_agent1_imageurl = listing.agent1.imageurl ? listing.agent1.imageurl : '';
          elementBlock.find('.md-prop-field-limage').attr('src', listing_agent1_imageurl).css('display', 'inline');
          if (!listing_agent1_imageurl) {
            elementBlock.find('.md-prop-field-limage').css('display', 'none');
          }

          const listing_agent1_telephone = listing.agent1.telephone ? listing.agent1.telephone : ' ';
          elementBlock.find('.md-prop-field-lphone').html(listing_agent1_telephone);

          const listing_agent1_profile = listing.agent1.profile ? listing.agent1.profile : ' ';
          elementBlock.find('.md-prop-field-lprofile').html(listing_agent1_profile);
        }

        if (listing.agent2.firstname && listing.agent2.lastname) {
          listing_agent2_name = listing.agent2.firstname + ' ' + listing.agent2.lastname;
        }
        elementBlock.find('.md-prop-field-lname2').html(listing_agent2_name);

        /*
          Merge field: md-prop-field-lagentnames
          Use: Dynamic listing agent names for merge field md-prop-field-lagentnames
        */
        if (listing.agents !== null && typeof listing.agents !== 'undefined') {
          let agentNames = '';
          listing.agents.slice(0,4).forEach((agent, index) => {
            if (index === listing.agents.length - 1) {
              agentNames = agentNames + agent.firstname + ' ' + agent.lastname;
            } else {
              agentNames = agentNames + agent.firstname + ' ' + agent.lastname;
              if (index === listing.agents.length - 2) {
                agentNames = agentNames + ' and ';
              } else {
                agentNames = agentNames + ', ';
              }
            }
          });
    
          elementBlock.find('.md-prop-field-lagentnames').html(agentNames);
        } else {
          elementBlock.find('.md-prop-field-lagentnames').html('');
        }
        /* End of Merge field: md-prop-field-lagentnames */

        let listing_agent2_mobiledisplay = listing.agent2.mobiledisplay ? listing.agent2.mobiledisplay : ' ';
        listing_agent2_mobiledisplay = listing_agent2_mobiledisplay.replace('+61', '').replace('+64', '').trim();
        if (listing_agent2_mobiledisplay) {
          listing_agent2_mobiledisplay = String(listing_agent2_mobiledisplay);
          if (!listing_agent2_mobiledisplay.startsWith('1800') && listing_agent2_mobiledisplay.charAt(0) !== '0') {
            listing_agent2_mobiledisplay = '0' + listing_agent2_mobiledisplay;
          }
        }
        elementBlock.find('.md-prop-field-lmobile2').html(listing_agent2_mobiledisplay);

        listing_agent2_email = listing.agent2.email ? listing.agent2.email : ' ';
        elementBlock.find('.md-prop-field-lemail2').html(listing_agent2_email);

        listing_agent2_imageurl = listing.agent2.imageurl ? listing.agent2.imageurl : '';
        elementBlock.find('.md-prop-field-limage2').attr('src', listing_agent2_imageurl).css('display', 'inline');
        if (!listing_agent2_imageurl) {
          elementBlock.find('.md-prop-field-limage2').css('display', 'none');
        }

        const listing_agent2_telephone = listing.agent2.telephone ? listing.agent2.telephone : ' ';
        elementBlock.find('.md-prop-field-lphone2').html(listing_agent2_telephone);

        if (listing.listing_status === 'settled' || listing.listing_status === 'unconditional' || listing.listing_status === 'notcurrentlylisted') {
          elementBlock.find('.md-prop-field-status').html('Sold');
        }

        if (listing.listing_status === 'conditional') {
          elementBlock.find('.md-prop-field-status').html('For Sale');
        }

        if (listing.listing_status === 'listing' && listing.listing_type === 'sale') {
          const dateToday = Date.parse(new Date().toString());

          let dateAuction = 0;

          if (typeof listing.auctiondateformat !== 'undefined' && listing.auctiondateformat !== null && listing.auctiondateformat !== '') {
            dateAuction = Date.parse(listing.auctiondateformat.toString());
          }

          if (dateAuction >= dateToday) {
            elementBlock.find('.md-prop-field-status').html('For Auction');
          } else {
            elementBlock.find('.md-prop-field-status').html('For Sale');
          }
        }
      }

      if (this.authService.getApp() !== 'MyDesign3') {
        let listing_agent1_mobiledisplay;
        let listing_agent1_profile;
        let listing_agent1_telephone;
        if (listing.agent1 !== null) {
          if (listing.agent1.firstname && listing.agent1.lastname) {
            listing_agent1_name = listing.agent1.firstname + ' ' + listing.agent1.lastname;
          }
          elementBlock.find('.md-prop-field-lname').html(listing_agent1_name);

          listing_agent1_mobiledisplay = listing.agent1.mobiledisplay ? listing.agent1.mobiledisplay : (listing.agent1.mobile ? listing.agent1.mobile : ' ');
          listing_agent1_mobiledisplay = listing_agent1_mobiledisplay.replace('+61', '').replace('+64', '').trim();

          if (listing_agent1_mobiledisplay) {
            listing_agent1_mobiledisplay = String(listing_agent1_mobiledisplay);
            if (!listing_agent1_mobiledisplay.startsWith('1800') && listing_agent1_mobiledisplay.charAt(0) !== '0') {
              listing_agent1_mobiledisplay = '0' + listing_agent1_mobiledisplay;
            }
          }
          elementBlock.find('.md-prop-field-lmobile').html(listing_agent1_mobiledisplay);

          listing_agent1_email = listing.agent1.email ? listing.agent1.email : ' ';
          elementBlock.find('.md-prop-field-lemail').html(listing_agent1_email);

          listing_agent1_position = listing.agent1.position ? listing.agent1.position : ' ';
          elementBlock.find('.md-prop-field-lposition').html(listing_agent1_position);

          listing_agent1_imageurl = listing.agent1.imageurl ? listing.agent1.imageurl : '';
          elementBlock.find('.md-prop-field-limage').attr('src', listing_agent1_imageurl).css('display', 'inline');
          if (!listing_agent1_imageurl) {
            elementBlock.find('.md-prop-field-limage').css('display', 'none');
          }

          listing_agent1_profile = listing.agent1.profile ? listing.agent1.profile : ' ';
          listing_agent1_profile.replace(/<\/?p[^>]*>/g, ""); // remove p tag on profile that's causing issue
          elementBlock.find('.md-prop-field-lprofile').html(listing_agent1_profile);

          listing_agent1_telephone = listing.agent1.telephone ? listing.agent1.telephone : ' ';
          elementBlock.find('.md-prop-field-lphone').html(listing_agent1_telephone);
        }

        if (listing.agent2 !== null) {
          if (listing.agent2.firstname && listing.agent2.lastname) {
            listing_agent2_name = listing.agent2.firstname + ' ' + listing.agent2.lastname;
          }
          let listing_agent2_mobiledisplay = listing.agent2.mobiledisplay ? listing.agent2.mobiledisplay : ' ';
          listing_agent2_mobiledisplay = listing_agent2_mobiledisplay.replace('+61', '').replace('+64', '').trim();
          if (listing_agent2_mobiledisplay) {
            listing_agent2_mobiledisplay = String(listing_agent2_mobiledisplay);
            if (!listing_agent2_mobiledisplay.startsWith('1800') && listing_agent2_mobiledisplay.charAt(0) !== '0') {
              listing_agent2_mobiledisplay = '0' + listing_agent2_mobiledisplay;
            }
          }
          elementBlock.find('.md-prop-field-lmobile2').html(listing_agent2_mobiledisplay);

          listing_agent2_position = listing.agent2.position ? listing.agent2.position : ' ';
          elementBlock.find('.md-prop-field-lposition2').html(listing_agent2_position);

          listing_agent2_email = listing.agent2.email ? listing.agent2.email : ' ';
          elementBlock.find('.md-prop-field-lemail2').html(listing_agent2_email);

          listing_agent2_imageurl = listing.agent2.imageurl ? listing.agent2.imageurl : '';
          elementBlock.find('.md-prop-field-limage2').attr('src', listing_agent2_imageurl).css('display', 'inline');
          if (!listing_agent2_imageurl) {
            elementBlock.find('.md-prop-field-limage2').css('display', 'none');
          }

          listing_agent2_position = listing.agent2.position ? listing.agent2.position : ' ';
          elementBlock.find('.md-prop-field-lposition2').html(listing_agent2_position);

          let listing_agent2_profile = listing.agent2.profile ? listing.agent2.profile : ' ';
          listing_agent2_profile = listing_agent2_profile.replace(/<\/?p[^>]*>/g, ""); // remove p tag on profile that's causing issue
          elementBlock.find('.md-prop-field-lprofile2').html(listing_agent2_profile);


          const listing_agent2_telephone = listing.agent2.telephone ? listing.agent2.telephone : ' ';
          elementBlock.find('.md-prop-field-lphone2').html(listing_agent2_telephone);

          let agentGlobalElement = elementBlock.find('.dsgnly-agent-global');
          if(agentGlobalElement && agentGlobalElement.length > 0) {
            if (agentGlobalElement[0].getAttribute('data-agent-populate')
                && agentGlobalElement[0].getAttribute('data-agent-populate') === 'listingAgent') {

                  elementBlock.find('.dsgnly-agent-name2').html(listing_agent2_name);
                  elementBlock.find('.dsgnly-agent-profile2').html(listing_agent2_profile);
                  elementBlock.find('.dsgnly-agent-position2').html(listing_agent2_position);
                  elementBlock.find('.dsgnly-agent-mobile2').html(listing_agent2_mobiledisplay);
                  elementBlock.find('.dsgnly-agent-email2').html(listing_agent2_email);
                  if (!listing_agent2_imageurl) {
                    elementBlock.find('.dsgnly-agent-photo2').css('display', 'none');
                    elementBlock.find('.md-prop-field-limage2').css('display', 'none');
                  } else {
                    elementBlock.find('.dsgnly-agent-photo2').attr('src', listing_agent2_imageurl).css('display', 'inline');
                    elementBlock.find('.md-prop-field-limage2').attr('src', listing_agent2_imageurl).css('display', 'inline');
                  }
              }
          }
        } else {
          elementBlock.find('.md-prop-field-lmobile2').html('');
            elementBlock.find('.md-prop-field-lphone2').html('');
            elementBlock.find('.md-prop-field-limage2').html('');
            elementBlock.find('.md-prop-field-limage2').css('display', 'none');
            elementBlock.find('.md-prop-field-lname2').html('');
            elementBlock.find('.md-prop-field-lemail2').html('');
            elementBlock.find('.md-prop-field-lposition2').html('');
        }
          

        elementBlock.find('.md-prop-field-lname2').html(listing_agent2_name);

        /*
          Merge field: md-prop-field-lagentnames
          Use: Dynamic listing agent names for merge field md-prop-field-lagentnames
        */
        if (listing.agents !== null && typeof listing.agents !== 'undefined') {
          let agentNames = '';
          listing.agents.slice(0,4).forEach((agent, index) => {
            if (index === listing.agents.length - 1) {
              agentNames = agentNames + agent.firstname + ' ' + agent.lastname;
            } else {
              agentNames = agentNames + agent.firstname + ' ' + agent.lastname;
              if (index === listing.agents.length - 2) {
                agentNames = agentNames + ' and ';
              } else {
                agentNames = agentNames + ', ';
              }
            }
          });
    
          elementBlock.find('.md-prop-field-lagentnames').html(agentNames);
        } else {
          elementBlock.find('.md-prop-field-lagentnames').html('');
        }
        /* End of Merge field: md-prop-field-lagentnames */
        let agentGlobalElement = elementBlock.find('.dsgnly-agent-global');
        if(agentGlobalElement && agentGlobalElement.length > 0) {
          if (agentGlobalElement[0].getAttribute('data-agent-populate')
              && agentGlobalElement[0].getAttribute('data-agent-populate') === 'listingAgent') {
                listing_agent1_profile = listing_agent1_profile.replace(/<\/?p[^>]*>/g, ""); // remove p tag on profile that's causing issue   
                elementBlock.find('.dsgnly-agent-name').html(listing_agent1_name);
                elementBlock.find('.dsgnly-agent-profile').html(listing_agent1_profile);
                elementBlock.find('.dsgnly-agent-position').html(listing_agent1_position);
                elementBlock.find('.dsgnly-agent-mobile').html(listing_agent1_mobiledisplay);
                elementBlock.find('.dsgnly-agent-email').html(listing_agent1_email);

                if (!listing_agent1_imageurl) {
                  elementBlock.find('.dsgnly-agent-photo').css('display', 'none');
                  elementBlock.find('.md-prop-field-limage').css('display', 'none');
                } else {
                  elementBlock.find('.dsgnly-agent-photo').attr('src', listing_agent1_imageurl).css('display', 'inline');
                  elementBlock.find('.md-prop-field-limage').attr('src', listing_agent1_imageurl).css('display', 'inline');
                }
          }
        }
      }

      if (this.authService.getApp() === 'Designly' && auth.provider === 'vaultre') {
        if (typeof listing.rates.water.amount !== 'undefined' && listing.rates.water.amount !== null) {
          elementBlock.find('.md-prop-field-waterrates').html(`${currency}${listing.rates.water.amount.toLocaleString()} ${listing.rates.water.period}`);
          elementBlock.find('.md-prop-field-waterrates-title').html(`<b>Water Rates</b> ${currency}${listing.rates.water.amount.toLocaleString()} ${listing.rates.water.period}`);
          elementBlock.find('.md-prop-field-waterrates-title-br-br').remove();
          elementBlock.find('.md-prop-field-waterrates-title-br').html(`<b>Water Rates</b> ${currency}${listing.rates.water.amount.toLocaleString()} ${listing.rates.water.period} <br class="md-prop-field-waterrates-title-br-br">`);
        } else {
          elementBlock.find('.md-prop-field-waterrates').html('');
          elementBlock.find('.md-prop-field-waterrates-title').html('');
          elementBlock.find('.md-prop-field-waterrates-title-br').html('');
        }

        if (typeof listing.rates.strata.amount !== 'undefined' && listing.rates.strata.amount !== null) {
          elementBlock.find('.md-prop-field-stratarates').html(`${currency}${listing.rates.strata.amount.toLocaleString()} ${listing.rates.strata.period}`);
          elementBlock.find('.md-prop-field-stratarates-title').html(`<b>Strata Rates</b> ${currency}${listing.rates.strata.amount.toLocaleString()} ${listing.rates.strata.period}`);
          elementBlock.find('.md-prop-field-stratarates-title-br-br').remove();
          elementBlock.find('.md-prop-field-stratarates-title-br').html(`<b>Strata Rates</b> ${currency}${listing.rates.strata.amount.toLocaleString()} ${listing.rates.strata.period} <br class="md-prop-field-stratarates-title-br-br">`);
        } else {
          elementBlock.find('.md-prop-field-stratarates').html('');
          elementBlock.find('.md-prop-field-stratarates-title').html('');
          elementBlock.find('.md-prop-field-stratarates-title-br').html('');
        }

        if (typeof listing.rates.council.amount !== 'undefined' && listing.rates.council.amount !== null) {
          elementBlock.find('.md-prop-field-councilrate').html(`${currency}${listing.rates.council.amount.toLocaleString()} ${listing.rates.council.period}`);
          if (this.authService.isUk()) {
            elementBlock.find('.md-prop-field-councilrate-title').html(`<b>Council Tax</b> ${currency}${listing.rates.council.amount.toLocaleString()} ${listing.rates.council.period}`);
            elementBlock.find('.md-prop-field-councilrate-title-br-br').remove();
            elementBlock.find('.md-prop-field-councilrate-title-br').html(`<b>Council Tax</b> ${currency}${listing.rates.council.amount.toLocaleString()} ${listing.rates.council.period} <br class="md-prop-field-councilrate-title-br-br">`);
          } else {
            elementBlock.find('.md-prop-field-councilrate-title').html(`<b>Council Rates</b> ${currency}${listing.rates.council.amount.toLocaleString()} ${listing.rates.council.period}`);
            elementBlock.find('.md-prop-field-councilrate-title-br-br').remove();
            elementBlock.find('.md-prop-field-councilrate-title-br').html(`<b>Council Rates</b> ${currency}${listing.rates.council.amount.toLocaleString()} ${listing.rates.council.period} <br class="md-prop-field-councilrate-title-br-br">`);
          }
        } else {
          elementBlock.find('.md-prop-field-councilrate').html('');
          elementBlock.find('.md-prop-field-councilrate-title').html('');
          elementBlock.find('.md-prop-field-councilrate-title-br').html('');
        }

        if (typeof listing.rates.councilstrata !== 'undefined') {
          if (typeof listing.rates.councilstrata.amount !== 'undefined' && listing.rates.councilstrata.amount !== null) {
            elementBlock.find('.md-prop-field-councilstratarate').html(`${currency}${listing.rates.councilstrata.amount.toLocaleString()} ${listing.rates.councilstrata.period}`);
            elementBlock.find('.md-prop-field-councilstratarate-title').html(`<b>Council Rates</b> ${currency}${listing.rates.councilstrata.amount.toLocaleString()} ${listing.rates.councilstrata.period}`);
            elementBlock.find('.md-prop-field-councilstratarate-title-br-br').remove();
            elementBlock.find('.md-prop-field-councilstratarate-title-br').html(`<b>Council Rates</b> ${currency}${listing.rates.councilstrata.amount.toLocaleString()} ${listing.rates.councilstrata.period} <br class="md-prop-field-councilstratarate-title-br-br">`);
          } else {
            elementBlock.find('.md-prop-field-councilstratarate').html('');
            elementBlock.find('.md-prop-field-councilstratarate-title').html('');
            elementBlock.find('.md-prop-field-councilstratarate-title-br').html('');
          }
        }

        if (typeof listing.counciltaxband !== 'undefined' && listing.counciltaxband !== null) {
          elementBlock.find('.md-prop-field-counciltaxband').html(listing.counciltaxband);
          elementBlock.find('.md-prop-field-counciltaxband-title').html('<b>Council Tax Band</b> ' + listing.counciltaxband);
        } else {
          elementBlock.find('.md-prop-field-counciltaxband').html('');
          elementBlock.find('.md-prop-field-counciltaxband-title').html('');
        }

        elementBlock.find('.md-prop-field-mezzaninearea').html('');
        elementBlock.find('.md-prop-field-mezzaninearea-title').html('');
          elementBlock.find('.md-prop-field-mezzaninearea-title-br').html('');
          elementBlock.find('.md-prop-field-mezzanineareatype').html('');

        if (listing.mezzaninearea !== null && listing.mezzaninearea !== 0) {
          elementBlock.find('.md-prop-field-mezzaninearea').html(listing.mezzaninearea);
          // tslint:disable-next-line: max-line-length
          elementBlock.find('.md-prop-field-mezzaninearea-title').html(`<b>Mezzanine</b> ${listing.mezzaninearea}<small>${listing.mezzanineareatype}</small>`);
          elementBlock.find('.md-prop-field-mezzaninearea-title-br-br').remove();
          elementBlock.find('.md-prop-field-mezzaninearea-title-br').html(`<b>Mezzanine</b> ${listing.mezzaninearea}<small>${listing.mezzanineareatype}</small> <br class="md-prop-field-mezzaninearea-title-br-br">`);
        }
        if (listing.mezzanineareatype !== null && listing.mezzaninearea !== 0) {
          elementBlock.find('.md-prop-field-mezzanineareatype').html(`<small>${listing.mezzanineareatype}</small>`);
        }

        if (listing.warehousearea !== null && listing.warehousearea !== 0) {
          elementBlock.find('.md-prop-field-warehousearea').html(listing.warehousearea);
          // tslint:disable-next-line: max-line-length
          elementBlock.find('.md-prop-field-warehousearea-title').html(`<b>Warehouse Area</b> <span>${listing.warehousearea} <small>${listing.warehouseareatype}</small></span>`);
          elementBlock.find('.md-prop-field-warehousearea-title-br-br').remove();
          elementBlock.find('.md-prop-field-warehousearea-title-br').html(`<b>Warehouse Area</b> <span>${listing.warehousearea} <small>${listing.warehouseareatype}</small></span><br class="md-prop-field-warehousearea-title-br-br">`);
        } else {
          elementBlock.find('.md-prop-field-warehousearea').html('');
          elementBlock.find('.md-prop-field-warehousearea-title').html('');
          elementBlock.find('.md-prop-field-warehousearea-title-br').html('');
        }

        if (listing.warehouseareatype !== null && listing.warehousearea !== 0) {
          elementBlock.find('.md-prop-field-warehouseareatype').html(`<small>${listing.warehouseareatype}</small>`);
        } else {
          elementBlock.find('.md-prop-field-warehouseareatype').html('');
        }

        if (listing.officearea !== null && listing.officearea !== 0) {
          elementBlock.find('.md-prop-field-officearea').html(listing.officearea);
          elementBlock.find('.md-prop-field-officearea-title').html(`<b>Office Area</b> <span>${listing.officearea} <small>${listing.officeareatype}</small></span>`);
          elementBlock.find('.md-prop-field-officearea-title-br-br').remove();
          elementBlock.find('.md-prop-field-officearea-title-br').html(`<b>Office Area</b> <span>${listing.officearea} <small>${listing.officeareatype}</small></span><br class="md-prop-field-officearea-title-br-br">`);
        } else {
          elementBlock.find('.md-prop-field-officearea').html('');
          elementBlock.find('.md-prop-field-officearea-title').html('');
          elementBlock.find('.md-prop-field-officearea-title-br').html('');
        }

        if (listing.officeareatype !== null && listing.officearea !== 0) {
          elementBlock.find('.md-prop-field-officeareatype').html(`<small>${listing.officeareatype}</small>`);
        } else {
          elementBlock.find('.md-prop-field-officeareatype').html('');
        }

        if (listing.retailarea !== null && listing.retailarea !== 0 && listing.retailarea) {
          elementBlock.find('.md-prop-field-retailarea').html(listing.retailarea);
          elementBlock.find('.md-prop-field-retailarea-title').html(`<b>Retail Area</b> <span>${listing.retailarea} <small>${listing.retailareatype}</small></span>`);
          elementBlock.find('.md-prop-field-retailarea-title-br-br').remove();
          elementBlock.find('.md-prop-field-retailarea-title-br').html(`<b>Retail Area</b> <span>${listing.retailarea} <small>${listing.retailareatype}</small></span> <br class="md-prop-field-retailarea-title-br-br">`);
        } else {
          elementBlock.find('.md-prop-field-retailarea').html('');
          elementBlock.find('.md-prop-field-retailarea-title').html('');
          elementBlock.find('.md-prop-field-retailarea-title-br').html('');
        }

        if (listing.retailareatype !== null && listing.retailareatype !== 0 &&
          listing.retailareatype && listing.retailarea !== null && listing.retailarea !== 0) {
          elementBlock.find('.md-prop-field-retailareatype').html(`<small>${listing.retailareatype}</small>`);
        } else {
          elementBlock.find('.md-prop-field-retailareatype').html('');
        }
      }

      if (typeof listing.counciltaxband !== 'undefined' && listing.counciltaxband !== null) {
        elementBlock.find('.md-prop-field-counciltaxband').html(listing.counciltaxband);
      } else {
        elementBlock.find('.md-prop-field-counciltaxband').html('');
      }

      if (typeof listing.buildingareatype !== 'undefined' && listing.listing_category !== 'land' &&
      listing.buildingarea !== '0' && listing.buildingarea !== null) {
        elementBlock.find('.md-prop-field-buildingareatype').html(`<small>${listing.buildingareatype}</small>`);
      } else {
        elementBlock.find('.md-prop-field-buildingareatype').html('');
      }

      if (typeof listing.bondprice !== 'undefined' && listing.bondprice !== null && listing.bondprice !== '' && listing.bondprice !== 0) {
        elementBlock.find('.md-prop-field-bond').html(listing.bondprice);
        elementBlock.find('.md-prop-field-bond-title').html(`<b>Deposit</b> ${listing.bondprice}`);
      } else {
        elementBlock.find('.md-prop-field-bond').html('');
        elementBlock.find('.md-prop-field-bond-title').html('');
      }

      if (auth.provider === 'mydesktop') {
        elementBlock.find('.md-prop-field-buildingareatype').html('<small>sqm</small>');

        if (listing.sales) {
          if (typeof listing.sales.saledate !== 'undefined' && listing.sales.saledate) {
            elementBlock.find('.md-prop-field-saledate').html(moment(listing.sales.saledate).format('Do, MMM YYYY'));
            elementBlock.find('.md-prop-field-saledate-title').html(`<b>Sales Date</b> <span>${moment(listing.saledate).format('Do, MMM YYYY')}</span><br>`);
          } else {
            elementBlock.find('.md-prop-field-saledate').html('');
            elementBlock.find('.md-prop-field-saledate-title').html('');
          }

          if (typeof listing.sales.saleprice !== 'undefined' && listing.sales.saleprice) {
            elementBlock.find('.md-prop-field-price').html(listing.sales.saleprice);
          }
        }
      }

      if (typeof listing.priceonapplication !== 'undefined' && listing.priceonapplication) {
        if (listing.listing_status !== 'settled' && listing.listing_status !== 'unconditional'
         && listing.listing_status !== 'settled,unconditional') {
          if (typeof listing.priceonapplication_auction !== 'undefined' && listing.priceonapplication_auction) {
            if(listing.methodofsale === 'Auction') {
              // elementBlock.find('.md-prop-field-price').html('');
              // elementBlock.find('.md-prop-field-price-alt').html('');
            } else {
              if (typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext) {
                elementBlock.find('.md-prop-field-price').html(listing.pricetext);
                elementBlock.find('.md-prop-field-price-alt').html(listing.pricetext);
              } else if (typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain) { 
                elementBlock.find('.md-prop-field-price').html(listing.priceMain);
                elementBlock.find('.md-prop-field-price-alt').html(listing.priceMain);
              } else {
                elementBlock.find('.md-prop-field-price').html(listing.price);
                elementBlock.find('.md-prop-field-price-alt').html(listing.price);
              }
            }
          } else {
            if (typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext) {
              elementBlock.find('.md-prop-field-price').html(listing.pricetext);
              elementBlock.find('.md-prop-field-price-alt').html(listing.pricetext);
            } else {
              if(listing.methodofsale !== 'Tender') {
                elementBlock.find('.md-prop-field-price').html('Price on Application');
                elementBlock.find('.md-prop-field-price-alt').html('Price on Application');
              }
            }
          }
        }
      }

      /* Settled/Unconditional properties conditions */
      let isSettledUnconditionalProperty = false;
      if (typeof listing.listing_status !== 'undefined' && listing.listing_status !== null && listing.listing_status) {
        const listingStatus = listing.listing_status.split(',').map((v:string) => { return v.trim()});
        if (listingStatus.includes('settled') || listingStatus.includes('unconditional')) {
          isSettledUnconditionalProperty = true;
        }
      }

      const isNZClientProperty = ((listing.address_country === "New Zealand" || auth.client.country === "New Zealand"));

      if (auth.provider === 'vaultre') {
        if (typeof listing.methodofsale !== 'undefined' && listing.methodofsale) {
          if (listing.methodofsale === 'Private Treaty') {
            elementBlock.find('.md-prop-field-methodofsale').html('For Sale');
          } else {
            /* Replace Set Sale Date to Deadline Sale for NZ Property */
            const listingMethodOfSale = isNZClientProperty && listing.methodofsale == 'Set Sale Date' ? "Deadline Sale" : listing.methodofsale;
            elementBlock.find('.md-prop-field-methodofsale').html(listingMethodOfSale);

            if (listing.methodofsale === 'Auction') {
              elementBlock.find('.md-prop-field-methodofsale-alt').html(listing.methodofsale + ' <small>(unless sold prior)</small>');
            } else {
              elementBlock.find('.md-prop-field-methodofsale-alt').html(listing.methodofsale);
            }
          }
        } else {
          if (listing.listing_status === 'management') {
            elementBlock.find('.md-prop-field-methodofsale').html('For Rent');
          } else {
            elementBlock.find('.md-prop-field-methodofsale').html('');
          }
        }

        if (isSettledUnconditionalProperty) {
          elementBlock.find('.md-prop-field-methodofsale').html('Sold');
        }

        if (typeof listing.tenderdetails !== 'undefined' && listing.tenderdetails && listing.tenderdetails !== null) {
          elementBlock.find('.md-prop-field-tenderdate').html(listing.tenderdetails);
        } else {
          elementBlock.find('.md-prop-field-tenderdate').html('');
        }

        if (typeof listing.yearbuilt !== 'undefined' && listing.yearbuilt) {
          elementBlock.find('.md-prop-field-yearbuilt').html(listing.yearbuilt);
          elementBlock.find('.md-prop-field-yearbuilt-title').html('<b>Approx Year Built</b> ' + listing.yearbuilt);
          elementBlock.find('.md-prop-field-yearbuilt-title-br-br').remove();
          elementBlock.find('.md-prop-field-yearbuilt-title-br').html('<b>Approx Year Built</b> ' + listing.yearbuilt + '<br class="md-prop-field-yearbuilt-title-br-br">');
        } else {
          elementBlock.find('.md-prop-field-yearbuilt').html('');
          elementBlock.find('.md-prop-field-yearbuilt-title').html('');
          elementBlock.find('.md-prop-field-yearbuilt-title-br').html('');
        }

        if (typeof listing.yearbuiltage !== 'undefined' && listing.yearbuiltage) {
          elementBlock.find('.md-prop-field-ageofproperty').html(listing.yearbuiltage);
          elementBlock.find('.md-prop-field-ageofproperty-title').html(`<b>Age</b> ${listing.yearbuiltage}`);
          elementBlock.find('.md-prop-field-ageofproperty-title-br-br').remove();
          elementBlock.find('.md-prop-field-ageofproperty-title-br').html(`<b>Age</b> ${listing.yearbuiltage}<br class="md-prop-field-ageofproperty-title-br-br">`);
        } else {
          elementBlock.find('.md-prop-field-ageofproperty').html('');
          elementBlock.find('.md-prop-field-ageofproperty-title').html('');
          elementBlock.find('.md-prop-field-ageofproperty-title-br').html('');
        }

        if (typeof listing.rateablevalue !== 'undefined' && listing.rateablevalue && listing.rateablevalue !== '$0') {
          elementBlock.find('.md-prop-field-rateablevalue').html(listing.rateablevalue);
          /* New Title Revision of md-prop-field-landvalue */
          elementBlock.find('.md-prop-field-rateablevalue-title').html(`<b>RV</b> <span>${listing.rateablevalue}</span>`);
          
        } else {
          elementBlock.find('.md-prop-field-rateablevalue').html('');
          /* New Title Revision of md-prop-field-landvalue */
          elementBlock.find('.md-prop-field-rateablevalue-title').html('');
        }

        if (typeof listing.landvalue !== 'undefined' && listing.landvalue !== '0' && listing.landvalue) {
          elementBlock.find('.md-prop-field-landvalue').html(currency + listing.landvalue);
          /* New Title Revision of md-prop-field-landvalue */
          elementBlock.find('.md-prop-field-landvalue-title').html( `<b>LV</b> <span>${currency}${listing.landvalue}</span>`);
        } else {
          elementBlock.find('.md-prop-field-landvalue').html('');
          /* New Title Revision of md-prop-field-landvalue */
          elementBlock.find('.md-prop-field-landvalue-title').html('');
        }

        if (typeof listing.improvementvalue !== 'undefined' && listing.improvementvalue && listing.improvementvalue !== '0') {
          elementBlock.find('.md-prop-field-improvementvalue').html(`${currency}${listing.improvementvalue}`);
          /* New Title Revision of md-prop-field-improvementvalue */
          elementBlock.find('.md-prop-field-improvementvalue-title').html(`<b>IV</b> <span>${currency}${listing.improvementvalue}</span>`);
          
        } else {
          elementBlock.find('.md-prop-field-improvementvalue').html('');
          /* New Title Revision of md-prop-field-improvementvalue */
          elementBlock.find('.md-prop-field-improvementvalue-title').html('');
        }

        if (typeof listing.lotnumber !== 'undefined' && listing.lotnumber) {
          elementBlock.find('.md-prop-field-lotnumber').html(listing.lotnumber);
        } else {
          elementBlock.find('.md-prop-field-lotnumber').html('');
        }

        if (typeof listing.openspaces !== 'undefined' && listing.openspaces) {
          elementBlock.find('.md-prop-field-openspaces').html(listing.openspaces);
        } else {
          elementBlock.find('.md-prop-field-openspaces').html('');
        }

        if (typeof listing.legaldescription !== 'undefined' && listing.legaldescription) {
          elementBlock.find('.md-prop-field-legaldescription').html(listing.legaldescription);
          /* New Title Revision of md-prop-field-legaldescription */
          elementBlock.find('.md-prop-field-legaldescription-title').html(`<b>Legal Details</b> <span>${listing.legaldescription}</span>`);
          
        }

        if (typeof listing.unconditional_date !== 'undefined' && listing.unconditional_date) {
          elementBlock.find('.md-prop-field-unconditionaldate').html(moment(listing.unconditional_date).format('Do, MMM YYYY'));
        } else {
          elementBlock.find('.md-prop-field-unconditionaldate').html('');
        }

        if (listing.saledate) {
          if (typeof listing.saledate !== 'undefined' && listing.saledate) {
            elementBlock.find('.md-prop-field-saledate').html(moment(listing.saledate).format('Do, MMM YYYY'));
            elementBlock.find('.md-prop-field-saledate-title').html(`<b>Sales Date</b> <span>${moment(listing.saledate).format('Do, MMM YYYY')}</span><br>`);
          } else {
            elementBlock.find('.md-prop-field-saledate').html('');
            elementBlock.find('.md-prop-field-saledate-title').html('');
          }

          let sale_price = listing.pricefrom;
          if (typeof listing.saleprice !== 'undefined' && listing.saleprice) {
            sale_price = listing.saleprice;
          }
          const ratePerM2 = parseInt(sale_price, 0) / parseInt(listing.buildingarea, 0);

          elementBlock.find('.md-prop-field-rate').html(ratePerM2);
        } else {
          elementBlock.find('.md-prop-field-saledate').html('');
          elementBlock.find('.md-prop-field-saledate-title').html('');
          elementBlock.find('.md-prop-field-rate').html('');
        }

        if (typeof listing.agentpriceopinion !== 'undefined' && listing.agentpriceopinion !== null) {
          elementBlock.find('.md-prop-field-agentpriceopinion').html(listing.agentpriceopinion);
        } else {
          elementBlock.find('.md-prop-field-agentpriceopinion').html('');
        }
      }

      if (auth.provider === 'vaultre') {
        if (typeof listing.pricequalifier !== 'undefined' && listing.pricequalifier) {
          if (listing.pricequalifier === 'Negotiation') {
            elementBlock.find('.md-prop-field-pricequalifier').html('Price by negotiation');
            elementBlock.find('.md-prop-field-searchprice').html('');
            elementBlock.find('.md-prop-field-price-alt').html('<b>Price by negotiation</b>');
          } else if (listing.pricequalifier === 'Buyer Enquiry Over') {
            elementBlock.find('.md-prop-field-pricequalifier').html('BEO');
          } else {
            elementBlock.find('.md-prop-field-pricequalifier').html(listing.pricequalifier);
          }
        } else {
          elementBlock.find('.md-prop-field-pricequalifier').html('');
        }
      }

      if (typeof listing.dateavailable !== 'undefined' && listing.dateavailable !== null && listing.dateavailable) {
        elementBlock.find('.md-prop-field-dateavailable').html(listing.dateavailable);
        elementBlock.find('.md-prop-field-dateavailable-title').html('<b>Date Available</b> ' + listing.dateavailable);
        elementBlock.find('.md-prop-field-dateavailable-title-br-br').remove();
        elementBlock.find('.md-prop-field-dateavailable-title-br').html('<b>Date Available</b> ' + listing.dateavailable + '<br class="md-prop-field-dateavailable-title-br-br">');
      } else {
        elementBlock.find('.md-prop-field-dateavailable').html('');
        elementBlock.find('.md-prop-field-dateavailable-title').html('');
        elementBlock.find('.md-prop-field-dateavailable-title-br').html('');
      }
      
      // const listing_legal_description = ( listing.legal_description ) ? listing.legal_description : ' ';
      // elementBlock.find('.md-prop-field-legaldescription').html(listing_legal_description);

      // const listing_legal_landvalue = ( listing.legal_landvalue ) ? listing.legal_landvalue : ' ';
      // elementBlock.find('.md-prop-field-landvalue').html(listing_legal_landvalue);

      const listing_legal_localauthority = listing.legal_localauthority ? listing.legal_localauthority : ' ';
      elementBlock.find('.md-prop-field-localauthority').html(listing_legal_localauthority);

      const listing_legal_lot = listing.legal_lot ? listing.legal_lot : ' ';
      elementBlock.find('.md-prop-field-lot').html(listing_legal_lot);

      const listing_legal_rpdp = listing.legal_rpdp ? listing.legal_rpdp : ' ';
      elementBlock.find('.md-prop-field-legal-rpdp').html(listing_legal_rpdp);

      const listing_legal_gvyear = listing.legal_gvyear ? listing.legal_gvyear : ' ';
      elementBlock.find('.md-prop-field-gvyear').html(listing_legal_gvyear);

      const listing_legal_governmentvalue = listing.legal_governmentvalue ? listing.legal_governmentvalue : ' ';
      elementBlock.find('.md-prop-field-govvalue').html(listing_legal_governmentvalue);

      if (typeof listing.legal_zoning !== 'undefined' && listing.legal_zoning && listing.legal_zoning !== null) {
        elementBlock.find('.md-prop-com-zoning').html(listing.legal_zoning);
        elementBlock.find('.md-prop-com-zoning-title').html('<b>Zoning</b>&nbsp' + listing.legal_zoning);
        elementBlock.find('.md-prop-com-zoning-title-br-br').remove();
        elementBlock.find('.md-prop-com-zoning-title-br').html('<b>Zoning</b>&nbsp' + listing.legal_zoning + '<br class="md-prop-com-zoning-title-br-br">');
        
        elementBlock.find('.md-prop-field-zoning').html(listing.legal_zoning);
      } else {
        elementBlock.find('.md-prop-com-zoning').html('');
        elementBlock.find('.md-prop-com-zoning-title').html('');
        elementBlock.find('.md-prop-com-zoning-title-br').html('');

        elementBlock.find('.md-prop-field-zoning').html('');
      }

      if (listing.listing_status == 'leased' && listing.listing_category == 'commercial') {
        elementBlock.find('.md-prop-com-leasesale').html('Leased');
      } else if (listing.listing_status == 'sold' && listing.listing_category == 'commercial') {
        elementBlock.find('.md-prop-com-leasesale').html('Sold');
      } else {
        elementBlock.find('.md-prop-com-leasesale').html(listing.leasesale);
      }

      if (typeof listing.commercialtypes !== 'undefined' && listing.commercialtypes && listing.commercialtypes !== null) {
        elementBlock.find('.md-prop-com-type').html(listing.commercialtypes);
        elementBlock.find('.md-prop-com-type-title').html(`<b>Type</b> <span>${listing.commercialtypes}</span>`);
        elementBlock.find('.md-prop-com-type-title-br-br').remove();
        elementBlock.find('.md-prop-com-type-title-br').html(`<b>Type</b> <span>${listing.commercialtypes}</span><br class="md-prop-com-type-title-br-br">`);
      } else {
        elementBlock.find('.md-prop-com-type').html('');
        elementBlock.find('.md-prop-com-type-title').html('');
        elementBlock.find('.md-prop-com-type-title-br').html('');
      }


      if (typeof listing.listingdate !== 'undefined' && listing.listingdate !== null && listing.listingdate) {
        elementBlock.find('.md-prop-field-listingdate').html(listing.listingdate);
      } else {
        elementBlock.find('.md-prop-field-listingdate').html('');
      }

      if (typeof listing.listingdatealt !== 'undefined' && listing.listingdatealt !== null && listing.listingdatealt) {
        elementBlock.find('.md-prop-field-listingdate-alt').html(listing.listingdatealt);
      } else {
        elementBlock.find('.md-prop-field-listingdate-alt').html('');
      }

      const listing_auctiondate = listing.auctiondate ? listing.auctiondate : '';

      const listing_auctionvenue = listing.auctionvenue ? listing.auctionvenue : '';
      elementBlock.find('.md-prop-field-auctionvenue').html(listing_auctionvenue);

      if(clientSetting.franchise_name) {
        elementBlock.find('.md-business-field-name').html(clientSetting.franchise_name);
      }

      if(clientSetting.name) {
        elementBlock.find('.md-office-field-name').html(clientSetting.name);
      }
      if (clientSetting.branch_name) {
        elementBlock.find('.md-office-field-branch').html(clientSetting.branch_name);
      } else {
        if (groupSetting !== null && groupSetting !== undefined) {
          elementBlock.find('.md-office-field-branch').html(groupSetting.branch_name);
        }
      }

      let listing_auction_details = '';
      if (listing_auctiondate !== ' ') {
        listing_auction_details = listing_auctiondate;
      }

      if (listing_auctionvenue !== ' ') {
        listing_auction_details = `${listing_auction_details} ${listing_auctionvenue}`;
      }

      elementBlock.find('.md-prop-field-auction').html(listing_auction_details);

      if (this.authService.isUk() && listing.energy_rating !== null) {
        elementBlock.find('.md-prop-field-energyrating').attr('src', listing.energy_rating);
      }

      if (auth.provider === 'vaultre' && !this.authService.isUk() && listing.energy_rating !== null) {
        elementBlock.find('.md-prop-field-energyrating').html(listing.energy_rating);
        elementBlock.find('.md-prop-field-energyrating-title').html('<b>EER</b>&nbsp;' + listing.energy_rating);
        elementBlock.find('.md-prop-field-energyrating-title-br-br').remove();
        elementBlock.find('.md-prop-field-energyrating-title-br').html('<b>EER</b>&nbsp;' + listing.energy_rating + '<br class="md-prop-field-energyrating-title-br-br">');
      } else {
        elementBlock.find('.md-prop-field-energyrating').html('');
        elementBlock.find('.md-prop-field-energyrating-title').html('');
        elementBlock.find('.md-prop-field-energyrating-title-br').html('');
      }

      if (typeof listing.epcrating !== 'undefined' && listing.epcrating && listing.epcrating !== null) {
        elementBlock.find('.md-prop-field-epcrating').html(listing.epcrating);
        elementBlock.find('.md-prop-field-epcrating-title').html(`<b>EPC Rating</b> ${listing.epcrating}`);
        elementBlock.find('.md-prop-field-epcrating-title-br-br').remove();
        elementBlock.find('.md-prop-field-epcrating-title-br').html(`<b>EPC Rating</b> ${listing.epcrating} <br class="md-prop-field-epcrating-title-br-br">`);
      } else {
        elementBlock.find('.md-prop-field-epcrating').html('');
        elementBlock.find('.md-prop-field-epcrating-title').html('');
        elementBlock.find('.md-prop-field-epcrating-title-br').html('');
      }

      // Custom Fields
      // tslint:disable-next-line: quotemark
      const customFields = elementBlock.find("[class*='md-prop-field-custom-']");

      if (customFields.length > 0) {
        customFields.each(function () {
          const customFieldClass = $(this).attr('class');
          const customFieldClasses = customFieldClass.split(' ');

          if (customFieldClasses.length > 0) {
            customFieldClasses.forEach((element) => {
              if (String(element).includes('md-prop-field-custom-')) {
                const fieldName = element.split('md-prop-field-custom-')[1];

                if (typeof listing.customfields !== 'undefined') {
                  if (typeof listing.customfields[fieldName] !== 'undefined' && listing.customfields[fieldName]) {
                    if (fieldName === 'receptionrooms') {
                      // tslint:disable-next-line: radix
                      listing.customfields[fieldName] = parseInt(listing.customfields[fieldName]);
                    }
                    elementBlock.find(`.md-prop-field-custom-${fieldName}`).html(listing.customfields[fieldName]);
                  } else {
                    elementBlock.find(`.md-prop-field-custom-${fieldName}`).html('');
                  }
                }
              }
            });
          }
        });
      }

      // Custom Features
      // tslint:disable-next-line: quotemark
      const customFeatures = elementBlock.find("[class*='md-prop-field-feature-']");
      if (customFeatures.length > 0) {
        customFeatures.each(function() {
          const customFeatureClass = $(this).attr('class');
          const customFeatureClasses = customFeatureClass.split(' ');

          if (customFeatureClasses.length > 0) {
            customFeatureClasses.forEach(element => {
              if (String(element).includes('md-prop-field-feature-')) {
                const fieldNameSplit = element.split('md-prop-field-feature-')[1];
                const fieldName = fieldNameSplit.split('-')[0];

                if (typeof listing.customfeatures !== 'undefined') {
                  if (typeof listing.customfeatures[fieldName] !== 'undefined' && listing.customfeatures[fieldName]
                  && listing.customfeatures[fieldName].length !== 0 && listing.customfeatures[fieldName][0] !== '') {

                    /* If the field name livingrooms exist, add this merge field */
                    if (fieldName === 'livingrooms') {
                      const featureDataValue = listing.customfeatures[fieldName][0]['data'] !== "0" ? listing.customfeatures[fieldName][0]['data'] : "";
                      elementBlock.find(`.md-prop-field-feature-${fieldName}`).html(featureDataValue);
                      elementBlock.find(`.md-prop-field-feature-${fieldName}-title`).html(`<b>${listing.customfeatures[fieldName][0]['displayName']}</b> ${featureDataValue}`);
                    } else {
                      elementBlock.find(`.md-prop-field-feature-${fieldName}`).html(listing.customfeatures[fieldName].filter(n => n).join(', '));

                      const formattedFeature = listing.customfeatures[fieldName].filter(n => n).map((features: string) =>
                        `<span class="bullet">&bull;</span><span class='feature'>${features}</span>`);
                      elementBlock.find(`.md-prop-field-feature-${fieldName}-list`).html(formattedFeature.join('<br>'));

                      const formattedFeatureAlt = listing.customfeatures[fieldName].filter(n => n).map((features: string) =>
                        `<span class='feature'>${features}</span>`);
                      elementBlock.find(`.md-prop-field-feature-${fieldName}-list-alt`).html(formattedFeatureAlt.join('<br>'));

                      const featureTitleSplit = fieldName.split(/(?=[A-Z])/);
                      for (let i = 0; i < featureTitleSplit.length; i++) {
                        featureTitleSplit[i] = featureTitleSplit[i].charAt(0).toUpperCase() + featureTitleSplit[i].slice(1);
                      }
                      const featureTitle = featureTitleSplit.join(' ');

                      if (fieldName === 'otherfeatures') {
                        elementBlock.find(`.md-prop-field-feature-${fieldName}-title`).html(`<b>Other Features</b> <span>${listing.customfeatures[fieldName].filter(n => n).join(', ')}</span><br>`);
                      } else if (fieldName === 'excludedchattels') {
                        elementBlock.find(`.md-prop-field-feature-${fieldName}-title`).html(`<b>Excluded Chattels</b> <span>${listing.customfeatures[fieldName].filter(n => n).join(', ')}</span><br>`);
                      }else {
                        elementBlock.find(`.md-prop-field-feature-${fieldName}-title`).html(`<b>${featureTitle}</b> <span>${listing.customfeatures[fieldName].filter(n => n).join(', ')}</span><br>`);

                      }
                    }
                  } else {
                    elementBlock.find(`.md-prop-field-feature-${fieldName}`).html('');
                    elementBlock.find(`.md-prop-field-feature-${fieldName}-list`).html('');
                    elementBlock.find(`.md-prop-field-feature-${fieldName}-list-alt`).html('');
                    elementBlock.find(`.md-prop-field-feature-${fieldName}-title`).html('');
                  }
                }
              }
            });
          }
        });
      }

      if (typeof listing.customfeatures !== 'undefined' && listing.customfeatures) {
        if (typeof listing.customfeatures.general !== 'undefined' && listing.customfeatures.general.includes('Study')) {
          elementBlock.find('.md-prop-field-feature-study').html('Study');
          elementBlock.find('.md-prop-field-feature-study-number').html('1');
        } else {
          elementBlock.find('.md-prop-field-feature-study').html('');
          elementBlock.find('.md-prop-field-feature-study-number').html('');
        }

        if (typeof listing.customfeatures.exterior !== 'undefined') {
          if (listing.customfeatures.exterior.includes('Tennis Court')) {
            elementBlock.find('.md-prop-field-feature-tennis').html('Tennis Court');
            elementBlock.find('.md-prop-field-feature-tennis-number').html('1');
          } else {
            elementBlock.find('.md-prop-field-feature-tennis').html('');
            elementBlock.find('.md-prop-field-feature-tennis-number').html('');
          }

          let poolNumber = 0;
          if (listing.customfeatures.exterior.includes('Pool Above Ground')) {
            poolNumber = poolNumber + 1;
          }

          if (listing.customfeatures.exterior.includes('Pool Inground')) {
            poolNumber = poolNumber + 1;
          }

          if (listing.customfeatures.exterior.includes('Pool Above Ground') || listing.customfeatures.exterior.includes('Pool Inground')) {
            elementBlock.find('.md-prop-field-feature-pool').html('Pool');
            elementBlock.find('.md-prop-field-feature-pool-number').html(poolNumber);
          } else {
            elementBlock.find('.md-prop-field-feature-pool').html('');
            elementBlock.find('.md-prop-field-feature-pool-number').html('');
          }
        }

      }

      elementBlock.find('.md-prop-field-owners-name').html('');
      elementBlock.find('.md-prop-field-owners-company').html('');
      elementBlock.find('.md-prop-field-owners-address').html('');
      elementBlock.find('.md-prop-field-owners-mobile').html('');
      elementBlock.find('.md-prop-field-owners-email').html('');

      elementBlock.find('.md-prop-field-owners-name-1').html('');
      elementBlock.find('.md-prop-field-owners-company-1').html('');
      elementBlock.find('.md-prop-field-owners-address-1').html('');
      elementBlock.find('.md-prop-field-owners-mobile-1').html('');
      elementBlock.find('.md-prop-field-owners-email-1').html('');

      elementBlock.find('.md-prop-field-owners-name-2').html('');
      elementBlock.find('.md-prop-field-owners-company-2').html('');
      elementBlock.find('.md-prop-field-owners-address-2').html('');
      elementBlock.find('.md-prop-field-owners-mobile-2').html('');
      elementBlock.find('.md-prop-field-owners-email-2').html('');

      if (typeof listing.owners !== 'undefined' && listing.owners) {
        if (typeof listing.owners[0] !== 'undefined' && listing.owners[0]) {
          if (typeof listing.owners[0].name !== 'undefined' && listing.owners[0].name) {
            elementBlock.find('.md-prop-field-owners-name').html(listing.owners[0].name);
            elementBlock.find('.md-prop-field-owners-name-1').html(listing.owners[0].name);
          } else {
            elementBlock.find('.md-prop-field-owners-name').html('');
            elementBlock.find('.md-prop-field-owners-name-1').html('');
          }

          if (typeof listing.owners[0].company !== 'undefined' && listing.owners[0].company) {
            elementBlock.find('.md-prop-field-owners-company').html(listing.owners[0].company);
            elementBlock.find('.md-prop-field-owners-company-1').html(listing.owners[0].company);
          } else {
            elementBlock.find('.md-prop-field-owners-company').html('');
            elementBlock.find('.md-prop-field-owners-company-1').html('');
          }

          if (typeof listing.owners[0].address !== 'undefined' && listing.owners[0].address) {
            elementBlock.find('.md-prop-field-owners-address').html(listing.owners[0].address);
            elementBlock.find('.md-prop-field-owners-address-1').html(listing.owners[0].address);
          } else {
            elementBlock.find('.md-prop-field-owners-address').html('');
            elementBlock.find('.md-prop-field-owners-address-1').html('');
          }

          if (typeof listing.owners[0].phone !== 'undefined' && listing.owners[0].phone) {
            elementBlock.find('.md-prop-field-owners-mobile').html(listing.owners[0].phone);
            elementBlock.find('.md-prop-field-owners-mobile-1').html(listing.owners[0].phone);
          } else {
            elementBlock.find('.md-prop-field-owners-mobile').html('');
            elementBlock.find('.md-prop-field-owners-mobile-1').html('');
          }

          if (typeof listing.owners[0].email !== 'undefined' && listing.owners[0].email) {
            elementBlock.find('.md-prop-field-owners-email').html(listing.owners[0].email);
            elementBlock.find('.md-prop-field-owners-email-1').html(listing.owners[0].email);
          } else {
            elementBlock.find('.md-prop-field-owners-email').html('');
            elementBlock.find('.md-prop-field-owners-email-1').html('');
          }
        }

        if (typeof listing.owners[1] !== 'undefined' && listing.owners[1]) {
          if (typeof listing.owners[1].name !== 'undefined' && listing.owners[1].name) {
            elementBlock.find('.md-prop-field-owners-name-2').html(listing.owners[1].name);
          } else {
            elementBlock.find('.md-prop-field-owners-name-2').html('');
          }

          if (typeof listing.owners[1].company !== 'undefined' && listing.owners[1].company) {
            elementBlock.find('.md-prop-field-owners-company-2').html(listing.owners[1].company);
          } else {
            elementBlock.find('.md-prop-field-owners-company-2').html('');
          }

          if (typeof listing.owners[1].address !== 'undefined' && listing.owners[1].address) {
            elementBlock.find('.md-prop-field-owners-address-2').html(listing.owners[1].address);
          } else {
            elementBlock.find('.md-prop-field-owners-address-2').html('');
          }

          if (typeof listing.owners[1].phone !== 'undefined' && listing.owners[1].phone) {
            elementBlock.find('.md-prop-field-owners-mobile-2').html(listing.owners[1].phone);
          } else {
            elementBlock.find('.md-prop-field-owners-mobile-2').html('');
          }

          if (typeof listing.owners[1].email !== 'undefined' && listing.owners[1].email) {
            elementBlock.find('.md-prop-field-owners-email-2').html(listing.owners[1].email);
          } else {
            elementBlock.find('.md-prop-field-owners-email-2').html('');
          }
        }

        /*
          Merge field: md-prop-field-ownernames
          Use: Dynamic listing owner names for merge field md-prop-field-ownernames
        */
          if (listing.owners.length) {
            let ownerNames = '';
            listing.owners.forEach((owner, index) => {
              if (index === listing.owners.length - 1) {
                ownerNames = ownerNames + owner.name;
              } else {
                ownerNames = ownerNames + owner.name;
                if (index === listing.owners.length - 2) {
                  ownerNames = ownerNames + ' and ';
                } else {
                  ownerNames = ownerNames + ', ';
                }
              }
            });
            elementBlock.find('.md-prop-field-ownernames').html(ownerNames);
          } else {
            elementBlock.find('.md-prop-field-ownernames').html('');
          }
          /* End of Merge field: md-prop-field-ownernames */
      }

      elementBlock.find('.md-prop-field-tenants-firstname-1').html('');
      elementBlock.find('.md-prop-field-tenants-lastname-1').html('');
      elementBlock.find('.md-prop-field-tenants-phone-1').html('');

      elementBlock.find('.md-prop-field-tenants-firstname-2').html('');
      elementBlock.find('.md-prop-field-tenants-lastname-2').html('');
      elementBlock.find('.md-prop-field-tenants-phone-2').html('');

      if (typeof listing.tenants !== 'undefined' && listing.tenants) {
        if (typeof listing.tenants[0] !== 'undefined' && listing.tenants[0]) {
          if (typeof listing.tenants[0].firstname !== 'undefined' && listing.tenants[0].firstname) {
            elementBlock.find('.md-prop-field-tenants-firstname-1').html(listing.tenants[0].firstname);
          } else {
            elementBlock.find('.md-prop-field-tenants-firstname-1').html('');
          }

          if (typeof listing.tenants[0].lastname !== 'undefined' && listing.tenants[0].lastname) {
            elementBlock.find('.md-prop-field-tenants-lastname-1').html(listing.tenants[0].lastname);
          } else {
            elementBlock.find('.md-prop-field-tenants-lastname-1').html('');
          }

          if (typeof listing.tenants[0].phone !== 'undefined' && listing.tenants[0].phone) {
            elementBlock.find('.md-prop-field-tenants-phone-1').html(listing.tenants[0].phone);
          } else {
            elementBlock.find('.md-prop-field-tenants-phone-1').html('');
          }
        }

        if (typeof listing.tenants[1] !== 'undefined' && listing.tenants[1]) {
          if (typeof listing.tenants[1].firstname !== 'undefined' && listing.tenants[1].firstname) {
            elementBlock.find('.md-prop-field-tenants-firstname-2').html(listing.tenants[1].firstname);
          } else {
            elementBlock.find('.md-prop-field-tenants-firstname-2').html('');
          }

          if (typeof listing.tenants[1].lastname !== 'undefined' && listing.tenants[1].lastname) {
            elementBlock.find('.md-prop-field-tenants-lastname-2').html(listing.tenants[1].lastname);
          } else {
            elementBlock.find('.md-prop-field-tenants-lastname-2').html('');
          }

          if (typeof listing.tenants[1].phone !== 'undefined' && listing.tenants[1].phone) {
            elementBlock.find('.md-prop-field-tenants-phone-2').html(listing.tenants[1].phone);
          } else {
            elementBlock.find('.md-prop-field-tenants-phone-2').html('');
          }
        }
      }


      if (typeof listing.portalstatus !== 'undefined' && listing.portalstatus !== null && listing.portalstatus) {
        elementBlock.find('.md-prop-field-status-alt').html(listing.portalstatus);
      } else {
        elementBlock.find('.md-prop-field-status-alt').html('');
      }

      elementBlock.find('.md-prop-tommys-tender').html('');

      if (typeof listing.methodofsale !== 'undefined' && listing.methodofsale === 'Tender') {
        if (typeof listing.tenderdate_nz !== 'undefined' && listing.tenderdate_nz) {
          const officeStreet = typeof clientSetting.street !== 'undefined' ? clientSetting.street : '';
          const officeSuburb = typeof clientSetting.suburb !== 'undefined' ? clientSetting.suburb : '';
          // tslint:disable-next-line: max-line-length
          elementBlock.find('.md-prop-tommys-tender').html(`Tender closing ${listing.tenderdate_nz} at Tommy’s Real Estate Ltd, ${officeStreet}, ${officeSuburb}`);
        }
      }

      // if (typeof listing.methodofsale !== 'undefined' && listing.methodofsale === 'Auction') {
      //   // elementBlock.find('.md-prop-field-pricequalifier').html('To be auctioned');
      //   elementBlock.find('.md-prop-field-searchprice').html('');

      //   if (typeof listing.displayprice !== 'undefined' && listing.displayprice) {
      //     elementBlock.find('.md-prop-field-price-alt').html(listing.displayprice);
      //   } else {
      //     elementBlock.find('.md-prop-field-price-alt').html('<b>To be auctioned</b>');
      //   }
      // }

      let roomsHtml = '';
      if (typeof listing.rooms !== 'undefined' && listing.rooms) {
        listing.rooms.forEach((room) => {
          let roomSize = '';

          if (room.width !== 0 && room.length !== 0) {
            if (room.units === 'metres') {
              const roomWidth = room.width * 3.281;
              const roomLength = room.length * 3.281;

              const roomWidthArray = roomWidth.toString().split('.');
              const roomWidthFeet = `${roomWidthArray[0]}'`;

              let roomWidthInch = `${roomWidthArray[1][0]}"`;

              if (roomWidthArray[1][0] === '0') {
                roomWidthInch = '';
              }

              const roomLengthArray = roomLength.toString().split('.');
              const roomLengthFeet = `${roomLengthArray[0]}'`;
              let roomLengthInch = `${roomLengthArray[1][0]}"`;

              if (roomLengthArray[1][0] === '0') {
                roomLengthInch = '';
              }

              roomSize = `${roomWidthFeet}${roomWidthInch} x ${roomLengthFeet}${roomLengthInch} (${room.width}m x ${room.length}m)`;
            } else {
              const roomWidth = `${room.width}`;
              const roomWidthFeet = roomWidth.split('.');
              const widthFeetStr = `${roomWidthFeet[0]}'`;
              const widthFeet = `${roomWidthFeet[0]}`;

              let widthInch = '';
              let widthInchStr = '';
              if (typeof roomWidthFeet[1] !== 'undefined') {

                let roomWidthFeetInt = parseInt(roomWidthFeet[1]);

                if (roomWidthFeetInt < 10) {
                  roomWidthFeetInt = roomWidthFeetInt * 10;
                }

                const widthInches = Math.round((roomWidthFeetInt / 100) * 12);

                if (widthInches !== 0) {
                  widthInch = `${widthInches}`;
                  widthInchStr = `${widthInches}"`;
                }
              }

              const roomLength = `${room.length}`;
              const roomLengthFeet = roomLength.split('.');
              const lengthFeet = `${roomLengthFeet[0]}`;
              const lengthFeetStr = `${roomLengthFeet[0]}'`;

              let lengthInch = '';
              let lengthInchStr = '';
              if (typeof roomLengthFeet[1] !== 'undefined') {

                let roomLengthFeetInt = parseInt(roomLengthFeet[1]);

                if (roomLengthFeetInt < 10) {
                  roomLengthFeetInt = roomLengthFeetInt * 10;
                }

                const LengthInches = Math.round((roomLengthFeetInt / 100) * 12);

                if (LengthInches !== 0) {
                  lengthInch = `${LengthInches}`;
                  lengthInchStr = `${LengthInches}"`;
                }
              }

              // tslint:disable-next-line: radix
              let widthMeter = parseFloat(`${widthFeet}.${widthInch}`);
              // tslint:disable-next-line: radix
              let lengthMeter = parseFloat(`${lengthFeet}.${lengthInch}`);

              widthMeter = widthMeter / 3.281;
              lengthMeter = lengthMeter / 3.281;

              // tslint:disable-next-line: max-line-length
              roomSize = `${widthFeetStr}${widthInchStr} x ${lengthFeetStr}${lengthInchStr} (${widthMeter.toFixed(2)}m x ${lengthMeter.toFixed(2)}m)<br>`;

            }
          }

          // tslint:disable-next-line: max-line-length
          roomsHtml += `<span class="room"><b>${room.name}</b><br>${room.description}<br>${roomSize}<br><code class="room-end"></code></span>`;
        });

        elementBlock.find('.md-prop-field-rooms').html(roomsHtml);

        const lenghtRoomNumber = elementBlock.find('[class*="md-prop-field-rooms-"]').length;
        let lastroomNumber = elementBlock.find('[class*="md-prop-field-rooms-"]')[lenghtRoomNumber - 1];

        if (lastroomNumber) {
          lastroomNumber = lastroomNumber.attributes[0].value;
          const getClassRoom = lastroomNumber.split(' ');

          const totalRoomLength = getClassRoom[0].replace('md-prop-field-rooms-', '');
          for (let i = 0; i < totalRoomLength; i++) {
            let roomHtml = '';
            const roomIndex = i + 1;

            let roomSize = '';

            if (listing.rooms[i].width !== 0 && listing.rooms[i].length !== 0) {
              if (listing.rooms[i].units === 'metres') {
                const roomWidth = listing.rooms[i].width * 3.281;
                const roomLength = listing.rooms[i].length * 3.281;

                const roomWidthArray = roomWidth.toString().split('.');
                const roomWidthFeet = `${roomWidthArray[0]}'`;

                let roomWidthInch = `${roomWidthArray[1][0]}"`;

                if (roomWidthArray[1][0] === '0') {
                  roomWidthInch = '';
                }

                const roomLengthArray = roomLength.toString().split('.');
                const roomLengthFeet = `${roomLengthArray[0]}'`;
                let roomLengthInch = `${roomLengthArray[1][0]}"`;

                if (roomLengthArray[1][0] === '0') {
                  roomLengthInch = '';
                }

                // tslint:disable-next-line: max-line-length
                roomSize = `${roomWidthFeet}${roomWidthInch} x ${roomLengthFeet}${roomLengthInch} (${listing.rooms[i].width}m x ${listing.rooms[i].length}m)`;

              } else {
                const roomWidth = `${listing.rooms[i].width}`;
              const roomWidthFeet = roomWidth.split('.');
              const widthFeetStr = `${roomWidthFeet[0]}'`;
              const widthFeet = `${roomWidthFeet[0]}`;

              let widthInch = '';
              let widthInchStr = '';
              if (typeof roomWidthFeet[1] !== 'undefined') {

                let roomWidthFeetInt = parseInt(roomWidthFeet[1]);

                if (roomWidthFeetInt < 10) {
                  roomWidthFeetInt = roomWidthFeetInt * 10;
                }

                const widthInches = Math.round((roomWidthFeetInt / 100) * 12);

                if (widthInches !== 0) {
                  widthInch = `${widthInches}`;
                  widthInchStr = `${widthInches}"`;
                }
              }

              const roomLength = `${listing.rooms[i].length}`;
              const roomLengthFeet = roomLength.split('.');
              const lengthFeet = `${roomLengthFeet[0]}`;
              const lengthFeetStr = `${roomLengthFeet[0]}'`;

              let lengthInch = '';
              let lengthInchStr = '';
              if (typeof roomLengthFeet[1] !== 'undefined') {

                let roomLengthFeetInt = parseInt(roomLengthFeet[1]);

                if (roomLengthFeetInt < 10) {
                  roomLengthFeetInt = roomLengthFeetInt * 10;
                }

                const LengthInches = Math.round((roomLengthFeetInt / 100) * 12);

                if (LengthInches !== 0) {
                  lengthInch = `${LengthInches}`;
                  lengthInchStr = `${LengthInches}"`;
                }
              }

              // tslint:disable-next-line: radix
              let widthMeter = parseFloat(`${widthFeet}.${widthInch}`);
              // tslint:disable-next-line: radix
              let lengthMeter = parseFloat(`${lengthFeet}.${lengthInch}`);

              widthMeter = widthMeter / 3.281;
              lengthMeter = lengthMeter / 3.281;

              // tslint:disable-next-line: max-line-length
              roomSize = `${widthFeetStr}${widthInchStr} x ${lengthFeetStr}${lengthInchStr} (${widthMeter.toFixed(2)}m x ${lengthMeter.toFixed(2)}m)<br>`;

              }
            }

            if (typeof listing.rooms[i] !== 'undefined' ) {
              roomHtml += `<b>${listing.rooms[i].name}</b><br>${listing.rooms[i].description}<br>${roomSize}`;
            }

            elementBlock.find('.md-prop-field-rooms-' + roomIndex).html(roomHtml);
          }
        }
      } else {
        elementBlock.find('.md-prop-field-rooms').html('');
        const lenghtRoomNumber = elementBlock.find('[class*="md-prop-field-rooms-"]').length;
        let lastroomNumber = elementBlock.find('[class*="md-prop-field-rooms-"]')[lenghtRoomNumber - 1];

        if (lastroomNumber && lastroomNumber.length) {
          lastroomNumber = lastroomNumber.attributes[0].value;

          const totalRoomLength = lastroomNumber.replace('md-prop-field-rooms-', '');

          for (let i = 0; i < totalRoomLength; i++) {
            const roomIndex = i + 1;
            elementBlock.find('.md-prop-field-rooms-' + roomIndex).html('');
          }
        }
      }

      if (typeof listing.highlights !== 'undefined' && listing.highlights) {
       
        elementBlock.find('.md-prop-field-highlights').html(listing.highlights.join(', '));

        // tslint:disable-next-line: max-line-length
        const formattedHighlights = listing.highlights.map((highlight: string) => `<span class="bullet">&bull;</span><span class='highlight'>${highlight}</span>`);
        elementBlock.find('.md-prop-field-highlights-list').html(formattedHighlights.join('<br>'));
        // tslint:disable-next-line: max-line-length
        elementBlock.find('.md-prop-field-highlights-title-list').html('<span class="highlights-title">Property Highlights</span><br/>' + formattedHighlights.join('<br>'));

        const formattedHighlightsAlt = listing.highlights.map((highlight: string) => `<span class='highlight'>${highlight}</span>`);
        elementBlock.find('.md-prop-field-highlights-list-alt').html(formattedHighlightsAlt.join('<br>'));

        for (let i = 0; i < 20; i++) {
          const highlightIndex = i + 1;
          if (typeof listing.highlights[i] !== 'undefined') {
            elementBlock.find('.md-prop-field-highlights-' + highlightIndex).html(listing.highlights[i]);
          } else {
            elementBlock.find('.md-prop-field-highlights-' + highlightIndex).html('');
          }
        }
      }

      elementBlock.find('.md-prop-field-map-image').attr('src', listing.map_image);

      // Reset Class Merge Field if required
      elementBlock.find("img[data-src*='{$']").each(function () {
        const imgValue = $(this).attr('data-src').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');

        if (imgValue.includes('{%REFID%}')) {
          $(this).attr('src', imgValue);
        }
      });

      elementBlock.find("a[data-url*='{$']").each(function () {
        const hrefValue = $(this).attr('data-url').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');

        if (hrefValue.includes('{%REFID%}') || hrefValue.includes('{%VIEWLINKURL%}') || hrefValue.includes('{%VIEWLINKSHORTURL%}')
          || hrefValue.includes('{%ETABLEURL%}')) {
          $(this).attr('href', hrefValue);
          $(this).html(hrefValue);
        }

        if (hrefValue.includes('{%OFFERLINKURL%}')) {
          $(this).attr('href', hrefValue);
        }
      });

      elementBlock.find("[data-text*='{$']").each(function () {
        const textValue = $(this).attr('data-text').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');

        if (textValue.includes('{%REFID%}') || textValue.includes('{%VIEWLINKURL%}') || textValue.includes('{%VIEWLINKSHORTURL%}')) {
          $(this).html(textValue);
        }
      });

      let listing_url = '#';

      if (listing.url && listing.url !== '') {
        listing_url = listing.url;
      } else {
        if (listing.etableurl && listing.etableurl !== '' && listing.etableurl !== null) {
          listing_url = listing.etableurl;
        } else {
          if (clientSetting.link_website !== '') {
            listing_url = clientSetting.link_website;
          } else {
            if (groupSetting !== null && groupSetting !== undefined && groupSetting.link_website !== '') {
              listing_url = groupSetting.link_website;
            }
          }
        }
      }

      let listing_etableurl = '#';

      if (listing.etableurl && listing.etableurl !== '' && listing.etableurl !== null) {
        listing_etableurl = listing.etableurl;
      } else {
        listing_etableurl = listing_url;
      }

      let listing_offerurl = '#';
      if (listing.offers_url && listing.offers_url !== '' && listing.offers_url !== null) {
        listing_offerurl = listing.offers_url;
      }

      let listing_url_short = '#';

      if (listing.url && listing.url !== '') {
        const short_url = listing.url.replace('http://', '').replace('https://', '');
        listing_url_short = short_url;
      } else {
        if (listing.etableurl && listing.etableurl !== '' && listing.etableurl !== null) {
          listing_url_short = listing.etableurl;
        } else {
          if (clientSetting.link_short_website !== '') {
            listing_url_short = clientSetting.link_short_website;
          } else {
            if (groupSetting !== null && groupSetting !== undefined && groupSetting.link_short_website !== '') {
              listing_url_short = groupSetting.link_short_website;
            }
          }
        }
      }

      elementBlock.find("img[data-src*='{$']").each(function () {
        const imgMergeField = $(this).attr('data-src');
        const imgValue = $(this).attr('data-src').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');
        if (imgValue.includes('{%VIEWLINKURL%}')) {            
          if (listing_url) {
            $(this).attr('src', imgValue);
          } else {
            $(this).attr('src', imgMergeField);
          }
        }
      });

      // Non Class Property - Merge Field
      let elementBlockHtml = elementBlock.html();

      elementBlockHtml = elementBlockHtml.replace(/\{%VIEWLINKURL%\}/gi, listing_url);

      elementBlockHtml = elementBlockHtml.replace(/\{%VIEWLINKSHORTURL%\}/gi, listing_url_short);
      elementBlockHtml = elementBlockHtml.replace(/\{%OFFERLINKURL%\}/gi, listing_offerurl);
      elementBlockHtml = elementBlockHtml.replace(/\{%ETABLEURL%\}/gi, listing_etableurl);

      const listing_videourl = listing.videourl ? listing.videourl : '';
      elementBlockHtml = elementBlockHtml.replace(/\{%VIDEOURL%\}/gi, listing_videourl);

      const listing_virtualtour = listing.virtualtour ? listing.virtualtour : '';
      elementBlockHtml = elementBlockHtml.replace(/\{%VIRTUALTOURURL%\}/gi, listing_virtualtour);
      elementBlockHtml = elementBlockHtml.replace(/\{%VIRTUALTOURURL-TITLE%\}/gi, `<b>Virtual Tour</b> <span>${listing_virtualtour}</span>`);
      elementBlockHtml = elementBlockHtml.replace(/\{%LPHOTO%\}/gi, listing_agent1_imageurl);
      elementBlockHtml = elementBlockHtml.replace(/\{%LPHOTO2%\}/gi, listing_agent2_imageurl);
      elementBlockHtml = elementBlockHtml.replace(/\{%LEMAIL%\}/gi, listing_agent1_email);
      elementBlockHtml = elementBlockHtml.replace(/\{%LEMAIL2%\}/gi, listing_agent2_email);
      elementBlockHtml = elementBlockHtml.replace(/\{%ID%\}/gi, listing_id);
      elementBlockHtml = elementBlockHtml.replace(/\{%OFFICEID%\}/gi, this.authService.auth.groupid);
      elementBlockHtml = elementBlockHtml.replace(/\{%REFID%\}/gi, listing_refid);
      elementBlockHtml = elementBlockHtml.replace(/\{%LEMAIL%\}/gi, listing_agent1_email);
      elementBlockHtml = elementBlockHtml.replace(/\{%LEMAIL2%\}/gi, listing_agent2_email);
      elementBlockHtml = elementBlockHtml.replace(/\{%WEBBOOKPROPERTYSTREET%\}/gi, webbook_street);
      elementBlockHtml = elementBlockHtml.replace(/\{%WEBBOOKPROPERTYSUBURB%\}/gi, webbook_suburb);

      elementBlock.html(elementBlockHtml);

      elementBlock.find('.md-prop-field-opentime').html('By appointment');
      elementBlock.find('.md-prop-field-opentimes-title').html('');
      elementBlock.find('.md-prop-field-opentimes-title-br').html('');

      elementBlock.find('.md-prop-field-multiple-opentimes').html('By appointment');
      elementBlock.find('.md-prop-field-multiple-opentimes-title').html('');
      elementBlock.find('.md-prop-field-multiple-opentimes-title-br').html('');

      let listing_opentimes = '';
      let listing_multipleopentimes = '';
      let listing_multipleopentimes_br = '';

      if (listing.inspections && listing.inspections.length > 0) {

        /* Add open times merge field  */
        let openTimesGrid = elementBlock.find('.open-times');
        if (openTimesGrid.length > 0) {
          const openTimesRows = openTimesGrid.find('.open-times-grid').children();
          if (openTimesRows.length > 0) {
            const openTimesRow = openTimesRows[1].outerHTML;
          
            //Remove sample row
            openTimesRows.last().remove();

            listing.inspections.forEach(inspection => {
              if ((typeof inspection.opendate !== 'undefined' && inspection.opendate) &&
              (typeof inspection.closedate !== 'undefined' && inspection.closedate)) {

                // Get sample row template
                let openTimesNewRow = $(openTimesRow);

                if (openTimesNewRow.children().length > 0) {
                  // Get sample block template
                  let openTimesNewBlock = openTimesNewRow.children()[0].outerHTML;

                  openTimesNewRow.empty();
                  
                  let openTimesDay = moment(inspection.opendate).format('DD');
                  let openTimesMonth = moment(inspection.opendate).format('MMM');
                  let openTimesStartTime = moment(inspection.opendate).format('hh.mm');
                  let openTimesEndTime = moment(inspection.closedate).format('hh.mma');

                  let address = '';

                  if (listing.address_unitnum !== null && listing.address_streetnum !== null) {
                    address = `${listing.address_suburb} ${address_unitnum}${listing.address_streetnum} ${listing.address_street}`;
                  } else {
                    address = `${listing.address_suburb} ${listing.address_street}`;
                  }

                  let openTimesMap = `https://maps.google.com/?q=${address}`;

                  // Temporary block template
                  let tempBlock = $(`${openTimesNewBlock}`);

                  if (tempBlock.length > 0) {
                    // Populate datas of the block
                    $(tempBlock).find('.open-times-date').html(openTimesDay);
                    $(tempBlock).find('.open-times-month').html(openTimesMonth);
                    $(tempBlock).find('.open-times-time').html(`${openTimesStartTime}-${openTimesEndTime}`);
                    $(tempBlock).find('.open-times-map').attr('href', openTimesMap);
                    
                    // Check row if there's no open times
                    if (openTimesGrid.find('.open-times-grid').children().length === 1) { 
                      let emptyColumn = $(openTimesNewBlock);
                      emptyColumn.empty();
                      
                      openTimesNewRow.append(tempBlock[0].outerHTML);
                      openTimesNewRow.append(emptyColumn);
                      openTimesGrid.find('.open-times-grid').append(openTimesNewRow);
                    } else { // If there's existing open times
                      let rowBlocks = openTimesGrid.find('.open-times-grid').children().last().children();
                      
                      if (rowBlocks.length > 0) {
                        // Check if only has one block in the row
                        if (rowBlocks.length === 1) {                                     
                          openTimesGrid.find('.open-times-grid').children().last().append(tempBlock[0].outerHTML);
                        } else { // If there's two blocks in the row

                          // Check if the second block has content
                          if ($(rowBlocks[1]).children().length > 0) {                    
                            openTimesNewRow.append(tempBlock[0].outerHTML);
                            openTimesGrid.find('.open-times-grid').append(openTimesNewRow);
                          } else { // If the second block has no content
                            openTimesGrid.find('.open-times-grid').children().last().children()[1].remove();
                            openTimesGrid.find('.open-times-grid').children().last().append(tempBlock[0].outerHTML);
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
          }
        }

        if (typeof listing.inspections[0].opendate !== 'undefined' && listing.inspections[0].opendate) {
          listing_opentimes = moment(listing.inspections[0].opendate).format('dddd Do MMMM, h:mmA');
          if (typeof listing.inspections[0].closedate !== 'undefined' && listing.inspections[0].closedate) {
            listing_opentimes += ' - ' + moment(listing.inspections[0].closedate).format('h:mmA');
          }

          listing_multipleopentimes = listing_opentimes;
          listing_multipleopentimes_br = listing_opentimes;
          elementBlock.find('.md-prop-field-opentime').html(listing_opentimes);
        }

        if (typeof listing.inspections[1] !== 'undefined') {
          if (typeof listing.inspections[1].opendate !== 'undefined' && listing.inspections[1].opendate) {
            let listing_opentimes2 = moment(listing.inspections[1].opendate).format('dddd Do MMMM, h:mmA');
            if (typeof listing.inspections[1].closedate !== 'undefined' && listing.inspections[1].closedate) {
              listing_opentimes2 += ' - ' + moment(listing.inspections[1].closedate).format('h:mmA');
            }

            listing_multipleopentimes = `${listing_multipleopentimes}, ${listing_opentimes2}`;
            listing_multipleopentimes_br = `${listing_multipleopentimes_br}, <br>${listing_opentimes2}`;
          }
        }

        if (typeof listing.inspections[2] !== 'undefined') {
          if (typeof listing.inspections[2].opendate !== 'undefined' && listing.inspections[2].opendate) {
            let listing_opentimes3 = moment(listing.inspections[2].opendate).format('dddd Do MMMM, h:mmA');
            if (typeof listing.inspections[2].closedate !== 'undefined' && listing.inspections[2].closedate) {
              listing_opentimes3 += ' - ' + moment(listing.inspections[2].closedate).format('h:mmA');
            }

            listing_multipleopentimes = `${listing_multipleopentimes}, ${listing_opentimes3}`;
            listing_multipleopentimes_br = `${listing_multipleopentimes_br}, <br>${listing_opentimes3}`;
          }
        }

        if (typeof listing.inspections[3] !== 'undefined') {
          if (typeof listing.inspections[3].opendate !== 'undefined' && listing.inspections[3].opendate) {
            let listing_opentimes4 = moment(listing.inspections[3].opendate).format('dddd Do MMMM, h:mmA');
            if (typeof listing.inspections[3].closedate !== 'undefined' && listing.inspections[3].closedate) {
              listing_opentimes4 += ' - ' + moment(listing.inspections[3].closedate).format('h:mmA');
            }

            listing_multipleopentimes = `${listing_multipleopentimes}, ${listing_opentimes4}`;
            listing_multipleopentimes_br = `${listing_multipleopentimes_br}, <br>${listing_opentimes4}`;
          }
        }
      } else {
        let openTimesGrid = elementBlock.find('.open-times');
        if (openTimesGrid.length > 0) {
          openTimesGrid.remove();
        }
      }

      if (typeof listing.bondprice !== 'undefined' && listing.bondprice !== null && listing.bondprice !== '' && listing.bondprice !== 0) {
        elementBlock.find('.md-prop-field-bond').html(listing.bondprice);
      } else {
        elementBlock.find('.md-prop-field-bond').html('');
      }

      if (listing_opentimes !== '') {
        elementBlock.find('.md-prop-field-opentimes-title').html('<b>View</b>&nbsp;' + listing_opentimes);
        elementBlock.find('.md-prop-field-opentimes-title-br-br').remove();
        elementBlock.find('.md-prop-field-opentimes-title-br').html('<b>View</b>&nbsp;' + listing_opentimes + '<br class="md-prop-field-opentimes-title-br-br">');
        elementBlock.find('.md-prop-field-opentimes-title-alt').html('<b>View</b><br> ' + listing_opentimes);
      } else {
        if (listing.openhomebyappointment === 1) {
          elementBlock.find('.md-prop-field-opentimes-title').html('<b>View</b>&nbsp;By Appointment');
          elementBlock.find('.md-prop-field-opentimes-title-br-br').remove();
          elementBlock.find('.md-prop-field-opentimes-title-br').html('<b>View</b>&nbsp;By Appointment<br class="md-prop-field-opentimes-title-br-br">');
          elementBlock.find('.md-prop-field-opentimes-title-alt').html('<b>View</b><br>By Appointment');
        } else {
          elementBlock.find('.md-prop-field-opentimes-title').html('');
          elementBlock.find('.md-prop-field-opentimes-title-alt').html('');
          elementBlock.find('.md-prop-field-opentimes-title-br').html('');
        }
      }

      if (listing_multipleopentimes !== '') {
        elementBlock.find('.md-prop-field-multiple-opentimes').html(listing_multipleopentimes);
        elementBlock.find('.md-prop-field-multiple-opentimes-title').html('<b>View</b>&nbsp;' + listing_multipleopentimes);
        elementBlock.find('.md-prop-field-multiple-opentimes-title-br-br').remove();
        elementBlock.find('.md-prop-field-multiple-opentimes-title-br').html('<b>View</b>&nbsp;' + listing_multipleopentimes_br + '<br class="md-prop-field-multiple-opentimes-title-br-br">');
        elementBlock.find('.md-prop-field-multiple-opentimes-title-alt').html('<b>View</b><br> ' + listing_multipleopentimes);
        elementBlock.find('.md-prop-field-multiple-opentimes-title-alt-br-br').remove();
        elementBlock.find('.md-prop-field-multiple-opentimes-title-alt-br').html(`<b>View</b><br> ${listing_multipleopentimes_br} <br class="md-prop-field-multiple-opentimes-title-alt-br-br">`);
      } else {
        if (listing.openhomebyappointment === 1) {
          elementBlock.find('.md-prop-field-multiple-opentimes').html('By Appointment');
          elementBlock.find('.md-prop-field-multiple-opentimes-title').html('<b>View</b>&nbsp;By Appointment');
          elementBlock.find('.md-prop-field-multiple-opentimes-title-br-br').remove();
          elementBlock.find('.md-prop-field-multiple-opentimes-title-br').html('<b>View</b>&nbsp;By Appointment<br class="md-prop-field-multiple-opentimes-title-br-br">');
          elementBlock.find('.md-prop-field-multiple-opentimes-title-alt').html('<b>View</b><br>By Appointment');
          elementBlock.find('.md-prop-field-multiple-opentimes-title-alt-br-br').remove();
          elementBlock.find('.md-prop-field-multiple-opentimes-title-alt-br').html('<b>View</b><br>By Appointment<br class="md-prop-field-multiple-opentimes-title-alt-br-br">');
        } else {
          elementBlock.find('.md-prop-field-multiple-opentimes').html('By appointment');
          elementBlock.find('.md-prop-field-multiple-opentimes-title').html('');
          elementBlock.find('.md-prop-field-multiple-opentimes-title-br').html('');
          elementBlock.find('.md-prop-field-multiple-opentimes-title-alt').html('');
          elementBlock.find('.md-prop-field-multiple-opentimes-title-alt-br').html('');
        }
      }

      if (typeof listing.volumenumber !== 'undefined' && listing.volumenumber && listing.volumenumber !== null) {
        elementBlock.find('.md-prop-field-volume').html(listing.volumenumber);
        /* New Title Revision of md-prop-field-volume */
        elementBlock.find('.md-prop-field-volume-title').html(`<b>Volume</b> <span>${listing.volumenumber}</span>`);
      } else {
        elementBlock.find('.md-prop-field-volume').html('');
        /* New Title Revision of md-prop-field-volume */
        elementBlock.find('.md-prop-field-volume-title').html('');
      }

      if (typeof listing.folionumber !== 'undefined' && listing.folionumber && listing.folionumber !== null) {
        elementBlock.find('.md-prop-field-folio').html(listing.folionumber);
        /* New Title Revision of md-prop-field-folio */
        elementBlock.find('.md-prop-field-folio-title').html(`<b>Folio</b> <span>${listing.folionumber}</span>`);
        
      } else {
        elementBlock.find('.md-prop-field-folio').html('');
        /* New Title Revision of md-prop-field-folio */
        elementBlock.find('.md-prop-field-folio-title').html('');
      }

      if (typeof listing.authoritytype !== 'undefined' && listing.authoritytype && listing.authoritytype !== null) {
        elementBlock.find('.md-prop-field-authoritytype').html(listing.authoritytype);
      } else {
        elementBlock.find('.md-prop-field-authoritytype').html('');
      }

      elementBlock.find('.md-prop-search').each(function () {
        $(this).attr('data-listing-id', listing.id);
      });

      if (typeof listing.tenanted !== 'undefined' && listing.tenanted !== null) {
        if (listing.tenanted === true) {
          elementBlock.find('.md-prop-field-tenanted').html('Tenanted');
          elementBlock.find('.md-prop-field-tenanted-title').html('<b>Tenanted</b> Yes');
          elementBlock.find('.md-prop-field-tenanted-title-br-br').remove();
          elementBlock.find('.md-prop-field-tenanted-title-br').html('<b>Tenanted</b> Yes <br class="md-prop-field-tenanted-title-br-br">');
        } else {
          elementBlock.find('.md-prop-field-tenanted').html('Vacant');
          elementBlock.find('.md-prop-field-tenanted-title').html('<b>Tenanted</b> No');
          elementBlock.find('.md-prop-field-tenanted-title-br-br').remove();
          elementBlock.find('.md-prop-field-tenanted-title-br').html('<b>Tenanted</b> No <br class="md-prop-field-tenanted-title-br-br">');
        }
      } else {
        elementBlock.find('.md-prop-field-tenanted').html('');
        elementBlock.find('.md-prop-field-tenanted-title').html('');
        elementBlock.find('.md-prop-field-tenanted-title-br').html('');
      }

      if (typeof listing.tenancystop !== 'undefined' && listing.tenancystop !== null) {
        const tenancystop = moment(listing.tenancystop).format('dddd, MMMM DD');

        elementBlock.find('.md-prop-field-tenancystop').html(tenancystop);
        elementBlock.find('.md-prop-field-tenancystop-title').html('<b>Tenancy ending</b> ' + tenancystop);
        elementBlock.find('.md-prop-field-tenancystop-title-br-br').remove();
        elementBlock.find('.md-prop-field-tenancystop-title-br').html('<b>Tenancy ending</b> ' + tenancystop + '<br class="md-prop-field-tenancystop-title-br-br">');
      } else {
        elementBlock.find('.md-prop-field-tenancystop').html('');
        elementBlock.find('.md-prop-field-tenancystop-title').html('');
        elementBlock.find('.md-prop-field-tenancystop-title-br').html('');
      }

      if (typeof listing.vpa !== 'undefined' && listing.vpa) {
        elementBlock.find('.md-prop-field-vpa').html(listing.vpa);
      } else {
        elementBlock.find('.md-prop-field-vpa').html('');
      }

      if (typeof listing.releasedate !== 'undefined' && listing.releasedate) {
        const releaseDate = moment(listing.releasedate).format('dddd, MMMM DD');
        elementBlock.find('.md-prop-field-release-date').html(listing.releasedate);
      } else {
        elementBlock.find('.md-prop-field-release-date').html('');
      }

      if (typeof listing.offers !== 'undefined' && listing.offers && typeof listing.offers.offerPrice !== 'undefined' && listing.offers.offerPrice) {
        elementBlock.find('.md-prop-field-offer-amount').html(`$${listing.offers.offerPrice.toLocaleString()}`);
      } else {
        elementBlock.find('.md-prop-field-offer-amount').html('');
      }

      if (typeof listing.offers !== 'undefined' && listing.offers && typeof listing.offers.offerDate !== 'undefined' && listing.offers.offerDate) {
        const offerDate = moment(listing.offers.offerDate).format('dddd, MMMM DD');
        elementBlock.find('.md-prop-field-offer-date').html(offerDate);
      } else {
        elementBlock.find('.md-prop-field-offer-date').html('');
      }

      if (typeof listing.gross_commission !== 'undefined' && listing.gross_commission) {
        elementBlock.find('.md-prop-field-gross-commissions').html(listing.gross_commission);
      } else {
        elementBlock.find('.md-prop-field-gross-commissions').html('');
      }

      if (typeof listing.gross_commission_gst !== 'undefined' && listing.gross_commission_gst) {
        elementBlock.find('.md-prop-field-gross-commissions-gst').html(listing.gross_commission_gst);
      } else {
        elementBlock.find('.md-prop-field-gross-commissions-gst').html('');
      }

      if (typeof listing.advertising !== 'undefined' && listing.advertising) {
        let totalUnpaid = 0;
        listing.advertising.forEach((advertising) => {
          if (advertising.amount !== null) {
            totalUnpaid = totalUnpaid + advertising.amount;
          }
        });
        elementBlock.find('.md-prop-field-marketing-unpaid').html(`$${Math.abs(totalUnpaid).toLocaleString()}`);

      } else {
        elementBlock.find('.md-prop-field-marketing-unpaid').html('');
      }

      if (typeof listing.solicitor !== 'undefined' && listing.solicitor && listing.solicitor.length !== 0) {
        if (listing.solicitor.vendor !== null && listing.solicitor.vendor && listing.solicitor.vendor.length !== 0) {
          elementBlock.find('.md-prop-field-vendor-solicitor-title').html(listing.solicitor.vendor.title);
          elementBlock.find('.md-prop-field-vendor-solicitor-fname').html(listing.solicitor.vendor.firstName);
          elementBlock.find('.md-prop-field-vendor-solicitor-lname').html(listing.solicitor.vendor.lastName);
          elementBlock.find('.md-prop-field-vendor-solicitor-company').html(listing.solicitor.vendor.company);

          const phoneNumbers = listing.solicitor.vendor.phoneNumbers;
          let vendorPhone = '';
          let vendorMobile = '';

          phoneNumbers.forEach((phone) => {
            if (phone.type === 'Mobile') {
              if (vendorMobile === '') {
                vendorMobile = phone.number;
              }
            }

            if (phone.type !== 'Mobile') {
              if (vendorPhone === '') {
                vendorPhone = phone.number;
              }
            }
          });

          elementBlock.find('.md-prop-field-vendor-solicitor-phone').html(vendorPhone);
          elementBlock.find('.md-prop-field-vendor-solicitor-mobile').html(vendorMobile);

          elementBlock.find('.md-prop-field-vendor-solicitor-email').html(listing.solicitor.vendor.emails[0]);


          let vendorPostalAddress = '';
          let vendorUnit = listing.solicitor.vendor.postalAddress.unitNumber;
          const vendorStreetNumber = listing.solicitor.vendor.postalAddress.streetNumber;
          const vendorStreet = listing.solicitor.vendor.postalAddress.street;
          const vendorSuburb = listing.solicitor.vendor.postalAddress.suburb !== null ? listing.solicitor.vendor.postalAddress.suburb.name : '';
          const vendorState = listing.solicitor.vendor.postalAddress.state !== null ? listing.solicitor.vendor.postalAddress.state.abbreviation : '';
          const vendorZip = listing.solicitor.vendor.postalAddress.suburb !== null ? listing.solicitor.vendor.postalAddress.suburb.postcode : '';

          if (vendorUnit !== '') {
            vendorUnit = vendorUnit + '/';
          }

          // tslint:disable-next-line: max-line-length
          vendorPostalAddress = vendorUnit + '' + vendorStreetNumber + ' ' + vendorStreet + ', ' + vendorSuburb + ' ' + vendorState + ' ' + vendorZip;

          let vendorAddress = '';
          let vendorAddressUnit = listing.solicitor.vendor.address.unitNumber;
          const vendorAddressStreetNumber = listing.solicitor.vendor.address.streetNumber;
          const vendorAddressStreet = listing.solicitor.vendor.address.street;
          const vendorAddressSuburb = listing.solicitor.vendor.address.suburb !== null ? listing.solicitor.vendor.address.suburb.name : '';
          const vendorAddressState = listing.solicitor.vendor.address.state !== null ? listing.solicitor.vendor.address.state.abbreviation : '';
          const vendorAddressZip = listing.solicitor.vendor.address.suburb !== null ? listing.solicitor.vendor.address.suburb.postcode : '';


          if (vendorAddressUnit !== '') {
            vendorAddressUnit = vendorAddressUnit + '/';
          }

          // tslint:disable-next-line: max-line-length
          vendorAddress = vendorAddressUnit + '' + vendorAddressStreetNumber + ' ' + vendorAddressStreet + ', ' + vendorAddressSuburb + ' ' + vendorAddressState + ' ' + vendorAddressZip;


          elementBlock.find('.md-prop-field-vendor-solicitor-postal-address').html(vendorPostalAddress);
          elementBlock.find('.md-prop-field-vendor-solicitor-address').html(vendorAddress);
        } else {
          elementBlock.find('.md-prop-field-vendor-solicitor-title').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-fname').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-lname').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-company').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-phone').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-mobile').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-email').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-postal-address').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-address').html('');
        }

        if (listing.solicitor.purchaser !== null && listing.solicitor.purchaser && listing.solicitor.purchaser.length !== 0) {
          elementBlock.find('.md-prop-field-purchaser-solicitor-title').html(listing.solicitor.purchaser.title);
          elementBlock.find('.md-prop-field-purchaser-solicitor-fname').html(listing.solicitor.purchaser.firstName);
          elementBlock.find('.md-prop-field-purchaser-solicitor-lname').html(listing.solicitor.purchaser.lastName);
          elementBlock.find('.md-prop-field-purchaser-solicitor-company').html(listing.solicitor.purchaser.company);

          const phoneNumbers = listing.solicitor.purchaser.phoneNumbers;
          let purchaserPhone = '';
          let purchaserMobile = '';

          phoneNumbers.forEach((phone) => {
            if (phone.type === 'Mobile') {
              if (purchaserMobile === '') {
                purchaserMobile = phone.number;
              }
            }

            if (phone.type !== 'Mobile') {
              if (purchaserPhone === '') {
                purchaserPhone = phone.number;
              }
            }
          });

          elementBlock.find('.md-prop-field-purchaser-solicitor-phone').html(purchaserPhone);
          elementBlock.find('.md-prop-field-purchaser-solicitor-mobile').html(purchaserMobile);

          elementBlock.find('.md-prop-field-purchaser-solicitor-email').html(listing.solicitor.purchaser.emails[0]);


          let purchaserPostalAddress = '';
          let purchaserUnit = listing.solicitor.purchaser.postalAddress.unitNumber;
          const purchaserStreetNumber = listing.solicitor.purchaser.postalAddress.streetNumber;
          const purchaserStreet = listing.solicitor.purchaser.postalAddress.street;
          const purchaserSuburb = listing.solicitor.purchaser.postalAddress.suburb !== null ? listing.solicitor.purchaser.postalAddress.suburb.name : '';
          const purchaserState = listing.solicitor.purchaser.postalAddress.state !== null ? listing.solicitor.purchaser.postalAddress.state.abbreviation : '';
          const purchaserZip = listing.solicitor.purchaser.postalAddress.suburb !== null ? listing.solicitor.purchaser.postalAddress.suburb.postcode : '';


          if (purchaserUnit !== '') {
            purchaserUnit = purchaserUnit + '/';
          }

          // tslint:disable-next-line: max-line-length
          purchaserPostalAddress = purchaserUnit + '' + purchaserStreetNumber + ' ' + purchaserStreet + ', ' + purchaserSuburb + ' ' + purchaserState + ' ' + purchaserZip;


          let purchaserAddress = '';
          let purchaserAddressUnit = listing.solicitor.purchaser.address.unitNumber;
          const purchaserAddressStreetNumber = listing.solicitor.purchaser.address.streetNumber;
          const purchaserAddressStreet = listing.solicitor.purchaser.address.street;
          const purchaserAddressSuburb = listing.solicitor.purchaser.address.suburb !== null ? listing.solicitor.purchaser.address.suburb.name : '';
          const purchaserAddressState = listing.solicitor.purchaser.address.state !== null ? listing.solicitor.purchaser.address.state.abbreviation : '';
          const purchaserAddressZip = listing.solicitor.purchaser.address.suburb !== null ? listing.solicitor.purchaser.address.suburb.postcode : '';


          if (purchaserAddressUnit !== '') {
            purchaserAddressUnit = purchaserAddressUnit + '/';
          }

          // tslint:disable-next-line: max-line-length
          purchaserAddress = purchaserAddressUnit + '' + purchaserAddressStreetNumber + ' ' + purchaserAddressStreet + ', ' + purchaserAddressSuburb + ' ' + purchaserAddressState + ' ' + purchaserAddressZip;

          elementBlock.find('.md-prop-field-purchaser-solicitor-postal-address').html(purchaserPostalAddress);
          elementBlock.find('.md-prop-field-purchaser-solicitor-address').html(purchaserAddress);
        } else {
          elementBlock.find('.md-prop-field-purchaser-solicitor-title').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-fname').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-lname').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-company').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-phone').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-mobile').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-email').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-postal-address').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-address').html('');
        }
      } else {
        elementBlock.find('.md-prop-field-vendor-solicitor-title').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-fname').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-lname').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-company').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-phone').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-mobile').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-email').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-postal-address').html('');

        elementBlock.find('.md-prop-field-purchaser-solicitor-title').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-fname').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-lname').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-company').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-phone').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-mobile').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-email').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-postal-address').html('');
      }

      if (typeof listing.offer_conditions !== 'undefined' && listing.offer_conditions) {
        let offerConditions = '';

        listing.offer_conditions.forEach((conditions) => {
          const dueDate = moment(conditions.due).format('dddd, MMMM DD, YYYY');
          offerConditions = offerConditions + `${conditions.condition.name} - ${dueDate}<br>`;
        });

        elementBlock.find('.md-prop-field-offer-conditions').html(offerConditions);
      } else {
        elementBlock.find('.md-prop-field-offer-conditions').html('');
      }

      if (typeof data.fillType !== 'undefined' && data.fillType === 'webbooks') {
        elementBlock.find('.md-prop-box').each((index: number, el) => {
          let propBoxFieldImages = $(el).find('.md-prop-field-image');

          if (!propBoxFieldImages.length) {
            return;
          }

          /* Special class to set default thumbnail */
          if (el.classList.contains('element-slider')) {
            /* Remove the box that cannot contain the image */
            let owlItem = $(elementBlock).find('.owl-carousel .owl-item').length > 0 ? '.owl-carousel .owl-item' : '.owl-carousel .item';
            $(elementBlock).find(owlItem).each(function (index) {
              index++;
              if (index > listing.images.length) {
                  $(this).closest('.owl-item').remove();
                  $(this).remove();
              }
            });
          }
          /* Create a dynamic slides based on listing images total count */
          if (listing.images.length > propBoxFieldImages.length) {
            let totalRemainingImg = listing.images.length - propBoxFieldImages.length;
            let carouselItem = $(el).find('.owl-carousel').find('.item').eq(0).html()
            let carouselItem1 = $(el).find('.owl-carousel').find('.owl-item').eq(0).html()
            for(var ctr = 0; ctr<totalRemainingImg; ctr++) {
              if ($(el).find('.owl-carousel .owl-stage').length > 0) {
                $(el).find('.owl-carousel .owl-stage').append(carouselItem1);
              } else {
                $(el).find('.owl-carousel').append(carouselItem);
              }
            }
            /* Get again the list of prop box field image after generate a dynamic element slides */
            propBoxFieldImages = $(el).find('.md-prop-field-image');
          }

          if (propBoxFieldImages.length) {
            propBoxFieldImages.each((imgIndex: number, imgEl) => {
              if (typeof listing.images !== 'undefined' && typeof listing.images[imgIndex] !== 'undefined') {
                let imageUrl = listing.images[imgIndex].url;
                if (typeof listing.images[imgIndex].fullImage !== 'undefined') {
                  imageUrl = listing.images[imgIndex].fullImage;
                }
                $(imgEl).attr('src', imageUrl);
              }
            });

            if ($(el).hasClass('md-prop-image-grid')) {
              const propBoxImageHtml = $(propBoxFieldImages[0]).parent()[0].outerHTML;

              if (listing.images.length) {
                const imageContainer = $(el).find('.container');

                if (imageContainer.length) {
                  const rowContainer = imageContainer.find('.row');

                  if (rowContainer.length) {
                    const rowItems = rowContainer.find('.grid-item');

                    if (rowItems.length) {
                      const propImageHtml = rowItems[0].outerHTML;

                      if (propImageHtml) {
                        rowContainer.empty();

                        listing.images.forEach((image) => {
                          let imageUrl = image.url;
                          if (typeof image.fullImage !== 'undefined' && image.hasOwnProperty('fullImage')
                            && image.fullImage !== '') {
                            imageUrl = image.fullImage;
                          }
                          const newPropImageHtml = propImageHtml.replace(/src="(.*?)"/g, `src="${imageUrl}"`);
                          rowContainer.append(newPropImageHtml);
                        });
                      }
                    }
                  }
                }
              } else {
                $(el).remove();
              }
            }
          }
        });

        const fileCabinetWrapper = elementBlock.find('.filling-cabinet-holder');

        if (fileCabinetWrapper.length && typeof listing.files !== 'undefined' && listing.files.length) {
          const fileCabinet = $(fileCabinetWrapper.first().html());

          if (fileCabinet.length) {
            fileCabinetWrapper.empty();

            listing.files.forEach(file => {
              const fileCabinetLink = fileCabinet.find('a');

              if (fileCabinetLink.length) {
                fileCabinetLink.text(file.filename);
                fileCabinetLink.attr('href', file.url);

                fileCabinetWrapper.append(fileCabinet);
              }
            });
          }
        }

        const largeFloorPlans = elementBlock.find('.md-prop-field-imagefloorplan-lrg');
        const smallFloorPlans = elementBlock.find('.md-prop-field-imagefloorplan-sml');

        largeFloorPlans.each((index: number, floorplan) => {
          // tslint:disable-next-line: max-line-length
          $(floorplan).attr('src', 'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/2354/WebBooks/Default-Theme/Images/floorplan.svg');

          if (typeof listing.floorplans !== 'undefined' && typeof listing.floorplans[index] !== 'undefined') {
            $(floorplan).attr('src', listing.floorplans[index].url);
          }

          if (typeof listing.floorplans !== 'undefined' && listing.floorplans.length && typeof listing.floorplans[index] === 'undefined') {
            $(floorplan).remove();
          }
        });

        smallFloorPlans.each((index: number, floorplan) => {
          // tslint:disable-next-line: max-line-length
          $(floorplan).attr('src', 'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/2354/WebBooks/Default-Theme/Images/floorplan.svg');

          if (typeof listing.floorplans !== 'undefined' && typeof listing.floorplans[index] !== 'undefined') {
            $(floorplan).attr('src', listing.floorplans[index].url);
          }

          if (typeof listing.floorplans !== 'undefined' && listing.floorplans.length && typeof listing.floorplans[index] === 'undefined') {
            $(floorplan).remove();
          }
        });
      }


      if (typeof listing.geolocation !== 'undefined' && listing.geolocation) {
        if (typeof listing.geolocation.latitude !== 'undefined' && listing.geolocation.latitude) {
          elementBlock.find('.md-prop-field-latitude-address').html(listing.geolocation.latitude);
        } else {
          elementBlock.find('.md-prop-field-latitude-address').html('');
        }

        if (typeof listing.geolocation.longitude !== 'undefined' && listing.geolocation.longitude) {
          elementBlock.find('.md-prop-field-longitude-address').html(listing.geolocation.longitude);
        } else {
          elementBlock.find('.md-prop-field-longitude-address').html('');
        }
      } else {
        elementBlock.find('.md-prop-field-latitude-address').html('');
        elementBlock.find('.md-prop-field-longitude-address').html('');
      }

      if (typeof listing.letagreed !== 'undefined' && listing.letagreed) {
        if (listing.portalstatus === 'conditional') {
          elementBlock.find('.md-prop-field-letagreed').html('Under Offer');
          elementBlock.find('.md-prop-field-status-uk').html('Under Offer');
        } else {
          elementBlock.find('.md-prop-field-letagreed').html('Let Agreed');
          elementBlock.find('.md-prop-field-status-uk').html('Let Agreed');

        }
      } else {
        elementBlock.find('.md-prop-field-letagreed').html('');
        if (listing.portalstatus === 'conditional') {
          elementBlock.find('.md-prop-field-status-uk').html('Under Offer');
        } else {
          elementBlock.find('.md-prop-field-status-uk').html('');
        }
      }

      if (listing.listing_category === 'commercial' && listing.listing_status === 'listing') {
        if(typeof listing.commercial_lease_price !== 'undefined' && listing.commercial_lease_price !== null && listing.commercial_lease_price) {
          if ( typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext ) {
            elementBlock.find('.md-prop-field-price').html(`${listing.pricetext} or ${listing.commercial_lease_price}`);
            if ( typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain ) {
              elementBlock.find('.md-prop-field-pricemain').html(`${listing.priceMain} or ${listing.commercial_lease_price}`);
            } else {
              elementBlock.find('.md-prop-field-pricemain').html('');
            }
          } else {
            if ( typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain ) {
              elementBlock.find('.md-prop-field-price').html(`${listing.priceMain} or ${listing.commercial_lease_price}`);
              elementBlock.find('.md-prop-field-pricemain').html(`${listing.priceMain} or ${listing.commercial_lease_price}`);
            } else {
              elementBlock.find('.md-prop-field-price').html('');
              elementBlock.find('.md-prop-field-pricemain').html('');
            }
          }
        } else {
          if ( typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext ) {
            elementBlock.find('.md-prop-field-price').html(listing.pricetext);
            if ( typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain ) {
              elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
            } else {
              elementBlock.find('.md-prop-field-pricemain').html('');
            }
          } else {
            if ( typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain ) {
              elementBlock.find('.md-prop-field-price').html(listing.priceMain);
              elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
            } else {
              elementBlock.find('.md-prop-field-price').html('');
              elementBlock.find('.md-prop-field-pricemain').html('');
            }
          }
        }
      }

      if (typeof listing.methodofsale !== 'undefined' && listing.methodofsale !== null && listing.methodofsale !== '') {
        if (listing.methodofsale === 'Private Treaty') {
          elementBlock.find('.md-prop-field-methodofsaledate').html('');
          elementBlock.find('.md-prop-field-methodofsaledate-2').html('');
        } else if (listing.methodofsale === 'Auction') {
          const dateToday = Date.parse(new Date().toString());

          let dateAuction = 0;
          if (typeof listing.auctiondateformat !== 'undefined' && listing.auctiondateformat !== null && listing.auctiondateformat !== '') {
            dateAuction = Date.parse(listing.auctiondateformat.toString());
          }

          if (dateToday <= dateAuction) {
            
            if ( typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext ) {
              elementBlock.find('.md-prop-field-price').html(''); /* Don't show price if the property is auction method of sale */
              elementBlock.find('.md-prop-field-price-alt').html(listing.pricetext);
              elementBlock.find('.md-prop-field-searchprice').html(listing.pricetext);
            } else {
              elementBlock.find('.md-prop-field-searchprice').html('Unless sold prior');
              elementBlock.find('.md-prop-field-price').html('Unless sold prior');
              elementBlock.find('.md-prop-field-price-alt').html('Unless sold prior');
            }
            let listingAuctionVenue = (typeof listing.auctionvenue !== 'undefined' && listing.auctionvenue != '') ? ', ' + listing.auctionvenue : "";
            elementBlock.find('.md-prop-field-methodofsaledate').html(moment(listing.auctiondateformat).format('dddd Do MMMM, h:mmA') + listingAuctionVenue);
            elementBlock.find('.md-prop-field-methodofsaledate-2').html(moment(listing.auctiondateformat).format('ha, ddd DD MMM YYYY') + listingAuctionVenue);
          } else {
            elementBlock.find('.md-prop-field-methodofsaledate').html('');
            elementBlock.find('.md-prop-field-methodofsaledate-2').html('');
            if (listing.priceonapplication) {
              if(listing.methodofsale !== 'Tender') {
                elementBlock.find('.md-prop-field-price').html('Price on Application');
              } else {
                elementBlock.find('.md-prop-field-price').html('');
              }
            } else {
              if(listing.listing_status !== 'settled' && listing.listing_status !== 'unconditional' && listing.listing_status !== 'settled,unconditional') {
                elementBlock.find('.md-prop-field-price').html('');
                elementBlock.find('.md-prop-field-price-alt').html('');
                elementBlock.find('.md-prop-field-searchprice').html('');
              }
             
            }
          }

        } else if (listing.methodofsale === 'Set Sale Date') {
          elementBlock.find('.md-prop-field-methodofsaledate').html(moment(listing.setSaleDateFormat).format('dddd Do MMMM, h:mmA'));
          elementBlock.find('.md-prop-field-methodofsaledate-2').html(moment(listing.setSaleDateFormat).format('ha, ddd DD MMM YYYY'));

          /* Hide price if property is based in New Zealand Deadline Sale (Set Sale Date) */
          if (isNZClientProperty) {
            elementBlock.find('.md-prop-field-price').html('');
          }
        } else {
          elementBlock.find('.md-prop-field-methodofsaledate').html(moment(listing.tenderdateformat).format('dddd Do MMMM, h:mmA'));
          elementBlock.find('.md-prop-field-methodofsaledate-2').html(moment(listing.tenderdateformat).format('ha, ddd DD MMM YYYY'));
        }
      } else {
          elementBlock.find('.md-prop-field-methodofsaledate').html('');
          elementBlock.find('.md-prop-field-methodofsaledate-2').html('');
      }

      /* Settled/Unconditional properties conditions */
      if (isSettledUnconditionalProperty) {
        elementBlock.find('.md-prop-field-methodofsaledate').html(moment(listing.unconditionalDate).format('dddd Do MMM, YYYY'));
      }

      if ( typeof listing.receptionrooms !== 'undefined' && listing.receptionrooms !== null && listing.receptionrooms ) {
        elementBlock.find('.md-prop-field-receptionrooms').html(listing.receptionrooms);
      } else {
        elementBlock.find('.md-prop-field-receptionrooms').html('');
      }

      if ( typeof listing.authority_end !== 'undefined' && listing.authority_end !== null && listing.authority_end ) {
        elementBlock.find('.md-prop-field-authorityend').html(listing.authority_end);
      } else {
        elementBlock.find('.md-prop-field-authorityend').html('');
      }
      
      if ( typeof listing.authority_start !== 'undefined' && listing.authority_start !== null && listing.authority_start ) {
        elementBlock.find('.md-prop-field-authoritystart').html(listing.authority_start);
      } else {
        elementBlock.find('.md-prop-field-authoritystart').html('');
      }

      if ( typeof listing.internal_remarks !== 'undefined' && listing.internal_remarks !== null && listing.internal_remarks ) {
        elementBlock.find('.md-prop-field-internalremarks').html(listing.internal_remarks);
      } else {
        elementBlock.find('.md-prop-field-internalremarks').html('');
      }

      if (typeof listing.hazards !== 'undefined' && listing.hazards) {
        let hazardList = [];

        listing.hazards.forEach(hazard => {
          hazardList.push(hazard.name);
        });

        elementBlock.find('.md-prop-field-hazards').html(hazardList.join(', '));
      } else {
        elementBlock.find('.md-prop-field-hazards').html('');
      }

      /* Merge Fields for Other Area */
      if ( (typeof listing.otherAreaValue !== 'undefined' && listing.otherAreaValue && listing.otherAreaValue !== '0') &&  
           (typeof listing.otherAreaUnit !== 'undefined' && listing.otherAreaUnit && listing.otherAreaUnit !== '') ){
        elementBlock.find('.md-prop-field-otherarea').html(`${listing.otherAreaValue}<small>${listing.otherAreaUnit}</small>`);
        elementBlock.find('.md-prop-field-otherarea-title').html(`<b>Other Area</b> <span>${listing.otherAreaValue}<small>${listing.otherAreaUnit}</small></span>`);
      } else {
        elementBlock.find('.md-prop-field-otherarea').html('');
        elementBlock.find('.md-prop-field-otherarea-title').html('');
      }
      
      /* Merge Fields for Front Page */
      if ( typeof listing.frontage !== 'undefined' &&  listing.frontage && listing.frontage !== '0' ) {
        elementBlock.find('.md-prop-field-frontage').html(listing.frontage);
        elementBlock.find('.md-prop-field-frontage-title').html(`<b>Frontage</b> <span>${listing.frontage}</span>`);
      } else {
        elementBlock.find('.md-prop-field-frontage').html('');
        elementBlock.find('.md-prop-field-frontage-title').html('');
      }

      /* Merge Fields for Outgoings $/annum */
      if ( typeof listing.outgoingsValue !== 'undefined' && listing.outgoingsValue && listing.outgoingsValue !== '0') {
        elementBlock.find('.md-prop-field-outgoings').html(`${listing.outgoingsValue}/annum`);
        elementBlock.find('.md-prop-field-outgoings-title').html(`<b>Total Outgoings</b> <span>${listing.outgoingsValue}/annum</span>`);
      } else {
        elementBlock.find('md-prop-field-outgoings').html('');
        elementBlock.find('.md-prop-field-outgoings-title').html('');
      } 

      /* Merge Fields for Outgoings Text */
      if ( typeof listing.outgoingsText !== 'undefined' &&  listing.outgoingsText ) {
        elementBlock.find('.md-prop-field-outgoingstext').html(`${listing.outgoingsText}/annum`);
        elementBlock.find('.md-prop-field-outgoingstext-title').html(`<b>Outgoing Details</b> <span>${listing.outgoingsText}/annum</span>`);
      } else {
        elementBlock.find('.md-prop-field-outgoingstext').html('');
        elementBlock.find('.md-prop-field-outgoingstext-title').html('');
      } 

      /* Merge Fields for Tenancy Details*/
      if ( typeof listing.tenancyDetailsValue !== 'undefined' &&  listing.tenancyDetailsValue ) {
        elementBlock.find('.md-prop-field-tenancydetails').html(listing.tenancyDetailsValue);
        elementBlock.find('.md-prop-field-tenancydetails-title').html(`<b>Tenancy Details</b> <span>${listing.tenancyDetailsValue}</span>`);
      } else {
        elementBlock.find('.md-prop-field-tenancydetails').html('');
        elementBlock.find('.md-prop-field-tenancydetails-title').html('');
      } 

      /* Merge Fields for Tenancy Name */
      if ( typeof listing.tenantName !== 'undefined' && listing.tenantName ) {
        elementBlock.find('.md-prop-field-tenantname').html(listing.tenantName);
        elementBlock.find('.md-prop-field-tenantname-title').html(`<b>Tenant Name</b> <span>${listing.tenantName}</span>`);
      } else {
        elementBlock.find('.md-prop-field-tenantname').html('');
        elementBlock.find('.md-prop-field-tenantname-title').html('');
      } 

      /* Merge Fields for Tenancy Email */
      if ( typeof listing.tenantEmail !== 'undefined' &&  listing.tenantEmail ) {
        elementBlock.find('.md-prop-field-tenantemail').html(listing.tenantEmail);
        elementBlock.find('.md-prop-field-tenantemail-title').html(`<b>Tenant Email</b> <span>${listing.tenantEmail}</span>`);
      } else {
        elementBlock.find('.md-prop-field-tenantemail').html('');
        elementBlock.find('.md-prop-field-tenantemail-title').html('');
      }
      

      /* Merge Fields for Tenancy Phone */
      if ( typeof listing.tenantPhone !== 'undefined' && listing.tenantPhone ) {
        elementBlock.find('.md-prop-field-tenantphone').html(listing.tenantPhone);
        elementBlock.find('.md-prop-field-tenantphone-title').html(`<b>Tenant Phone</b> <span>${listing.tenantPhone}</span>`);
      } else {
        elementBlock.find('.md-prop-field-tenantphone').html('');
        elementBlock.find('.md-prop-field-tenantphone-title').html('');
      }

      /* Appraisal logic:
        if displayPrice has value and not 0, then display display Price.
        if appraisal_min and appraisal_max is not undefined and has value, 
        populate the md-prop-field-price and md-prop-field-pricemain with price range
      */

      if (typeof listing.displayprice !== 'undefined' && listing.displayprice && listing.listing_status === 'appraisal') {
        elementBlock.find('.md-prop-field-price').html(listing.displayprice);
        elementBlock.find('.md-prop-field-pricemain').html(listing.displayprice);
      } else {
        if ((typeof listing.appraisal_min !== 'undefined' && listing.appraisal_min !== 0) &&
            (typeof listing.appraisal_max !== 'undefined' && listing.appraisal_max !== 0) && listing.listing_status === 'appraisal') {
              const appraisalMin = `${currency}${listing.appraisal_min}`;
              const appraisalMax = `${currency}${listing.appraisal_max}`;

              const priceRange = `${appraisalMin} - ${appraisalMax}`;
              elementBlock.find('.md-prop-field-price').html(priceRange);
              elementBlock.find('.md-prop-field-pricemain').html(priceRange);
        }
      }

      /* Merge Fields for Annual Return */
      if ( typeof listing.annualReturnPercent !== 'undefined' && listing.annualReturnPercent ) {
        elementBlock.find('.md-prop-field-annualreturn').html(`${listing.annualReturnPercent}%`);
        elementBlock.find('.md-prop-field-annualreturn-title').html(`<b>Annual Return</b> <span>${listing.annualReturnPercent}%</span>`);
      } else {
        elementBlock.find('.md-prop-field-annualreturn').html('');
        elementBlock.find('.md-prop-field-annualreturn-title').html('');
      }

      /*
        Merge Fields specifically for Commercial Category with Lease Type 
      */
      if (listing.listing_category === 'commercial' && listing.listing_status === 'lease') {
        if (typeof listing.commercialLeasePrice !== 'undefined' && listing.commercialLeasePrice && listing.displayprice === '') {
          elementBlock.find('.md-prop-field-price').html(listing.commercialLeasePrice);
        }
      }
      /* End of Merge Fields for Commercial Lease */

      if (typeof listing.tenancy !== 'undefined' && listing.tenancy) {
        if (typeof listing.tenancy.tenant_name !== 'undefined' && listing.tenancy.tenant_name) {
          elementBlock.find('.md-prop-field-tenant-name').html(listing.tenancy.tenant_name);
          elementBlock.find('.md-prop-field-tenant-name-title').html(`<b>Tenant Name</b> <span>${listing.tenancy.tenant_name}</span>`);
        } else {
          elementBlock.find('.md-prop-field-tenant-name').html('');
          elementBlock.find('.md-prop-field-tenant-name-title').html('');
        }

        if (typeof listing.tenancy.tenant_phone !== 'undefined' && listing.tenancy.tenant_phone) {
          elementBlock.find('.md-prop-field-tenant-phone').html(listing.tenancy.tenant_phone);
          elementBlock.find('.md-prop-field-tenant-phone-title').html(`<b>Tenant Phone</b> <span>${listing.tenancy.tenant_phone}</span>`);
        } else {
          elementBlock.find('.md-prop-field-tenant-phone').html('');
          elementBlock.find('.md-prop-field-tenant-phone-title').html('');
        }

        if (typeof listing.tenancy.tenant_email !== 'undefined' && listing.tenancy.tenant_email) {
          elementBlock.find('.md-prop-field-tenant-email').html(listing.tenancy.tenant_email);
          elementBlock.find('.md-prop-field-tenant-email-title').html(`<b>Tenant Email</b> <span>${listing.tenancy.tenant_email}</span>`);
        } else {
          elementBlock.find('.md-prop-field-tenant-email').html('');
          elementBlock.find('.md-prop-field-tenant-email-title').html('');
        }

        if (typeof listing.tenancy.tenancy_start !== 'undefined' && listing.tenancy.tenancy_start) {
          elementBlock.find('.md-prop-field-tenancy-start').html(moment(listing.tenancy.tenancy_start).format('dddd Do MMMM'));
          elementBlock.find('.md-prop-field-tenancy-start-title').html(`<b>Tenancy Start</b> <span>${moment(listing.tenancy.tenancy_start).format('dddd Do MMMM')}</span>`);
        } else {
          elementBlock.find('.md-prop-field-tenancy-start').html('');
          elementBlock.find('.md-prop-field-tenancy-start-title').html('');
        }

        if (typeof listing.tenancy.tenancy_stop !== 'undefined' && listing.tenancy.tenancy_stop) {
          elementBlock.find('.md-prop-field-tenancy-end').html(moment(listing.tenancy.tenancy_stop).format('dddd Do MMMM'));
          elementBlock.find('.md-prop-field-tenancy-end-title').html(`<b>Tenancy Ending</b> <span>${moment(listing.tenancy.tenancy_stop).format('dddd Do MMMM')}</span>`);
        } else {
          elementBlock.find('.md-prop-field-tenancy-end').html('');
          elementBlock.find('.md-prop-field-tenancy-end-title').html('');
        }

        if (typeof listing.tenancy.tenancy_details !== 'undefined' && listing.tenancy.tenancy_details) {
          elementBlock.find('.md-prop-field-tenancy-details').html(listing.tenancy.tenancy_details);
          elementBlock.find('.md-prop-field-tenancy-details-title').html(`<b>Tenancy Details</b> <span>${listing.tenancy.tenancy_details}</span>`);
        } else {
          elementBlock.find('.md-prop-field-tenancy-details').html('');
          elementBlock.find('.md-prop-field-tenancy-details-title').html('');
        }
      }


    } catch (err) {
      console.error(err);
    }

    return elementBlock;
  }

  fillMergeText(auth: Auth, html: string, type = 'replicate', content = 'element', data: any) {
    const clientSetting = auth.client;
    const groupSetting = clientSetting.group.master;
    const templateAgent = typeof data.user !== 'undefined' && data.user ? data.user : auth;
    const isMergedSaved = typeof data.isMergedSaved !== 'undefined' ? data.isMergedSaved : false;
    const fillType = data.fillType;
    
    let elementHtml = html;
    let tempObj = $('<div>' + elementHtml + '</div>');
    const customWrapper = tempObj.find('#customWrapper').html();
    /* Remove custom wrapper if it is existing */
    if (typeof customWrapper !== 'undefined' && customWrapper !== false) {
      tempObj = tempObj.find('#customWrapper').unwrap();
    }

    const elementObj = tempObj;
    
    if (type === 'subtitute') {
      elementObj.find("a[data-url*='{$']").each(function () {
        const hrefValue = $(this).attr('data-url').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');

        if (fillType === 'template') {
          if (hrefValue.includes('{%REFID%}')) {
            return;
          }

          if (hrefValue === '{%VIEWLINKURL%}' || hrefValue === '{%VIEWLINKSHORTURL%}' || hrefValue === '{%OFFERLINKURL%}'
            || hrefValue === '{%PRICEFINDERURL%}' || hrefValue === '{%ETABLEURL%}') {
            return;
          }

          const skipMergeFields = [
            'mailto:{%USEREMAIL%}',
            'tel:{%USERMOBILE%}',
            '{%USEREMAIL%}',
            '{%OFFICEEMAIL%}',
            '{%USERPHONE%}',
            '{%USERMOBILE%}',
            '{%USERWEBSITEURL%}',
            '{%WEBSITEURL%}',
            '{%SHORTWEBSITEURL%}',
            '{%FACEBOOKURL%}',
            '{%TWITTERURL%}',
            '{%INSTAGRAMURL%}',
            '{%YOUTUBEURL%}',
            '{%LINKEDINURL%}',
            '{%PINTERESTURL%}',
            '{%GPLUSURL%}',
            '{%RATEMYAGENT%}',
            '{%GOOGLEREVIEWURL%}',
            '{%BUYURL%}',
            '{%SELLURL%}',
            '{%RENTURL%}',
            '{%HOMEURL%}',
            '{%ABOUTURL%}',
            '{%CAREERSURL%}',
            '{%CONTACTURL%}',
            '{%BLOGURL%}'
          ];

          if (content === 'template' && isMergedSaved && skipMergeFields.includes(hrefValue.trim())) {
            return;
          }
        }

        if (!$(this).data('updated')) {
          $(this).attr('href', hrefValue);
        }
      });

      elementObj.find("[data-alt*='{$']").each(function () {
        const altValue = $(this).attr('data-alt').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');
        $(this).attr('alt', altValue);
      });

      elementObj.find("[data-style*='{$']").each(function () {
        const styleValue = $(this).attr('data-style').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');
        $(this).attr('style', styleValue);
      });

      elementObj.find("[data-bgcolor*='{$']").each(function () {
        const bgcolorValue = $(this).attr('data-bgcolor').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');
        $(this).attr('bgcolor', bgcolorValue);
      });

      elementObj.find("[bgcolor='#']").each(function () {
        let bgColorAttr = $(this).attr('bgcolor');
        if (bgColorAttr === '#') {
          let bgColor = this.defaultColor5; // WB-3474: default email background color. target element bgcolor that has # only value.
          if (clientSetting.color_5 && clientSetting.color_5 !== '#') {
            bgColor = clientSetting.color_5;
          } else {
            if (groupSetting !== null && groupSetting !== undefined) {
              bgColor = groupSetting.color_5;
            }
          }
          $(this).attr('bgcolor', bgColor);
        }
      });

      elementObj.find("img[data-src*='{$']").each(function () {
        const imgValue = $(this).attr('data-src').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');
        const imgSrc = $(this).attr('src').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');
        if (fillType === 'template') {
          if (imgValue.includes('{%REFID%}')) {
            return;
          }

          const skipMergeFields = ['{%PRIMARYLOGO%}', '{%SECONDARYLOGO%}', '{%USERPHOTO%}'];

          if (content === 'template' && isMergedSaved && skipMergeFields.includes(imgValue.trim())) {
            return;
          }
          if (imgValue.includes('{%VIEWLINKURL%}')) {
            if (!imgSrc.includes('{%VIEWLINKURL%}')) {
              return;
            }
          }
        }

        $(this).attr('src', imgValue);
      });

      elementObj.find("[data-text*='{$']").each(function () {
        const textValue = $(this).attr('data-text').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');

        if (fillType === 'template') {
          if (textValue.includes('{%REFID%}')) {
            return;
          }

          if (textValue === '{%VIEWLINKURL%}' || textValue === '{%VIEWLINKSHORTURL%}' || textValue === '{%OFFERLINKURL%}'
            || textValue === '{%PRICEFINDERURL%}' || textValue === '{%ETABLEURL%}') {
            return;
          }

          const skipMergeFields = [
            '{%USERFNAME%} {%USERLNAME%}',
            '{%USERID%}',
            '{%USEREMAIL%}',
            '{%USERFAX%}',
            '{%USERFNAME%}',
            '{%USERLNAME%}',
            '{%USERNAME%}',
            '{%USERPHOTO%}',
            '{%USERPHONE%}',
            '{%USERMOBILE%}',
            '{%USERPOSITION%}',
            '{%USERWEBSITEURL%}',
            '{%OFFICEID%}',
            '{%OFFICENAME%}',
            '{%OFFICEBUSINESS%}',
            '{%OFFICEBRANCH%}',
            '{%OFFICEPHONE%}',
            '{%OFFICEEMAIL%}',
            '{%OFFICEFAX%}',
            '{%OFFICESTREET%}',
            '{%OFFICESUBURB%}',
            '{%OFFICESTATE%}',
            '{%OFFICEPOSTCODE%}',
            '{%OFFICEABN%}',
            '{%WEBSITEURL%}',
            '{%SHORTWEBSITEURL%}',
            '{%FACEBOOKURL%}',
            '{%TWITTERURL%}',
            '{%INSTAGRAMURL%}',
            '{%YOUTUBEURL%}',
            '{%LINKEDINURL%}',
            '{%PINTERESTURL%}',
            '{%GPLUSURL%}',
            '{%RATEMYAGENT%}',
            '{%GOOGLEREVIEWURL%}',
            '{%BUYURL%}',
            '{%SELLURL%}',
            '{%RENTURL%}',
            '{%HOMEURL%}',
            '{%ABOUTURL%}',
            '{%CAREERSURL%}',
            '{%CONTACTURL%}',
            '{%BLOGURL%}',
          ];

          if (content === 'template' && isMergedSaved && skipMergeFields.includes(textValue.trim())) {
            return;
          }
        }

        $(this).html(textValue);
      });

      elementObj.find("iframe[data-src*='{$']").each(function () {
        const srcValue = $(this).attr('data-src').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');
        $(this).attr('src', srcValue);
      });
    } else {
      elementObj.find("a[href*='{%']").each(function () {
        const hrefValue = $(this).attr('href').replace(/\{%/gi, '{$').replace(/%\}/gi, '$}');

        $(this).attr('data-url', hrefValue);
      });

      elementObj.find("[style*='{%']").each(function () {
        const styleValue = $(this).attr('style').replace(/\{%/gi, '{$').replace(/%\}/gi, '$}');
        $(this).attr('data-style', styleValue);
      });

      elementObj.find("[bgcolor*='{%']").each(function () {
        const bgcolorValue = $(this).attr('bgcolor').replace(/\{%/gi, '{$').replace(/%\}/gi, '$}');
        $(this).attr('data-bgcolor', bgcolorValue);
      });

      elementObj.find("img[src*='{%']").each(function () {
        const imgValue = $(this).attr('src').replace(/\{%/gi, '{$').replace(/%\}/gi, '$}');
        $(this).attr('data-src', imgValue);
      });

      elementObj.find("iframe[src*='{%']").each(function () {
        const srcValue = $(this).attr('src').replace(/\{%/gi, '{$').replace(/%\}/gi, '$}');
        $(this).attr('data-src', srcValue);
      });

      elementObj.find(":not(:has(*)):contains('{%')").each(function () {
        const textValue = $(this).html().replace(/\{%/gi, '{$').replace(/%\}/gi, '$}');
        $(this).attr('data-text', textValue);
        // return false;
      });
    }

    elementObj.find('[data-cke-saved-href]').each(function () {
      $(this).removeAttr('data-cke-saved-href');
    });

    if (this.featureFlagsService.checkUser('bayleys', auth)) {
      let setting = clientSetting ? clientSetting : groupSetting;
      let hasOfficeSocialsClass = (elementObj.find('.dsgnly-office-socials').length > 0) ? true : false;
      if (hasOfficeSocialsClass) {
        /* It only grab social media icons under special class for bayleys social icons */
        elementObj.find('.dsgnly-office-socials [data-social-media]').each(function () {
          let socialMedia = $(this).attr('data-social-media');
          let socialMediaUrl = setting[`link_${socialMedia}`] != '' ? setting[`link_${socialMedia}`] : '';
          /* No social media url value */
          if (socialMediaUrl == '') {
            /* Remove redirection action for this anchor tag */
            // $(elementObj).find(`.dsgnly-office-socials [data-social-media=${socialMedia}] a.social_media_anchor`).attr('href', '#!');
            /* Remove social media element */
            $(elementObj).find(`.dsgnly-office-socials [data-social-media=${socialMedia}]`).remove();
          }
        });
      }
    }

    elementHtml = elementObj.html();

    // Non-Class User - Merge Field
    const template_agent_id = templateAgent.id ? templateAgent.id : '';
    elementHtml = elementHtml.replace(/\{%USERID%\}/gi, template_agent_id);

    const template_agent_email = templateAgent.email ? templateAgent.email : '';
    elementHtml = elementHtml.replace(/\{%USEREMAIL%\}/gi, template_agent_email);

    const template_agent_fax = templateAgent.fax ? templateAgent.fax : '';
    elementHtml = elementHtml.replace(/\{%USERFAX%\}/gi, template_agent_fax);

    const template_agent_firstname = templateAgent.firstname ? templateAgent.firstname : '';
    elementHtml = elementHtml.replace(/\{%USERFNAME%\}/gi, template_agent_firstname);

    const template_agent_lastname = templateAgent.lastname ? templateAgent.lastname : '';
    elementHtml = elementHtml.replace(/\{%USERLNAME%\}/gi, template_agent_lastname);

    if (typeof templateAgent.firstname === 'undefined' && typeof templateAgent.lastname === 'undefined') {
      if (typeof data.webbook_owner !== 'undefined' && data.webbook_owner) {
        elementHtml = elementHtml.replace(/\{%USERNAME%\}/gi, data.webbook_owner.firstname + ' ' + data.webbook_owner.lastname);
      } else {
        elementHtml = elementHtml.replace(/\{%USERNAME%\}/gi, template_agent_firstname + ' ' + template_agent_lastname);
      }
    } else {
      elementHtml = elementHtml.replace(/\{%USERNAME%\}/gi, template_agent_firstname + ' ' + template_agent_lastname);
    }

    const template_agent_imageurl = templateAgent.imageurl ? templateAgent.imageurl : '';
    if (template_agent_imageurl) {
      elementHtml = elementHtml.replace(/\{%USERPHOTO%\}/gi, template_agent_imageurl);
    } else {
      if (data.webbook_owner !== null && typeof data.webbook_owner !== 'undefined') {
        elementHtml = elementHtml.replace(/\{%USERPHOTO%\}/gi, data.webbook_owner.photo);
      }
    }

    const template_agent_telephone = templateAgent.telephone ? templateAgent.telephone : '';
    elementHtml = elementHtml.replace(/\{%USERPHONE%\}/gi, template_agent_telephone);

    const template_agent_position = templateAgent.position ? templateAgent.position : '';
    elementHtml = elementHtml.replace(/\{%USERPOSITION%\}/gi, template_agent_position);

    let template_agent_mobiledisplay = templateAgent.mobile ? templateAgent.mobile : '';
    template_agent_mobiledisplay = template_agent_mobiledisplay.replace('+61', '').replace('+64', '').trim();
    if (template_agent_mobiledisplay && template_agent_mobiledisplay.charAt(0) !== '0') {
      template_agent_mobiledisplay = '0' + template_agent_mobiledisplay;
    }
    elementHtml = elementHtml.replace(/\{%USERMOBILE%\}/gi, template_agent_mobiledisplay);

    const template_agent_url = templateAgent.website_url ? templateAgent.website_url : '';
    elementHtml = elementHtml.replace(/\{%USERWEBSITEURL%\}/gi, template_agent_url);

    // Non-Class Office Details - Merge Field
    elementHtml = elementHtml.replace(/\{%OFFICEID%\}/gi, auth.groupid);
    elementHtml = elementHtml.replace(/\{%DATE%\}/gi, moment().format('Do, MMM YYYY'));
    /* NEWDATE - New format of today's date for Bayleys  */
    elementHtml = elementHtml.replace(/\{%NEWDATE%\}/gi, moment().format('DD.MM.YYYY'));
    elementHtml = elementHtml.replace(/\{%DATEALT%\}/gi, moment().format('MMM YYYY'));

    if (clientSetting.name) {
      elementHtml = elementHtml.replace(/\{%OFFICENAME%\}/gi, clientSetting.name);
    } else {
      elementHtml = elementHtml.replace(/\{%OFFICENAME%\}/gi, '');
    }
    if (clientSetting.abn) {
      elementHtml = elementHtml.replace(/\{%OFFICEABN%\}/gi, clientSetting.abn);
    } else {
      elementHtml = elementHtml.replace(/\{%OFFICEABN%\}/gi, '');
    }

    /* 
      The logic here is that the Company Name uses the frachise(franchise_name) field and not the usual company_name
    */
    if (clientSetting.franchise_name) {
      elementHtml = elementHtml.replace(/\{%OFFICEBUSINESS%\}/gi, clientSetting.franchise_name);
    } else {
      elementHtml = elementHtml.replace(/\{%OFFICEBUSINESS%\}/gi, '');
    }

    
    if (clientSetting.branch_name) {
      elementHtml = elementHtml.replace(/\{%OFFICEBRANCH%\}/gi, clientSetting.branch_name);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%OFFICEBRANCH%\}/gi, groupSetting.branch_name);
      }
    }

    if (clientSetting.phone) {
      elementHtml = elementHtml.replace(/\{%OFFICEPHONE%\}/gi, clientSetting.phone);
    } else {
      elementHtml = elementHtml.replace(/\{%OFFICEPHONE%\}/gi, '');
    }
    if (clientSetting.email) {
      elementHtml = elementHtml.replace(/\{%OFFICEEMAIL%\}/gi, clientSetting.email);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%OFFICEEMAIL%\}/gi, groupSetting.email);
      }
    }
    if (clientSetting.fax) {
      elementHtml = elementHtml.replace(/\{%OFFICEFAX%\}/gi, clientSetting.fax);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%OFFICEFAX%\}/gi, groupSetting.fax);
      }
    }
    if (clientSetting.street) {
      elementHtml = elementHtml.replace(/\{%OFFICESTREET%\}/gi, clientSetting.street);
    } else {
      elementHtml = elementHtml.replace(/\{%OFFICESTREET%\}/gi, '');
    }
    if (clientSetting.suburb) {
      elementHtml = elementHtml.replace(/\{%OFFICESUBURB%\}/gi, clientSetting.suburb);
    } else {
      elementHtml = elementHtml.replace(/\{%OFFICESUBURB%\}/gi, '');
    }
    if (clientSetting.state_abbr) {
      elementHtml = elementHtml.replace(/\{%OFFICESTATE%\}/gi, clientSetting.state_abbr);
    } else {
      if (typeof groupSetting !== 'undefined' && groupSetting !== null) {
        elementHtml = elementHtml.replace(/\{%OFFICESTATE%\}/gi, groupSetting.state_abbr);
      } else {
        elementHtml = elementHtml.replace(/\{%OFFICESTATE%\}/gi, '');
      }
    }
    if (clientSetting.postcode) {
      elementHtml = elementHtml.replace(/\{%OFFICEPOSTCODE%\}/gi, clientSetting.postcode);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%OFFICEPOSTCODE%\}/gi, groupSetting.postcode);
      }
    }

    if (clientSetting.postal_postcode) {
      elementHtml = elementHtml.replace(/\{%POSTALPOSTCODE%\}/gi, clientSetting.postal_postcode);
    } else {
      elementHtml = elementHtml.replace(/\{%POSTALPOSTCODE%\}/gi, '');
    }

    if (clientSetting.postal_state) {
      elementHtml = elementHtml.replace(/\{%POSTALSTATE%\}/gi, clientSetting.postal_state);
    } else {
      elementHtml = elementHtml.replace(/\{%POSTALSTATE%\}/gi, '');
    }

    if (clientSetting.postal_street) {
      elementHtml = elementHtml.replace(/\{%POSTALSTREET%\}/gi, clientSetting.postal_street);
    } else {
      elementHtml = elementHtml.replace(/\{%POSTALSTREET%\}/gi, '');
    }

    if (clientSetting.postal_suburb) {
      elementHtml = elementHtml.replace(/\{%POSTALSUBURB%\}/gi, clientSetting.postal_suburb);
    } else {
      elementHtml = elementHtml.replace(/\{%POSTALSUBURB%\}/gi, '');
    }

    // Non-Class Setting - Merge Field
    if (clientSetting.color_1 && clientSetting.color_1 !== '#') {
      elementHtml = elementHtml.replace(/\{%COLOR1%\}/gi, clientSetting.color_1);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%COLOR1%\}/gi, groupSetting.color_1);
      }
    }
    if (clientSetting.color_2 && clientSetting.color_2 !== '#') {
      elementHtml = elementHtml.replace(/\{%COLOR2%\}/gi, clientSetting.color_2);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%COLOR2%\}/gi, groupSetting.color_2);
      }
    }
    if (clientSetting.color_3 && clientSetting.color_3 !== '#') {
      elementHtml = elementHtml.replace(/\{%COLOR3%\}/gi, clientSetting.color_3);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%COLOR3%\}/gi, groupSetting.color_3);
      }
    }
    if (clientSetting.color_4 && clientSetting.color_4 !== '#') {
      elementHtml = elementHtml.replace(/\{%COLOR4%\}/gi, clientSetting.color_4);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%COLOR4%\}/gi, groupSetting.color_4);
      }
    }
    if (clientSetting.color_5 && clientSetting.color_5 !== '#') {
      elementHtml = elementHtml.replace(/\{%COLOR5%\}/gi, clientSetting.color_5);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        if (groupSetting.color_5) {
          elementHtml = elementHtml.replace(/\{%COLOR5%\}/gi, groupSetting.color_5);
        } else {
          elementHtml = elementHtml.replace(/\{%COLOR5%\}/gi, this.defaultColor5); // WB-3474: default email background color. target element bgcolor that has # only value.
        }
      }
    }
    if (clientSetting.color_6 && clientSetting.color_6 !== '#') {
      elementHtml = elementHtml.replace(/\{%FONTCOLOR1%\}/gi, clientSetting.color_6);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%FONTCOLOR1%\}/gi, groupSetting.color_6);
      }
    }
    if (clientSetting.color_7 && clientSetting.color_7 !== '#') {
      elementHtml = elementHtml.replace(/\{%FONTCOLOR2%\}/gi, clientSetting.color_7);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%FONTCOLOR2%\}/gi, groupSetting.color_7);
      }
    }
    if (clientSetting.color_8 && clientSetting.color_8 !== '#') {
      elementHtml = elementHtml.replace(/\{%ICONCOLOR1%\}/gi, clientSetting.color_8);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%ICONCOLOR1%\}/gi, groupSetting.color_8);
      }
    }

    if (clientSetting.color_9 && clientSetting.color_9 !== '#') {
      elementHtml = elementHtml.replace(/\{%FONTCOLOR3%\}/gi, clientSetting.color_9);
      elementHtml = elementHtml.replace(/\{%FONTCOLOR9%\}/gi, clientSetting.color_9);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%FONTCOLOR3%\}/gi, groupSetting.color_9);
        elementHtml = elementHtml.replace(/\{%FONTCOLOR9%\}/gi, groupSetting.color_9);
      }
    }

    if (clientSetting.color_10 && clientSetting.color_10 !== '#') {
      elementHtml = elementHtml.replace(/\{%FONTCOLOR4%\}/gi, clientSetting.color_10);
      elementHtml = elementHtml.replace(/\{%FONTCOLOR10%\}/gi, clientSetting.color_10);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%FONTCOLOR4%\}/gi, groupSetting.color_10);
        elementHtml = elementHtml.replace(/\{%FONTCOLOR10%\}/gi, groupSetting.color_10);
      }
    }
    if (clientSetting.font_family) {
      elementHtml = elementHtml.replace(/\{%FONTFAMILY%\}/gi, clientSetting.font_family);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%FONTFAMILY%\}/gi, groupSetting.font_family);
      }
    }

    // low res logo
    if (clientSetting.primary_logo) {
      elementHtml = elementHtml.replace(/\{%PRIMARYLOGO%\}/gi, clientSetting.primary_logo);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%PRIMARYLOGO%\}/gi, groupSetting.primary_logo);
      }
    }
    if (clientSetting.secondary_logo) {
      elementHtml = elementHtml.replace(/\{%SECONDARYLOGO%\}/gi, clientSetting.secondary_logo);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%SECONDARYLOGO%\}/gi, groupSetting.secondary_logo);
      }
    }

    // high res logo
    if (clientSetting.hires_primary_logo) {
      elementHtml = elementHtml.replace(/\{%PRIMARYLOGOHIRES%\}/gi, clientSetting.hires_primary_logo);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%PRIMARYLOGOHIRES%\}/gi, groupSetting.hires_primary_logo);
      }
    }
    if (clientSetting.hires_secondary_logo) {
      elementHtml = elementHtml.replace(/\{%SECONDARYLOGOHIRES%\}/gi, clientSetting.hires_secondary_logo);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%SECONDARYLOGOHIRES%\}/gi, groupSetting.hires_secondary_logo);
      }
    }

    if (clientSetting.link_facebook) {
      elementHtml = elementHtml.replace(/\{%FACEBOOKURL%\}/gi, clientSetting.link_facebook);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%FACEBOOKURL%\}/gi, groupSetting.link_facebook);
      }
    }
    if (clientSetting.link_twitter) {
      elementHtml = elementHtml.replace(/\{%TWITTERURL%\}/gi, clientSetting.link_twitter);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%TWITTERURL%\}/gi, groupSetting.link_twitter);
      }
    }
    if (clientSetting.link_instagram) {
      elementHtml = elementHtml.replace(/\{%INSTAGRAMURL%\}/gi, clientSetting.link_instagram);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%INSTAGRAMURL%\}/gi, groupSetting.link_instagram);
      }
    }
    if (clientSetting.link_youtube) {
      elementHtml = elementHtml.replace(/\{%YOUTUBEURL%\}/gi, clientSetting.link_youtube);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%YOUTUBEURL%\}/gi, groupSetting.link_youtube);
      }
    }
    if (clientSetting.link_linkedin) {
      elementHtml = elementHtml.replace(/\{%LINKEDINURL%\}/gi, clientSetting.link_linkedin);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%LINKEDINURL%\}/gi, groupSetting.link_linkedin);
      }
    }
    if (clientSetting.link_gplus) {
      elementHtml = elementHtml.replace(/\{%GPLUSURL%\}/gi, clientSetting.link_gplus);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%GPLUSURL%\}/gi, groupSetting.link_gplus);
      }
    }
    if (clientSetting.link_pinterest) {
      elementHtml = elementHtml.replace(/\{%PINTERESTURL%\}/gi, clientSetting.link_pinterest);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%PINTERESTURL%\}/gi, groupSetting.link_pinterest);
      }
    }

    if (clientSetting.link_ratemyagent) {
      elementHtml = elementHtml.replace(/\{%RATEMYAGENT%\}/gi, clientSetting.link_ratemyagent);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%RATEMYAGENT%\}/gi, groupSetting.link_ratemyagent);
      }
    }

    if (clientSetting.link_googlereviews) {
      elementHtml = elementHtml.replace(/\{%GOOGLEREVIEWURL%\}/gi, clientSetting.link_googlereviews);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%GOOGLEREVIEWURL%\}/gi, groupSetting.link_googlereviews);
      }
    }

    if (clientSetting.link_website) {
      elementHtml = elementHtml.replace(/\{%WEBSITEURL%\}/gi, clientSetting.link_website);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%WEBSITEURL%\}/gi, groupSetting.link_website);
      }
    }

    if (clientSetting.link_short_website) {
      elementHtml = elementHtml.replace(/\{%SHORTWEBSITEURL%\}/gi, clientSetting.link_short_website);
    } else {
      if (clientSetting.link_website) {
        elementHtml = elementHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, clientSetting.link_website);
      } else {
        if ((groupSetting !== null && groupSetting !== undefined)) {
          if (groupSetting.link_short_website) {
            elementHtml = elementHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, groupSetting.link_short_website);
          } else {
            elementHtml = elementHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, '');
          }
        } else {
          elementHtml = elementHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, '');
        }
      }
    }

    if (clientSetting.link_buy) {
      elementHtml = elementHtml.replace(/\{%BUYURL%\}/gi, clientSetting.link_buy);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%BUYURL%\}/gi, groupSetting.link_buy);
      }
    }
    if (clientSetting.link_sell) {
      elementHtml = elementHtml.replace(/\{%SELLURL%\}/gi, clientSetting.link_sell);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%SELLURL%\}/gi, groupSetting.link_sell);
      }
    }
    if (clientSetting.link_rent) {
      elementHtml = elementHtml.replace(/\{%RENTURL%\}/gi, clientSetting.link_rent);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%RENTURL%\}/gi, groupSetting.link_rent);
      }
    }
    if (clientSetting.link_unsubscribe) {
      elementHtml = elementHtml.replace(/\{%UNSUBSCRIBEURL%\}/gi, clientSetting.link_unsubscribe);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%UNSUBSCRIBEURL%\}/gi, groupSetting.link_unsubscribe);
      }
    }
    if (clientSetting.link_home) {
      elementHtml = elementHtml.replace(/\{%HOMEURL%\}/gi, clientSetting.link_home);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%HOMEURL%\}/gi, groupSetting.link_home);
      }
    }
    if (clientSetting.link_about) {
      elementHtml = elementHtml.replace(/\{%ABOUTURL%\}/gi, clientSetting.link_about);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%ABOUTURL%\}/gi, groupSetting.link_about);
      }
    }
    if (clientSetting.link_careers) {
      elementHtml = elementHtml.replace(/\{%CAREERSURL%\}/gi, clientSetting.link_careers);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%CAREERSURL%\}/gi, groupSetting.link_careers);
      }
    }
    if (clientSetting.link_blog) {
      elementHtml = elementHtml.replace(/\{%BLOGURL%\}/gi, clientSetting.link_blog);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%BLOGURL%\}/gi, groupSetting.link_blog);
      }
    }
    if (clientSetting.link_contact) {
      elementHtml = elementHtml.replace(/\{%CONTACTURL%\}/gi, clientSetting.link_contact);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%CONTACTURL%\}/gi, groupSetting.link_contact);
      }
    }
    if (clientSetting.link_testimonials) {
      elementHtml = elementHtml.replace(/\{%TESTIMONIALSURL%\}/gi, clientSetting.link_testimonials);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%TESTIMONIALSURL%\}/gi, groupSetting.link_testimonials);
      }
    }
    if (clientSetting.primary_icon_bed) {
      elementHtml = elementHtml.replace(/\{%PRIMARYICONBED%\}/gi, clientSetting.primary_icon_bed);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%PRIMARYICONBED%\}/gi, groupSetting.primary_icon_bed);
      }
    }
    if (clientSetting.primary_icon_bath) {
      elementHtml = elementHtml.replace(/\{%PRIMARYICONBATH%\}/gi, clientSetting.primary_icon_bath);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%PRIMARYICONBATH%\}/gi, groupSetting.primary_icon_bath);
      }
    }
    if (clientSetting.primary_icon_car) {
      elementHtml = elementHtml.replace(/\{%PRIMARYICONCAR%\}/gi, clientSetting.primary_icon_car);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%PRIMARYICONCAR%\}/gi, groupSetting.primary_icon_car);
      }
    }
    if (clientSetting.secondary_icon_bed) {
      elementHtml = elementHtml.replace(/\{%SECONDARYICONBED%\}/gi, clientSetting.secondary_icon_bed);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%SECONDARYICONBED%\}/gi, groupSetting.secondary_icon_bed);
      }
    }
    if (clientSetting.secondary_icon_bath) {
      elementHtml = elementHtml.replace(/\{%SECONDARYICONBATH%\}/gi, clientSetting.secondary_icon_bath);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%SECONDARYICONBATH%\}/gi, groupSetting.secondary_icon_bath);
      }
    }
    if (clientSetting.secondary_icon_car) {
      elementHtml = elementHtml.replace(/\{%SECONDARYICONCAR%\}/gi, clientSetting.secondary_icon_car);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%SECONDARYICONCAR%\}/gi, groupSetting.secondary_icon_car);
      }
    }
    if (clientSetting.disclaimer_email) {
      elementHtml = elementHtml.replace(/\{%DISCLAIMEREMAIL%\}/gi, clientSetting.disclaimer_email);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%DISCLAIMEREMAIL%\}/gi, groupSetting.disclaimer_email);
      }
    }
    if (clientSetting.disclaimer_print) {
      elementHtml = elementHtml.replace(/\{%DISCLAIMERPRINT%\}/gi, clientSetting.disclaimer_print);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%DISCLAIMERPRINT%\}/gi, groupSetting.disclaimer_print);
      } else {
        elementHtml = elementHtml.replace(/\{%DISCLAIMERPRINT%\}/gi, '');
      }
    }
    let legal_1 = '';
    let legal_2 = '';
    let legal_3 = '';

    if (clientSetting.legal_1 !== null) {
      legal_1 = clientSetting.legal_1;
    } else {
      if (groupSetting !== null && groupSetting !== undefined && groupSetting.legal_1 !== null) {
        legal_1 = clientSetting.legal_1;
      }
    }
    elementHtml = elementHtml.replace(/\{%LEGAL1%\}/gi, legal_1);

    if (clientSetting.legal_2 !== null) {
      legal_2 = clientSetting.legal_2;
    } else {
      if (groupSetting !== null && groupSetting !== undefined && groupSetting.legal_2 !== null) {
        legal_2 = clientSetting.legal_2;
      }
    }
    elementHtml = elementHtml.replace(/\{%LEGAL2%\}/gi, legal_2);

    if (clientSetting.legal_3 !== null) {
      legal_3 = clientSetting.legal_3;
    } else {
      if (groupSetting !== null && groupSetting !== undefined && groupSetting.legal_3 !== null) {
        legal_3 = clientSetting.legal_3;
      }
    }
    elementHtml = elementHtml.replace(/\{%LEGAL3%\}/gi, legal_3);

    if (fillType === 'template') {
      if (auth.provider === 'vaultre' || auth.provider === 'designly') {
        elementHtml = elementHtml.replace(/%GREETING%/gi, '$$$greeting$$$').replace(/%UNSUBSCRIBE%/gi, '$$$unsubscribe$$$');
      }

      const pricefinderUrl = data.pricefinderUrl;
      if (typeof pricefinderUrl !== 'undefined' && pricefinderUrl) {
        elementHtml = elementHtml.replace(/\{%PRICEFINDERURL%\}/ig, pricefinderUrl);
      }
    }

    if (fillType === 'webbooks') {
      let webbookPropertyAddress = '';
      let webbookStreetMergeField = '';
      let webbookSuburbMergeField = '';
      if (type === 'subtitute') {
        const unitnumber = elementObj.find('.dsgnly-property-map').attr('data-unitnumber');
        const streetnumber = elementObj.find('.dsgnly-property-map').attr('data-streetnumber');
        const street = elementObj.find('.dsgnly-property-map').attr('data-street');
        const suburb = elementObj.find('.dsgnly-property-map').attr('data-suburb');

        if (typeof unitnumber !== 'undefined' && unitnumber) {
          webbookPropertyAddress = unitnumber;
        }
        if (typeof streetnumber !== 'undefined' && streetnumber) {
          webbookPropertyAddress = `${webbookPropertyAddress} ${streetnumber}`;
        }
        if (typeof street !== 'undefined' && street) {
          webbookPropertyAddress = `${webbookPropertyAddress} ${street}`;
        }

        if (webbookPropertyAddress) {
          webbookStreetMergeField = `${unitnumber} ${streetnumber} ${street}`;
          if (typeof suburb !== 'undefined' && suburb) {
            webbookPropertyAddress = `${webbookPropertyAddress},%20${suburb}`;
            webbookSuburbMergeField = `${suburb}`;
          }
        } else {
          if (typeof suburb !== 'undefined' && suburb) {
            webbookPropertyAddress = `${suburb}`;
            webbookSuburbMergeField = `${suburb}`;
          }
        }

        if (!webbookPropertyAddress) {
          if (clientSetting.street) {
            webbookPropertyAddress = `${clientSetting.street}`;
            if (clientSetting.suburb) {
              webbookPropertyAddress = `${webbookPropertyAddress},%20${clientSetting.suburb}`;
            }
          } else {
            if (clientSetting.suburb) {
              webbookPropertyAddress = `${clientSetting.suburb}`;
            }
          }
        }

        elementHtml = elementHtml.replace(/\{%WEBBOOKPROPERTYSTREET%\}/gi, webbookStreetMergeField);
        elementHtml = elementHtml.replace(/\{%WEBBOOKPROPERTYSUBURB%\}/gi, webbookSuburbMergeField);
        elementHtml = elementHtml.replace(/\{%WEBBOOKPROPERTYADDRESS%\}/gi, webbookPropertyAddress);
      } else {
        if (!webbookPropertyAddress) {
          if (clientSetting.street) {
            webbookPropertyAddress = `${clientSetting.street}`;
            webbookStreetMergeField = `${clientSetting.street}`;
            if (clientSetting.suburb) {
              webbookPropertyAddress = `${webbookPropertyAddress},%20${clientSetting.suburb}`;
              webbookSuburbMergeField = `${clientSetting.suburb}`;
            }
          } else {
            if (clientSetting.suburb) {
              webbookPropertyAddress = `${clientSetting.suburb}`;
            }
          }
        }
        elementHtml = elementHtml.replace(/\{%WEBBOOKPROPERTYSTREET%\}/gi, webbookStreetMergeField);
        elementHtml = elementHtml.replace(/\{%WEBBOOKPROPERTYSUBURB%\}/gi, webbookSuburbMergeField);
        elementHtml = elementHtml.replace(/\{%WEBBOOKPROPERTYADDRESS%\}/gi, webbookPropertyAddress);
      }

    }

    if (fillType === 'proposals') {
      let proposalUnitNumber = '';
      let proposalStreetNumber = '';
      let proposalStreet = '';
      let proposalSuburb = '';
      let proposalPropertyAddress = '';
      let proposalStreetMergeField = '';
      let proposalSuburbMergeField = '';
      if (typeof data.proposalProperty !== 'undefined' && data.proposalProperty) {

        if (typeof data.proposalProperty.unit_number !== 'undefined' && data.proposalProperty.unit_number) {
          proposalUnitNumber = data.proposalProperty.unit_number;
          const tempProposalUnitNumber = data.proposalProperty.unit_number.split(',');
          if (tempProposalUnitNumber.length >= 2) {
            proposalUnitNumber = '';
          }
        }

        if (typeof data.proposalProperty.street_number !== 'undefined' && data.proposalProperty.street_number) {
          proposalStreetNumber = data.proposalProperty.street_number;
        }

        if (typeof data.proposalProperty.street !== 'undefined' && data.proposalProperty.street) {
          proposalStreet = data.proposalProperty.street;
        }

        if (typeof data.proposalProperty.suburb !== 'undefined' && data.proposalProperty.suburb) {
          proposalSuburb = data.proposalProperty.suburb;
        }

        switch (data.proposalProperty.address_visibility) {
          case 'suburb':
            proposalPropertyAddress = `${proposalSuburb}`;
            proposalSuburbMergeField =  `${proposalSuburb}`;
          break;
          case 'streetAndSuburb':
            proposalPropertyAddress = `${proposalStreet},%20${proposalSuburb}`;
            proposalStreetMergeField = `${proposalStreet}`;
            proposalSuburbMergeField = `${proposalSuburb}`;
          break;
          default: 
            proposalPropertyAddress = `${proposalUnitNumber} ${proposalStreetNumber} ${proposalStreet},%20${proposalSuburb}`;
            proposalStreetMergeField = `${proposalUnitNumber} ${proposalStreetNumber} ${proposalStreet}`;
            proposalSuburbMergeField = `${proposalSuburb}`;
        }
        if (proposalPropertyAddress) {
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYADDRESS%\}/gi, proposalPropertyAddress);
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSTREET%\}/gi, proposalStreetMergeField);
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSUBURB%\}/gi, proposalSuburbMergeField);
        } else {
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYADDRESS%\}/gi, '');
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSTREET%\}/gi, '');
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSUBURB%\}/gi, '');
        }
      } else {
        if (clientSetting.street) {
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSTREET%\}/gi, clientSetting.street);
          proposalPropertyAddress = `${clientSetting.street}`;
        } else {
          proposalPropertyAddress = '';
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSTREET%\}/gi, '');
        }

        if (clientSetting.suburb) {
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSUBURB%\}/gi, clientSetting.suburb);
          if (proposalPropertyAddress) {
            proposalPropertyAddress = `${proposalPropertyAddress},%20${clientSetting.suburb}`;
          } else {
            proposalPropertyAddress = `${clientSetting.suburb}`;
          }
        } else {
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSUBURB%\}/gi, '');
        }

        elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYADDRESS%\}/gi, proposalPropertyAddress);
      }
    }

    return elementHtml;
  }

  capitalizeFirstLetter(string: string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  getLink(item: any) {
    if (item.type === 'file') {
      return this.appConfig.S3_URL + item.path;
    }
  }

  fillAgentMergeClass(elementBlock: any, data: any, isGlobalMergeField: boolean = false) {
    if (typeof data.agentName !== 'undefined' && data.agentName) {
      elementBlock.find('.dsgnly-agent-name').html(data.agentName);
      if (isGlobalMergeField) {
        elementBlock.find('.md-prop-field-lname').html(data.agentName);
      }
    }

    if (typeof data.agentPosition !== 'undefined' && data.agentPosition) {
      elementBlock.find('.dsgnly-agent-position').html(data.agentPosition);
      if (isGlobalMergeField) {
        elementBlock.find('.md-prop-field-lposition').html(data.agentPosition);
      }
    }

    if (typeof data.agentPhone !== 'undefined' && data.agentPhone) {
      elementBlock.find('.dsgnly-agent-phone').html(data.agentPhone);
      if (isGlobalMergeField) {
        elementBlock.find('.md-prop-field-lphone').html(data.agentPhone);
      }
    }

    if (typeof data.agentMobile !== 'undefined' && data.agentMobile) {
      elementBlock.find('.dsgnly-agent-mobile').html(data.agentMobile);
      if (isGlobalMergeField) {
        elementBlock.find('.md-prop-field-lmobile').html(data.agentMobile);
      }
    }

    if (typeof data.agentEmail !== 'undefined' && data.agentEmail) {
      elementBlock.find('.dsgnly-agent-email').html(data.agentEmail);
      if (isGlobalMergeField) {
        elementBlock.find('.md-prop-field-lemail').html(data.agentEmail);
      }
    }

    if (typeof data.agentPhoto !== 'undefined' && data.agentPhoto) {
      elementBlock.find('img.dsgnly-agent-photo').attr('src', data.agentPhoto);
      if (isGlobalMergeField) {
        elementBlock.find('.md-prop-field-limage').attr('src', data.agentPhoto);
      }
    }
    if (typeof data.agentProfile !== 'undefined' && data.agentProfile) {
      const agentProfile = new DOMParser().parseFromString(data.agentProfile, 'text/html');
      elementBlock.find('.dsgnly-agent-profile').html(agentProfile.body.textContent || '');
      if (isGlobalMergeField) {
        elementBlock.find('.md-prop-field-lprofile').html(agentProfile.body.textContent || '');
      }
    }

    if (typeof data.id !== 'undefined' && data.id && data.id === 'regenerate') {
      elementBlock.find('section').removeAttr('id');
    }

    return elementBlock.html();
  }

  clearAgentMergeClass(elementBlock: any, data, isGlobalMergeField: boolean = false) {
      // default photo url from templates.
      let defaultPhoto = 'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/2354/WebBooks/Theme-Two/Images/image_agent_photo_large.svg';
      elementBlock.find('.dsgnly-agent-name').html(data.agentName);
      if (isGlobalMergeField) {
        elementBlock.find('.md-prop-field-lname').html(data.agentName);
      }
    
      elementBlock.find('.dsgnly-agent-position').html(data.agentPosition);
      if (isGlobalMergeField) {
        elementBlock.find('.md-prop-field-lposition').html(data.agentPosition);
      }
    
      elementBlock.find('.dsgnly-agent-phone').html(data.agentPhone);
    
      elementBlock.find('.dsgnly-agent-mobile').html(data.agentMobile);
      if (isGlobalMergeField) {
        elementBlock.find('.md-prop-field-lmobile').html(data.agentMobile);
      }
    
      elementBlock.find('.dsgnly-agent-email').html(data.agentEmail);
      if (isGlobalMergeField) {
        elementBlock.find('.md-prop-field-lemail').html(data.agentEmail);
      }

      elementBlock.find('img.dsgnly-agent-photo').attr('src', defaultPhoto);
      if (isGlobalMergeField) {
        elementBlock.find('.md-prop-field-limage').attr('src', defaultPhoto);
      }
    
      const agentProfile = new DOMParser().parseFromString(data.agentProfile, 'text/html');
      elementBlock.find('.dsgnly-agent-profile').html(agentProfile.body.textContent || '');
      if (isGlobalMergeField) {
        elementBlock.find('.md-prop-field-lprofile').html(agentProfile.body.textContent || '');
      }

      return elementBlock.html();
  }

}
