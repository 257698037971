import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Auth } from '../../../models/auth.model';
import { ClientService } from '../../../services/client.service';
import { FrontService } from '../../front.service';

import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import swal from 'sweetalert2';

@Component({
	selector: 'app-settings-integrations',
	templateUrl: './settings-integrations.component.html',
  styleUrls: ['./settings-integrations.component.scss'],
})
export class SettingsIntegrationsComponent implements OnInit, OnDestroy {
	destroy$: Subject<boolean> = new Subject<boolean>();
	authUser!: Auth;
	clientIntegrationsForm!: FormGroup;
	thirdpartyTokenAccess = '';
	loading = false;
  isDisableSaveBtn = false;
	isThirdPartyFieldTouched = false;
	tokenValue!: string;
	tokenStatus!: string;

	constructor(
		private frontService: FrontService,
		private clientService: ClientService,
	) { }

	ngOnInit() {
		this.authUser = this.frontService.authService.auth;
		this.thirdpartyTokenAccess = '';
		this.clientService.onUpdateIntegrations
		.takeUntil(this.destroy$)
		.subscribe(
		(response: any) => {
			if (response) {
				if (typeof response.status !== 'undefined') {
					this.loading = false;

					if (response.status === 'error') {
					swal({
						title: 'Failed to update Integrations',
						text: 'Contact Dev Team',
						type: 'error'
					});
					}
				}
			}
		});
		this.initForm();

		this.clientIntegrationsForm.controls['vault_thirdparty_token_access'].valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(100))
			.subscribe((data) => {
				this.tokenValue = data;
				
        this.isThirdPartyFieldTouched = true;
        this.isDisableSaveBtn = !!data ? false : true;
			});
  }

	initForm() {
		this.thirdpartyTokenAccess = this.authUser.user.formatted_vault_thirdparty_token_access;
		this.clientIntegrationsForm = new FormGroup({
			vault_thirdparty_token_access: new FormControl(this.thirdpartyTokenAccess),
			propps_app_id: new FormControl(this.authUser.client.propps_app_id),
			pricefinder_username: new FormControl(this.authUser.client.pricefinder_username),
			pricefinder_password: new FormControl(''),
			google_maps_api_key: new FormControl(this.authUser.client.google_maps_api_key),
			google_tag_id: new FormControl(this.authUser.client.google_tag_id)
		});
	}

	async onSubmit(): Promise<void> {
		if (this.isThirdPartyFieldTouched) {
			await this.onTestVaultThirdPartyToken(this.tokenValue);
		} else {
			await this.updateIntegrations();
		}
		
	}
	testCredentials(pricefinderUsername: string, pricefinderPassword:string): void {
		let clientId = this.authUser.user.client_id;
		this.clientService.testPricefinderCredentials(pricefinderUsername, pricefinderPassword).subscribe(
			(response: any) => {
				swal({
					title: 'Pricefinder credentials valid',
					text: 'Would you want to save this credentials?',
					type: 'success',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, save it!'
				  }).then((result) => {
					
					if (result) {
						this.saveCredentials(clientId, pricefinderUsername, pricefinderPassword);
					//   $this.loading = true;
					//   $this.clientService.httpDeleteClient(id);
					}
				  }).catch(swal.noop);
			},
			(error: any) => {
				swal('Pricefinder credentials invalid', '', 'error');
			}
		)
	}
	saveCredentials(clientId: number, pricefinderUsername: string, pricefinderPassword:string) {
		let location = "client";
		this.clientService.savePricefinderCredentials(clientId, location, pricefinderUsername, pricefinderPassword).subscribe(
			(response: any) => {
				swal('Pricefinder credentials are saved', '', 'success');
			},
			(error: any) => {
				swal('Issue saving your pricefinder credentials', '', 'error');
			}
		)
	}

	async onTestVaultThirdPartyToken(thirdPartyToken: string): Promise<void> {
		this.loading = true;
    this.clientService
      .testThirdPartyToken(thirdPartyToken)
      .takeUntil(this.destroy$)
      .subscribe({
				next: async (response: any) => {
					this.loading = false;
					this.isThirdPartyFieldTouched = false;
					this.tokenStatus = 'success';
					if (response.status === 'success') {
						swal('Success', response.message, 'success');
						await this.updateIntegrations();
					}
        },
        error: (err: any) => {
					this.loading = false;
					this.tokenStatus = 'error';
					
					switch (err.status) {
						case 400:
							swal('Validation Error', '3rd Party Token is required.', 'error');
							break;
						default:
							swal('Unauthorized', '3rd Party Token is invalid.', 'error')
							break;
					}
        },
			});
	}

	async updateIntegrations(): Promise<void> {
		const clientIntegrationsFormValues = this.clientIntegrationsForm.value;

		if (this.clientIntegrationsForm.valid) {
			if (clientIntegrationsFormValues.vault_thirdparty_token_access) {
				clientIntegrationsFormValues.vault_thirdparty_token_access = clientIntegrationsFormValues.vault_thirdparty_token_access.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/gi, '');
				if (clientIntegrationsFormValues.vault_thirdparty_token_access === this.thirdpartyTokenAccess.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/gi, '')) {
					delete clientIntegrationsFormValues.vault_thirdparty_token_access;
				}
				this.clientService.httpUpdateIntegrations(this.authUser.client.ID, clientIntegrationsFormValues);
			}
		}
	}
	
	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
