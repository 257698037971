export class Template {
  constructor(
    public ID: number,
    public master_id: number = 0,
    public category_id: number = 0,
    public user_id: number = 0,
    public user_agent_id: string,
    public client_id: number = 0,
    public group_id: number = 1,
    public category_name: string = '',
    public media_attribute_id: number = 0,
    public department_id: number = 1,
    public custom_category_id = null,
    public title: string,
    public html: string = '',
    public html_raw: string = '',
    public html_data: any = {},
    public thumbnail: string = '',
    public is_shared: number = 0,
    public is_active: number = 1,
    public sort: number = 0,
    public created: string = '',
    public modified: string = '',
    public client: any = {},
    public master: any = {},
    public category: any = {} ,
    public group: any = {},
    public media_attribute: any = {},
    public department: any = {},
    public preloadedTemplate: boolean,
    public preloadedCampaign: boolean,
    public is_master_template: number = 0,
    public quickdesign: any = {},
    public listings: any = [],
    public folder_templates: any = [],
    public campaign_categories: any = [],
    public reference_template_id = null,
    public vault_template_id: number = 0
  ) {}
}
